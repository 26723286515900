import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import MyProjects from './pages/projects/MyProjects';
import CreateProject from './pages/projects/CreateProject';
import ProjectDetails from './pages/projects/ProjectDetails';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import GDPR from './pages/GDPR';
import Profile from './pages/Profile';
import ProjectEdit from './pages/projects/ProjectEdit';
import Landing from './pages/Landing';
import ContractorProfile from './pages/contractors/ContractorProfile';
import ContractorSearch from './pages/contractors/ContractorSearch';
import ProjectBrowser from './pages/contractors/ProjectBrowser';
import MyProposals from './pages/contractors/MyProposals';
import ReceivedProposals from './pages/client/ReceivedProposals';
import AdminUsers from './pages/admin/users';
import AdminLayout from './pages/admin/layout';
import UsersPage from './pages/admin/users';
import ProjectsPage from './pages/admin/projects';
import CategoriesPage from './pages/admin/categories';
import ProjectDetailsPage from './pages/admin/projects/ProjectDetailsPage';
import TweaksPage from './pages/admin/TweaksPage';
import Info from './pages/Info';

function AppRoutes() {
  const { user, activeProfile } = useAuth();

  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/gdpr" element={<GDPR />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      
      {/* Megrendelői útvonalak */}
      <Route 
        path="/client/projects" 
        element={
          activeProfile?.type === 'client' ? <MyProjects /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/client/projects/create" 
        element={
          activeProfile?.type === 'client' ? <CreateProject /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/client/projects/:id" 
        element={
          activeProfile?.type === 'client' ? <ProjectDetails /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/client/projects/:id/edit" 
        element={
          activeProfile?.type === 'client' ? <ProjectEdit /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/client/proposals" 
        element={
          activeProfile?.type === 'client' ? <ReceivedProposals /> : <Navigate to="/" />
        } 
      />

      {/* Kivitelezői útvonalak */}
      <Route 
        path="/contractor/projects/browse" 
        element={
          activeProfile?.type === 'contractor' ? <ProjectBrowser /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/contractor/projects/active" 
        element={
          activeProfile?.type === 'contractor' ? <MyProjects /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/contractor/projects/:id" 
        element={
          activeProfile?.type === 'contractor' ? <ProjectDetails /> : <Navigate to="/" />
        } 
      />
      <Route 
        path="/contractor/proposals" 
        element={
          activeProfile?.type === 'contractor' ? <MyProposals /> : <Navigate to="/" />
        } 
      />

      {/* Közös útvonalak */}
      <Route path="/contractors" element={<ContractorSearch />} />
      <Route path="/contractors/:id" element={<ContractorProfile />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/info/:section?" element={<Info />} />
      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<Navigate to="/admin/users" replace />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="projects" element={<ProjectsPage />} />
        <Route path="projects/:id" element={<ProjectDetailsPage />} />
        <Route path="categories" element={<CategoriesPage />} />
        <Route path="statistics" element={<div>Statisztikák</div>} />
        <Route path="reports" element={<div>Bejelentések</div>} />
        <Route path="tweaks" element={<TweaksPage />} />
      </Route>
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/gdpr" element={<GDPR />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <AppRoutes />
        </Layout>
      </AuthProvider>
    </Router>
  );
}

