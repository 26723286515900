import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, where, orderBy, Timestamp, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import UserList from './components/UserList';
import UserFilter from './components/UserFilter';
import UserProfileModal from './components/UserProfileModal';
import UserPermissionsModal from './components/UserPermissionsModal';
import { User, UserProfile, UserWithStats } from '../../../types/user';

type UserFilter = 'all' | 'client' | 'contractor' | 'admin';

export default function UsersPage() {
  const [users, setUsers] = useState<UserWithStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [userFilter, setUserFilter] = useState<UserFilter>('all');
  const [selectedUser, setSelectedUser] = useState<UserWithStats | null>(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      // Felhasználók lekérése
      const usersRef = collection(db, 'users');
      const q = query(usersRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const usersData: UserWithStats[] = [];
      
      for (const userDoc of querySnapshot.docs) {
        const userData = {
          id: userDoc.id,
          ...userDoc.data()
        } as User;
        
        // Profilok lekérése
        const profilesRef = collection(db, 'profiles');
        const profileQuery = query(profilesRef, where('userId', '==', userDoc.id));
        const profileSnap = await getDocs(profileQuery);
        const profiles = profileSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as UserProfile[];

        // Aktív profil megkeresése
        const activeProfile = profiles.find(p => p.id === userData.activeProfile?.id) || null;

        // Projektek és ajánlatok számának lekérése
        let projectCount = 0;
        let proposalCount = 0;

        if (activeProfile) {
          const projectsQuery = query(
            collection(db, 'projects'),
            where(activeProfile.type === 'client' ? 'clientId' : 'contractorId', '==', activeProfile.id)
          );
          const projectsSnap = await getDocs(projectsQuery);
          projectCount = projectsSnap.size;

          if (activeProfile.type === 'contractor') {
            const proposalsQuery = query(
              collection(db, 'proposals'),
              where('contractorId', '==', activeProfile.id)
            );
            const proposalsSnap = await getDocs(proposalsQuery);
            proposalCount = proposalsSnap.size;
          }
        }

        usersData.push({
          ...userData,
          profile: activeProfile,
          profiles,
          projectCount,
          proposalCount
        });
      }
      
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserAction = async (userId: string, action: 'view' | 'permissions') => {
    const user = users.find(u => u.id === userId);
    if (!user) return;

    switch (action) {
      case 'view':
        setSelectedUser(user);
        setIsProfileModalOpen(true);
        break;
      case 'permissions':
        setSelectedUser(user);
        setIsPermissionsModalOpen(true);
        break;
    }
  };

  const filteredUsers = users.filter(user => {
    const matchesSearch = 
      (user.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.email || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.profile?.contactName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.profile?.contactEmail || '').toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter = 
      userFilter === 'all' ||
      (userFilter === 'admin' && user.isAdmin === true) ||
      (userFilter === user.profile?.type);

    return matchesSearch && matchesFilter;
  });

  return (
    <>
      <UserFilter
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        activeFilter={userFilter}
        onFilterChange={setUserFilter}
        totalUsers={filteredUsers.length}
        loading={loading}
      />
      <UserList
        users={filteredUsers}
        loading={loading}
        onUserAction={handleUserAction}
      />
      <UserProfileModal
        user={selectedUser}
        isOpen={isProfileModalOpen}
        onClose={() => {
          setIsProfileModalOpen(false);
          setSelectedUser(null);
        }}
      />
      <UserPermissionsModal
        user={selectedUser}
        isOpen={isPermissionsModalOpen}
        onClose={() => {
          setIsPermissionsModalOpen(false);
          setSelectedUser(null);
        }}
        onUpdate={fetchUsers}
      />
    </>
  );
} 