import { Link } from 'react-router-dom';
import { FaRocket, FaUsers, FaCheckCircle, FaShieldAlt } from 'react-icons/fa';
import heroImage from '../assets/images/landing/home-hero.png';
import contractorImage from '../assets/images/landing/Human-2.png';
import clientImage from '../assets/images/landing/Human-3.png';
import securityImage from '../assets/images/landing/Human-3-s.png';

const ButtonPrimary = ({ children, to }: { children: React.ReactNode; to: string }) => (
  <Link
    to={to}
    className="group relative px-8 py-4 bg-[#20A64B] overflow-hidden rounded-lg transition-all duration-500 ease-out hover:shadow-lg hover:shadow-[#20A64B]/30 hover:scale-[1.02]"
  >
    {/* Animált háttér */}
    <div className="absolute inset-0 bg-gradient-to-r from-[#178f3f] via-[#20A64B] to-[#178f3f] bg-[length:200%_100%] transition-all duration-500 ease-out group-hover:bg-right" 
      style={{ backgroundPosition: '0 0', backgroundSize: '200% 100%' }}
    />
    
    {/* Animált fénylő effekt */}
    <div className="absolute inset-0 opacity-0 group-hover:opacity-25 bg-[linear-gradient(110deg,transparent_25%,rgba(255,255,255,0.4)_50%,transparent_75%)] bg-[length:200%_100%] transition-all duration-700 ease-out group-hover:translate-x-full" />
    
    {/* Szöveg és ikon */}
    <span className="relative z-10 text-lg font-medium text-white inline-flex items-center gap-2 transition-transform duration-500 ease-out group-hover:translate-x-1">
      {children}
      <svg 
        className="w-4 h-4 transform transition-all duration-500 ease-out opacity-0 -translate-x-4 group-hover:opacity-100 group-hover:translate-x-0" 
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </span>
  </Link>
);

const ButtonSecondary = ({ children, to }: { children: React.ReactNode; to: string }) => (
  <Link
    to={to}
    className="group relative px-8 py-4 bg-gray-700 overflow-hidden rounded-lg transition-all duration-500 ease-out hover:shadow-lg hover:shadow-gray-900/30 hover:scale-[1.02]"
  >
    {/* Animált háttér */}
    <div className="absolute inset-0 bg-gradient-to-r from-gray-600 via-gray-700 to-gray-600 bg-[length:200%_100%] transition-all duration-500 ease-out group-hover:bg-right"
      style={{ backgroundPosition: '0 0', backgroundSize: '200% 100%' }}
    />
    
    {/* Animált fénylő effekt */}
    <div className="absolute inset-0 opacity-0 group-hover:opacity-15 bg-[linear-gradient(110deg,transparent_25%,rgba(255,255,255,0.2)_50%,transparent_75%)] bg-[length:200%_100%] transition-all duration-700 ease-out group-hover:translate-x-full" />
    
    {/* Szöveg */}
    <span className="relative z-10 text-lg font-medium text-white transition-transform duration-500 ease-out group-hover:translate-x-1">
      {children}
    </span>
  </Link>
);

export default function Landing() {
  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero section */}
      <div className="relative bg-gray-900 overflow-hidden">
        {/* Háttér gradiens */}
        <div className="absolute inset-0 bg-gradient-to-br from-[#20A64B]/20 via-transparent to-transparent" />
        
        {/* Tartalom */}
        <div className="relative max-w-[1920px] mx-auto px-4 py-32">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
                Találja meg a tökéletes <span className="text-[#20A64B]">kivitelezőt</span>
              </h1>
              <p className="text-xl text-gray-300 mb-12">
                A WebTender összeköti a megrendelőket a minősített kivitelezőkkel. 
                Hozza létre projektjét és találja meg a legjobb szakembert!
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <ButtonPrimary to="/login">
                  Ingyenes regisztráció
                </ButtonPrimary>
              </div>
            </div>
            <div className="hidden lg:flex justify-center">
              <img 
                src={heroImage} 
                alt="WebTender Platform" 
                className="max-w-md w-full h-auto rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <div className="w-full px-4 py-20 bg-gray-800">
        <div className="max-w-[1920px] mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-6 bg-gray-700 rounded-lg">
              <div className="w-12 h-12 bg-[#20A64B]/20 rounded-lg flex items-center justify-center mb-4">
                <FaRocket className="text-2xl text-[#20A64B]" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">Gyors és egyszerű</h3>
              <p className="text-gray-300">
                Percek alatt létrehozhatja projektjét és megtalálhatja a megfelelő kivitelezőt
              </p>
            </div>
            <div className="p-6 bg-gray-700 rounded-lg">
              <div className="w-12 h-12 bg-[#20A64B]/20 rounded-lg flex items-center justify-center mb-4">
                <FaUsers className="text-2xl text-[#20A64B]" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">Minősített kivitelezők</h3>
              <p className="text-gray-300">
                Csak ellenőrzött, megbízható szakemberekkel dolgozunk
              </p>
            </div>
            <div className="p-6 bg-gray-700 rounded-lg">
              <div className="w-12 h-12 bg-[#20A64B]/20 rounded-lg flex items-center justify-center mb-4">
                <FaCheckCircle className="text-2xl text-[#20A64B]" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">Garantált minőség</h3>
              <p className="text-gray-300">
                Minden projekt minőségellenőrzésen megy keresztül
              </p>
            </div>
            <div className="p-6 bg-gray-700 rounded-lg">
              <div className="w-12 h-12 bg-[#20A64B]/20 rounded-lg flex items-center justify-center mb-4">
                <FaShieldAlt className="text-2xl text-[#20A64B]" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">Biztonságos fizetés</h3>
              <p className="text-gray-300">
                Csak az elvégzett és elfogadott munkáért kell fizetni
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* For Contractors */}
      <div className="w-full px-4 py-20">
        <div className="max-w-[1920px] mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="order-2 lg:order-1">
              <img 
                src={contractorImage} 
                alt="Kivitelezőknek" 
                className="w-[350px] h-auto mx-auto rounded-lg shadow-xl"
              />
            </div>
            <div className="order-1 lg:order-2">
              <h2 className="text-3xl font-bold text-white mb-6">
                Kivitelezőknek
              </h2>
              <p className="text-xl text-gray-300 mb-8">
                Találjon új projekteket és növelje vállalkozását. A WebTender segít 
                a megfelelő megrendelők megtalálásában és a projektek hatékony kezelésében.
              </p>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-3" />
                  <span>Új üzleti lehetőségek</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-3" />
                  <span>Hatékony projektmenedzsment</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-3" />
                  <span>Biztonságos fizetési rendszer</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* For Clients */}
      <div className="w-full px-4 py-20 bg-gray-800/50">
        <div className="max-w-[1920px] mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold text-white mb-6">
                Megrendelőknek
              </h2>
              <p className="text-xl text-gray-300 mb-8">
                Találja meg a megfelelő szakembert projektjéhez. Hasonlítsa össze az 
                ajánlatokat és válassza ki a legjobb kivitelezőt.
              </p>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-3" />
                  <span>Ellenőrzött kivitelezők</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-3" />
                  <span>Versenyképes ajánlatok</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-3" />
                  <span>Projekt nyomonkövetés</span>
                </li>
              </ul>
            </div>
            <div>
              <img 
                src={clientImage} 
                alt="Megrendelőknek" 
                className="w-[350px] h-auto mx-auto rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Pricing */}
      <div className="w-full px-4 py-20 bg-gray-800/50">
        <div className="max-w-[1920px] mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              Egyszerű és átlátható árazás
            </h2>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Válassza ki az Önnek megfelelő csomagot
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Basic Plan */}
            <div className="bg-gray-700 rounded-lg p-8 relative hover:scale-105 transition-transform">
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-white mb-4">Basic</h3>
                <div className="text-4xl font-bold text-white mb-2">
                  Ingyenes
                </div>
                <p className="text-gray-300">Ideális kezdéshez</p>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>3 aktív projekt</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Alap projektkezelés</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Email támogatás</span>
                </li>
              </ul>
              <ButtonSecondary to="/login">
                Kezdés most
              </ButtonSecondary>
            </div>

            {/* Pro Plan */}
            <div className="bg-gradient-to-br from-[#20A64B]/20 to-[#178f3f]/20 rounded-lg p-8 relative transform hover:scale-105 transition-transform border-2 border-[#20A64B]">
              <div className="absolute top-0 right-0 bg-[#20A64B] text-white px-4 py-1 rounded-bl-lg rounded-tr-lg text-sm font-medium">
                Ajánlott
              </div>
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-white mb-4">Pro</h3>
                <div className="text-4xl font-bold text-white mb-2">
                  9 900 Ft
                  <span className="text-base font-normal text-gray-300">/hó</span>
                </div>
                <p className="text-gray-300">Professzionális felhasználóknak</p>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Korlátlan projekt</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Haladó projektkezelés</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Prioritásos támogatás</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Részletes analitika</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Egyedi branding</span>
                </li>
              </ul>
              <ButtonPrimary to="/login">
                Próbálja ki ingyen
              </ButtonPrimary>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-gray-700 rounded-lg p-8 relative hover:scale-105 transition-transform">
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-white mb-4">Enterprise</h3>
                <div className="text-4xl font-bold text-white mb-2">
                  Egyedi
                </div>
                <p className="text-gray-300">Nagyvállalatoknak</p>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Minden Pro funkció</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Dedikált ügyfélmenedzser</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>API hozzáférés</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaCheckCircle className="text-[#20A64B] mr-2" />
                  <span>Egyedi fejlesztések</span>
                </li>
              </ul>
              <ButtonSecondary to="/login">
                Kapcsolatfelvétel
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </div>

      {/* Security */}
      <div className="w-full px-4 py-20 bg-gray-800/30">
        <div className="max-w-[1920px] mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <img 
                src={securityImage} 
                alt="Biztonságos platform" 
                className="w-[350px] h-auto mx-auto rounded-lg shadow-xl"
              />
            </div>
            <div>
              <h2 className="text-3xl font-bold text-white mb-6">
                Biztonságos és megbízható
              </h2>
              <p className="text-xl text-gray-300 mb-8">
                A WebTender platform a legmagasabb biztonsági standardok szerint működik. 
                Minden tranzakció és kommunikáció védett és biztonságos.
              </p>
              <ul className="space-y-4">
                <li className="flex items-center text-gray-300">
                  <FaShieldAlt className="text-[#20A64B] mr-3" />
                  <span>Titkosított kommunikáció</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaShieldAlt className="text-[#20A64B] mr-3" />
                  <span>Biztonságos fizetések</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaShieldAlt className="text-[#20A64B] mr-3" />
                  <span>Adatvédelem</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Call to action */}
      <div className="w-full px-4 py-20">
        <div className="max-w-[1920px] mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
            Kezdje el most!
          </h2>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Csatlakozzon a WebTender közösségéhez és találja meg a tökéletes partnert projektjéhez.
          </p>
          <ButtonPrimary to="/login">
            Ingyenes regisztráció
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
} 