import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, query, where, getDocs, orderBy, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { Proposal } from '../../types/project';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaClock, FaTimesCircle, FaFile, FaExternalLinkAlt, FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

interface ProposalWithProject extends Proposal {
  projectTitle: string;
  projectId: string;
}

export default function ReceivedProposals() {
  const { user, activeProfile } = useAuth();
  const [proposals, setProposals] = useState<ProposalWithProject[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<'all' | 'pending' | 'accepted' | 'rejected'>('all');

  useEffect(() => {
    const fetchProposals = async () => {
      if (!user?.uid) return;

      if (!activeProfile || activeProfile.type !== 'client') {
        toast.error('Csak megrendelők férhetnek hozzá ehhez az oldalhoz');
        setLoading(false);
        return;
      }

      try {
        const proposalsRef = collection(db, 'proposals');
        const proposalsQuery = query(
          proposalsRef,
          where('clientId', '==', user.uid),
          orderBy('createdAt', 'desc')
        );

        const querySnapshot = await getDocs(proposalsQuery);
        const proposalsData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          createdAt: doc.data().createdAt.toDate()
        })) as ProposalWithProject[];

        setProposals(proposalsData);
      } catch (error) {
        console.error('Error fetching proposals:', error);
        toast.error('Hiba történt az ajánlatok betöltése során');
      } finally {
        setLoading(false);
      }
    };

    fetchProposals();
  }, [user?.uid, activeProfile]);

  const handleProposalAction = async (proposalId: string, action: 'accept' | 'reject') => {
    if (!user) return;

    try {
      const proposalRef = doc(db, 'proposals', proposalId);
      await updateDoc(proposalRef, {
        status: action === 'accept' ? 'accepted' : 'rejected',
        updatedAt: Timestamp.fromDate(new Date())
      });

      // Frissítjük a helyi state-et
      setProposals(prevProposals => 
        prevProposals.map(p => 
          p.id === proposalId 
            ? { ...p, status: action === 'accept' ? 'accepted' as const : 'rejected' as const }
            : p
        )
      );

      toast.success(
        action === 'accept' 
          ? 'Ajánlat sikeresen elfogadva!' 
          : 'Ajánlat sikeresen elutasítva!'
      );
    } catch (error) {
      console.error('Error updating proposal:', error);
      toast.error('Hiba történt az ajánlat kezelése során');
    }
  };

  const getStatusBadge = (status: Proposal['status']) => {
    switch (status) {
      case 'pending':
        return (
          <span className="flex items-center gap-1 text-yellow-400">
            <FaClock className="w-4 h-4" />
            <span>Függőben</span>
          </span>
        );
      case 'accepted':
        return (
          <span className="flex items-center gap-1 text-green-400">
            <FaCheckCircle className="w-4 h-4" />
            <span>Elfogadva</span>
          </span>
        );
      case 'rejected':
        return (
          <span className="flex items-center gap-1 text-red-400">
            <FaTimesCircle className="w-4 h-4" />
            <span>Elutasítva</span>
          </span>
        );
    }
  };

  const filteredProposals = filter === 'all' 
    ? proposals 
    : proposals.filter(p => p.status === filter);

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="bg-gray-800 rounded-lg p-6 mb-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-white">Beérkezett ajánlatok</h1>
            <div className="flex gap-2">
              <button
                onClick={() => setFilter('all')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  filter === 'all' 
                    ? 'bg-[#20A64B] text-white' 
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                Összes
              </button>
              <button
                onClick={() => setFilter('pending')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  filter === 'pending'
                    ? 'bg-[#20A64B] text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                Függőben
              </button>
              <button
                onClick={() => setFilter('accepted')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  filter === 'accepted'
                    ? 'bg-[#20A64B] text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                Elfogadva
              </button>
              <button
                onClick={() => setFilter('rejected')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  filter === 'rejected'
                    ? 'bg-[#20A64B] text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                Elutasítva
              </button>
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B]"></div>
            </div>
          ) : filteredProposals.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-400">
                {filter === 'all' 
                  ? 'Még nem érkezett ajánlat a projektjeire'
                  : `Nincs ${
                      filter === 'pending' ? 'függőben lévő' :
                      filter === 'accepted' ? 'elfogadott' :
                      'elutasított'
                    } ajánlat`
                }
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              {filteredProposals.map(proposal => (
                <motion.div
                  key={proposal.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-gray-700/50 rounded-lg p-6"
                >
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <Link 
                        to={`/projects/${proposal.projectId}`}
                        className="text-xl font-semibold text-white hover:text-[#20A64B] transition-colors flex items-center gap-2"
                      >
                        {proposal.projectTitle}
                        <FaExternalLinkAlt className="w-4 h-4" />
                      </Link>
                      <div className="flex items-center gap-4 mt-1">
                        <p className="text-gray-400 text-sm">
                          Beküldve: {new Date(proposal.createdAt).toLocaleDateString('hu-HU')}
                        </p>
                        <p className="text-gray-400 text-sm">
                          Kivitelező: {proposal.contractorName}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      {proposal.status === 'pending' && (
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleProposalAction(proposal.id, 'accept')}
                            className="px-3 py-1 bg-green-500/20 hover:bg-green-500/30 text-green-400 rounded-lg transition-colors flex items-center gap-2"
                          >
                            <FaCheck className="w-4 h-4" />
                            <span>Elfogadás</span>
                          </button>
                          <button
                            onClick={() => handleProposalAction(proposal.id, 'reject')}
                            className="px-3 py-1 bg-red-500/20 hover:bg-red-500/30 text-red-400 rounded-lg transition-colors flex items-center gap-2"
                          >
                            <FaTimes className="w-4 h-4" />
                            <span>Elutasítás</span>
                          </button>
                        </div>
                      )}
                      {getStatusBadge(proposal.status)}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Ajánlott összeg</p>
                      <p className="text-white font-medium">
                        {proposal.amount.toLocaleString()} {proposal.currency}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Becsült időtartam</p>
                      <p className="text-white">{proposal.estimatedDuration} nap</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Csatolt fájlok</p>
                      {proposal.attachments && proposal.attachments.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                          {proposal.attachments.map(file => (
                            <a
                              key={file.id}
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center gap-2 px-3 py-1 bg-gray-600 hover:bg-gray-500 rounded-lg text-gray-300 hover:text-white transition-colors group max-w-full"
                            >
                              <FaFile className="w-4 h-4 flex-shrink-0 text-[#20A64B] group-hover:text-white transition-colors" />
                              <span className="text-sm truncate">
                                {file.name.length > 20 
                                  ? file.name.substring(0, 20) + '...' 
                                  : file.name
                                }
                              </span>
                              <span className="text-xs text-gray-400 group-hover:text-gray-300">
                                ({(file.size / 1024 / 1024).toFixed(1)} MB)
                              </span>
                            </a>
                          ))}
                        </div>
                      ) : (
                        <span className="text-gray-500 text-sm">Nincs csatolt fájl</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <p className="text-sm text-gray-400 mb-2">Ajánlat leírása</p>
                    <p className="text-gray-300 whitespace-pre-wrap">{proposal.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 