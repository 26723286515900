import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { FaGoogle, FaEnvelope, FaLock, FaUser, FaArrowLeft } from 'react-icons/fa';
import { useNavigate, Navigate } from 'react-router-dom';
import WebTenderLogo from '../assets/images/webtender_logo.svg';

type AuthMode = 'login' | 'register' | 'reset';

export default function Login() {
  const { user, signInWithGoogle, signInWithEmail, signUpWithEmail, resetPassword } = useAuth();
  const [mode, setMode] = useState<AuthMode>('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Ha már be van jelentkezve, irányítsuk át a főoldalra
  if (user) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (mode === 'login') {
        const success = await signInWithEmail(email, password);
        if (success) {
          navigate('/');
        }
      } else if (mode === 'register') {
        await signUpWithEmail(email, password, name);
        navigate('/');
      } else if (mode === 'reset') {
        await resetPassword(email);
      }
    } catch (error) {
      console.error('Auth error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[80vh] flex items-center justify-center">
      <div className="max-w-md w-full px-6">
        <div className="bg-gray-800 shadow-lg rounded-2xl p-8 space-y-6">
          {/* Logo */}
          <div className="flex justify-center mb-6">
            <img src={WebTenderLogo} alt="WebTender" className="h-8 w-auto" />
          </div>

          {/* Fejléc */}
          <div className="text-center relative">
            {mode !== 'login' && (
              <button
                onClick={() => setMode('login')}
                className="absolute left-0 top-1/2 -translate-y-1/2 text-gray-400 hover:text-[#20A64B] transition-colors"
              >
                <FaArrowLeft size={20} />
              </button>
            )}
            <h2 className="text-3xl font-bold text-white mb-2">
              {mode === 'login' ? 'Üdvözöljük!' : 
               mode === 'register' ? 'Regisztráció' : 
               'Jelszó visszaállítás'}
            </h2>
            <p className="text-gray-400">
              {mode === 'login' ? 'Jelentkezzen be fiókjába' : 
               mode === 'register' ? 'Hozza létre új fiókját' : 
               'Adja meg email címét'}
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            {mode === 'register' && (
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  <FaUser className="inline mr-2" />
                  Név
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#20A64B] focus:border-transparent transition-all"
                  placeholder="Adja meg nevét"
                  required
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                <FaEnvelope className="inline mr-2" />
                Email cím
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#20A64B] focus:border-transparent transition-all"
                placeholder="pelda@email.com"
                required
              />
            </div>

            {mode !== 'reset' && (
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  <FaLock className="inline mr-2" />
                  Jelszó
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#20A64B] focus:border-transparent transition-all"
                  placeholder="********"
                  required
                />
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full py-3 px-4 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-[#20A64B] focus:ring-offset-2 focus:ring-offset-gray-800 disabled:opacity-50"
            >
              {loading ? 'Folyamatban...' : 
               mode === 'login' ? 'Bejelentkezés' : 
               mode === 'register' ? 'Regisztráció' : 
               'Jelszó visszaállítása'}
            </button>
          </form>

          {mode === 'login' && (
            <>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-600"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-800 text-gray-400">vagy</span>
                </div>
              </div>

              <button
                onClick={signInWithGoogle}
                className="w-full py-3 px-4 bg-gray-700 hover:bg-gray-600 text-white rounded-lg font-medium transition-colors flex items-center justify-center gap-2 focus:outline-none focus:ring-2 focus:ring-[#20A64B] focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                <FaGoogle /> Bejelentkezés Google-lel
              </button>
            </>
          )}

          <div className="text-center space-y-2">
            {mode === 'login' ? (
              <>
                <button
                  onClick={() => setMode('register')}
                  className="text-[#20A64B] hover:text-[#178f3f] transition-colors"
                >
                  Még nincs fiókja? Regisztráljon!
                </button>
                <button
                  onClick={() => setMode('reset')}
                  className="block w-full text-[#20A64B] hover:text-[#178f3f] transition-colors"
                >
                  Elfelejtette jelszavát?
                </button>
              </>
            ) : (
              <button
                onClick={() => setMode('login')}
                className="text-[#20A64B] hover:text-[#178f3f] transition-colors"
              >
                {mode === 'register' ? 'Már van fiókja? Jelentkezzen be!' : 'Vissza a bejelentkezéshez'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 