import React from 'react';
import { motion } from 'framer-motion';
import { FaClock, FaExclamationCircle } from 'react-icons/fa';
import { UserWithStats } from '../../../../types/user';
import UserListItem from './UserListItem';

interface UserListProps {
  users: UserWithStats[];
  loading: boolean;
  onUserAction: (userId: string, action: 'view' | 'permissions') => void;
}

export default function UserList({ users, loading, onUserAction }: UserListProps) {
  if (loading) {
    return (
      <div className="text-center py-12">
        <FaClock className="w-6 h-6 text-gray-400 animate-spin mx-auto mb-2" />
        <p className="text-gray-400">Felhasználók betöltése...</p>
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className="text-center py-12">
        <FaExclamationCircle className="w-6 h-6 text-gray-400 mx-auto mb-2" />
        <p className="text-gray-400">Nincs találat a keresési feltételeknek.</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {users.map((user) => (
        <motion.div
          key={user.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <UserListItem 
            user={user} 
            onAction={onUserAction}
          />
        </motion.div>
      ))}
    </div>
  );
} 