import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';

export type FirestoreDate = { toDate: () => Date };

// Ellenőrzi, hogy egy érték Firestore dátum-e
export const isFirestoreDate = (date: any): date is FirestoreDate => {
  return date && typeof date.toDate === 'function';
};

// Konvertálja a dátumot JavaScript Date objektummá
export const toJsDate = (date: Date | FirestoreDate): Date => {
  if (isFirestoreDate(date)) {
    return date.toDate();
  }
  return date;
};

// Formázza a dátumot a megadott formátum szerint
export const formatDate = (
  date: Date | FirestoreDate,
  formatStr: string = 'yyyy.MM.dd'
): string => {
  return format(toJsDate(date), formatStr, { locale: hu });
};

export function formatDateTime(date: Timestamp | Date | undefined): string {
  if (!date) return '';

  const d = date instanceof Timestamp ? date.toDate() : date;
  return d.toLocaleDateString('hu-HU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
} 