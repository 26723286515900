import { Link } from 'react-router-dom';
import { FaCar, FaPhone, FaEnvelope, FaFacebook } from 'react-icons/fa';
import WebTenderLogo from '../assets/images/webtender_logo.svg';
import WebforyouLogo from '../assets/images/webforyou.svg';
import { motion } from 'framer-motion';
import { 
  SiReact, 
  SiTypescript, 
  SiTailwindcss, 
  SiFirebase, 
  SiVite 
} from 'react-icons/si';

export default function Footer() {
  return (
    <motion.footer 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gray-800 py-6 mt-auto w-full"
    >
      <div className="w-full px-4">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6 max-w-[1920px] mx-auto">
          {/* Logo és copyright */}
          <div className="flex items-center gap-4">
            <Link to="/" className="hover:opacity-90 transition-opacity">
              <img 
                src={WebTenderLogo} 
                alt="WebTender" 
                className="h-8 w-auto"
              />
            </Link>
            <p className="text-gray-400 text-sm whitespace-nowrap">
              © {new Date().getFullYear()} WebTender
            </p>
          </div>

          {/* Linkek */}
          <div className="flex items-center gap-6">
            <Link 
              to="/info/minosegi-mutato" 
              className="text-gray-400 hover:text-[#20A64B] transition-colors whitespace-nowrap"
            >
              Minőségi mutató
            </Link>
            <Link 
              to="/info/biztositas" 
              className="text-gray-400 hover:text-[#20A64B] transition-colors whitespace-nowrap"
            >
              Biztosítás
            </Link>
            <Link 
              to="/terms" 
              className="text-gray-400 hover:text-[#20A64B] transition-colors whitespace-nowrap"
            >
              Felhasználási feltételek
            </Link>
            <Link 
              to="/privacy" 
              className="text-gray-400 hover:text-[#20A64B] transition-colors whitespace-nowrap"
            >
              Adatvédelmi irányelvek
            </Link>
            <Link 
              to="/gdpr" 
              className="text-gray-400 hover:text-[#20A64B] transition-colors whitespace-nowrap"
            >
              GDPR
            </Link>
          </div>

          {/* Tech stack */}
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-400 whitespace-nowrap">Technológiák:</span>
            <div className="flex items-center gap-3">
              <SiReact className="w-5 h-5 text-[#61DAFB] hover:scale-110 transition-transform" title="React" />
              <SiTypescript className="w-5 h-5 text-[#3178C6] hover:scale-110 transition-transform" title="TypeScript" />
              <SiTailwindcss className="w-5 h-5 text-[#06B6D4] hover:scale-110 transition-transform" title="Tailwind CSS" />
              <SiFirebase className="w-5 h-5 text-[#FFCA28] hover:scale-110 transition-transform" title="Firebase" />
              <SiVite className="w-5 h-5 text-[#646CFF] hover:scale-110 transition-transform" title="Vite" />
            </div>
          </div>

          {/* Webforyou */}
          <div className="flex items-center gap-3">
            <span className="text-sm text-gray-400 whitespace-nowrap">Fejlesztő:</span>
            <a 
              href="https://webforyou.hu" 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
            >
              <img 
                src={WebforyouLogo} 
                alt="Webforyou" 
                className="h-6"
              />
            </a>
          </div>
        </div>
      </div>
    </motion.footer>
  );
} 
