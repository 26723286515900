import React, { useState } from 'react';
import { FaInfoCircle, FaCloudUploadAlt, FaFile, FaTrash, FaEdit, FaLeaf } from 'react-icons/fa';
import { CATEGORIES, PROJECT_SIZES } from '../../../constants/projectCategories';
import { ProjectCategory, DocumentType, ProjectAttachment } from '../../../types/project';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
import toast from 'react-hot-toast';
import { Dialog } from '@headlessui/react';

interface BasicInfoStepProps {
  basicInfo: {
    title: string;
    category: ProjectCategory;
    subcategory: string;
    description: string;
    scope: 'small' | 'medium' | 'large';
    isGreenfield: boolean;
  };
  setBasicInfo: React.Dispatch<React.SetStateAction<{
    title: string;
    category: ProjectCategory;
    subcategory: string;
    description: string;
    scope: 'small' | 'medium' | 'large';
    isGreenfield: boolean;
  }>>;
  validationErrors: {
    title?: string;
    category?: string;
    subcategory?: string;
    description?: string;
    scope?: string;
  };
  attachments: ProjectAttachment[];
  setAttachments: React.Dispatch<React.SetStateAction<ProjectAttachment[]>>;
}

const documentTypeOptions: { value: DocumentType; label: string }[] = [
  { value: 'project_description', label: 'Projekt leírás' },
  { value: 'technical_spec', label: 'Műszaki specifikáció' },
  { value: 'design_doc', label: 'Tervrajz, dizájn dokumentum' },
  { value: 'reference', label: 'Referencia anyag' },
  { value: 'legal', label: 'Jogi dokumentum' },
  { value: 'other', label: 'Egyéb' }
];

export const BasicInfoStep: React.FC<BasicInfoStepProps> = ({
  basicInfo,
  setBasicInfo,
  validationErrors,
  attachments,
  setAttachments
}) => {
  const [uploading, setUploading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingFile, setEditingFile] = useState<{ index: number; attachment: ProjectAttachment } | null>(null);

  const handleEditDocumentType = (index: number, attachment: ProjectAttachment) => {
    setEditingFile({ index, attachment });
    setIsEditModalOpen(true);
  };

  const handleUpdateDocumentType = (documentType: DocumentType) => {
    if (editingFile) {
      const newAttachments = [...attachments];
      newAttachments[editingFile.index] = {
        ...editingFile.attachment,
        documentType
      };
      setAttachments(newAttachments);
      setIsEditModalOpen(false);
      setEditingFile(null);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    setUploading(true);
    try {
      for (const file of files) {
        if (file.size > 10 * 1024 * 1024) {
          toast.error(`${file.name} mérete nagyobb mint 10MB`);
          continue;
        }

        const storageRef = ref(storage, `projects/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        const newAttachment: ProjectAttachment = {
          name: file.name,
          url: url,
          type: file.type,
          size: file.size,
          documentType: 'other'
        };

        setAttachments(prev => [...prev, newAttachment]);
        toast.success(`${file.name} sikeresen feltöltve!`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Hiba történt a fájl feltöltése során');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Projekt címe
          {validationErrors.title && (
            <span className="text-red-500 text-xs ml-2">
              {validationErrors.title}
            </span>
          )}
        </label>
        <input
          type="text"
          value={basicInfo.title}
          onChange={(e) => setBasicInfo(prev => ({ ...prev, title: e.target.value }))}
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.title 
              ? 'border-red-500 focus:ring-red-500' 
              : 'border-gray-600 focus:ring-[#20A64B]'
          } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
          placeholder="Pl.: Céges weboldal fejlesztése"
          required
          data-error={validationErrors.title ? 'true' : undefined}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Kategória
            {validationErrors.category && (
              <span className="text-red-500 text-xs ml-2">
                {validationErrors.category}
              </span>
            )}
          </label>
          <select
            value={basicInfo.category}
            onChange={(e) => setBasicInfo(prev => ({ 
              ...prev, 
              category: e.target.value as ProjectCategory,
              subcategory: ''
            }))}
            className={`w-full px-4 py-2 rounded-lg border ${
              validationErrors.category 
                ? 'border-red-500 focus:ring-red-500' 
                : 'border-gray-600 focus:ring-[#20A64B]'
            } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
            required
            data-error={validationErrors.category ? 'true' : undefined}
          >
            <option value="">Válasszon kategóriát</option>
            {CATEGORIES.map(category => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </select>
        </div>

        {basicInfo.category && (
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Alkategória
              {validationErrors.subcategory && (
                <span className="text-red-500 text-xs ml-2">
                  {validationErrors.subcategory}
                </span>
              )}
            </label>
            <select
              value={basicInfo.subcategory}
              onChange={(e) => setBasicInfo(prev => ({ ...prev, subcategory: e.target.value }))}
              className={`w-full px-4 py-2 rounded-lg border ${
                validationErrors.subcategory 
                  ? 'border-red-500 focus:ring-red-500' 
                  : 'border-gray-600 focus:ring-[#20A64B]'
              } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
              required
              data-error={validationErrors.subcategory ? 'true' : undefined}
            >
              <option value="">Válasszon alkategóriát</option>
              {CATEGORIES.find(c => c.value === basicInfo.category)?.subcategories.map(sub => (
                <option key={sub} value={sub}>
                  {sub}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {/* Egységes információs panel minden kategóriához */}
      {basicInfo.category && CATEGORIES.find(c => c.value === basicInfo.category)?.additionalInfo && (
        <div className="mt-4 p-4 bg-gray-600 rounded-lg space-y-4">
          <div className="flex items-start gap-2">
            <FaInfoCircle className="text-[#20A64B] mt-1 flex-shrink-0" />
            <div className="w-full">
              <h4 className="text-white font-medium mb-4">
                {CATEGORIES.find(c => c.value === basicInfo.category)?.label} információk
              </h4>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {Object.entries(CATEGORIES.find(c => c.value === basicInfo.category)?.additionalInfo || {}).map(([key, items]) => (
                  <div key={key} className="bg-gray-700/50 rounded-lg p-4">
                    <h5 className="text-white font-medium mb-3">
                      {/* Panel címek fordítása */}
                      {key === 'requirements' && 'Követelmények'}
                      {key === 'features' && 'Jellemző funkciók'}
                      {key === 'considerations' && 'Fontos szempontok'}
                      {key === 'phases' && 'Projekt fázisok'}
                      {key === 'tools' && 'Használt eszközök'}
                      {key === 'services' && 'Szolgáltatások'}
                      {key === 'deliverables' && 'Végtermékek'}
                      {key === 'metrics' && 'Mérőszámok'}
                    </h5>
                    <ul className="space-y-2">
                      {items.map((item: string, i: number) => (
                        <li key={i} className="flex items-start gap-2 text-sm text-gray-300">
                          <span className="text-[#20A64B] mt-1">•</span>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-4">
          Projekt mérete
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {PROJECT_SIZES.map((size) => (
            <div
              key={size.value}
              onClick={() => setBasicInfo(prev => ({ ...prev, scope: size.value as 'small' | 'medium' | 'large' }))}
              className={`relative cursor-pointer p-6 rounded-lg border transition-all ${
                basicInfo.scope === size.value
                  ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                  : 'border-gray-600 hover:border-gray-500 text-gray-300'
              }`}
            >
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0">
                  <size.icon className="text-2xl text-[#20A64B]" />
                </div>
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <h3 className="font-medium">{size.label}</h3>
                    <div className="group relative">
                      <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help" />
                      <div className="absolute left-full ml-2 top-0 w-64 p-4 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
                        <p className="text-sm text-white mb-2">{size.description}</p>
                        <p className="text-xs text-gray-300 mb-2">Tipikus példák:</p>
                        <ul className="text-xs text-gray-400 list-disc list-inside">
                          {size.examples.map((example, index) => (
                            <li key={index}>{example}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p className="text-sm text-gray-400 mb-2">
                    Időtartam: {size.duration}
                  </p>
                </div>
              </div>
              {basicInfo.scope === size.value && (
                <div className="absolute top-2 right-2">
                  <div className="w-2 h-2 rounded-full bg-[#20A64B]" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6">
        <label className="flex items-center gap-2 text-sm font-medium text-gray-300 mb-4">
          <span>Zöldmezős projekt</span>
          <div className="group relative">
            <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help" />
            <div className="absolute left-full ml-2 top-0 w-64 p-4 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
              <div className="flex items-center gap-2 mb-2">
                <FaLeaf className="text-[#20A64B]" />
                <p className="text-sm text-white font-medium">Mi az a zöldmezős projekt?</p>
              </div>
              <p className="text-sm text-gray-300">
                Egy teljesen új projekt, amelyen korábban még nem dolgozott más kivitelező. Ez azt jelenti, hogy:
              </p>
              <ul className="mt-2 text-xs text-gray-400 list-disc list-inside space-y-1">
                <li>Nincs előzmény vagy korábbi munka</li>
                <li>Teljesen tiszta lappal indulhat a fejlesztés</li>
                <li>Nincsenek örökölt kódok vagy megoldások</li>
                <li>Szabadabb technológiai döntések</li>
              </ul>
            </div>
          </div>
        </label>
        <div className="flex items-center gap-4">
          <button
            type="button"
            onClick={() => setBasicInfo(prev => ({ ...prev, isGreenfield: true }))}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-all ${
              basicInfo.isGreenfield
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaLeaf className={basicInfo.isGreenfield ? 'text-[#20A64B]' : 'text-gray-400'} />
            <span>Igen, ez egy új projekt</span>
          </button>
          <button
            type="button"
            onClick={() => setBasicInfo(prev => ({ ...prev, isGreenfield: false }))}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-all ${
              !basicInfo.isGreenfield
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaEdit className={!basicInfo.isGreenfield ? 'text-[#20A64B]' : 'text-gray-400'} />
            <span>Nem, már dolgoztak rajta</span>
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Projekt leírása
          {validationErrors.description && (
            <span className="text-red-500 text-xs ml-2">
              {validationErrors.description}
            </span>
          )}
        </label>
        <textarea
          value={basicInfo.description}
          onChange={(e) => setBasicInfo(prev => ({ ...prev, description: e.target.value }))}
          rows={6}
          className={`w-full px-4 py-3 rounded-lg border ${
            validationErrors.description 
              ? 'border-red-500 focus:ring-red-500' 
              : 'border-gray-600 focus:ring-[#20A64B]'
          } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
          placeholder="Írja le részletesen a projekt célját és elvárásait..."
          required
          data-error={validationErrors.description ? 'true' : undefined}
        />
        <div className="mt-4 p-4 bg-gray-600 rounded-lg space-y-3">
          <p className="text-sm text-gray-300">
            <strong className="text-white">Mire érdemes kitérni a leírásban:</strong>
          </p>
          <ul className="text-sm text-gray-300 list-disc list-inside space-y-2">
            <li>Mi a projekt fő célja és milyen problémát old meg?</li>
            <li>Kik lesznek a végfelhasználók és milyen igényeik vannak?</li>
            <li>Milyen főbb funkciókat és szolgáltatásokat vár el a megoldástól?</li>
            <li>Van-e speciális technikai vagy üzleti követelmény?</li>
            <li>Milyen meglévő rendszerekkel kell együttműködnie?</li>
            <li>Vannak-e referenciák vagy példák, amiket követni szeretne?</li>
          </ul>
          <p className="text-sm text-gray-300 mt-3">
            <strong className="text-white">Tipp:</strong> Minél részletesebb a leírás, annál pontosabb ajánlatokat kaphat. 
            Ne felejtsen el kitérni a projekt egyedi jellemzőire és speciális elvárásaira.
          </p>
        </div>
      </div>

      {/* Fájlfeltöltő szekció */}
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Csatolmányok
          <span className="ml-2 text-gray-400 text-xs font-normal">
            (max. 10MB / fájl)
          </span>
        </label>
        
        <div className="mt-2 p-4 border-2 border-dashed border-gray-600 rounded-lg">
          <div className="space-y-4">
            {/* Feltöltött fájlok listája */}
            {attachments.length > 0 && (
              <div className="space-y-2">
                {attachments.map((file, index) => (
                  <div key={index} className="flex items-center justify-between p-2 bg-gray-600 rounded">
                    <div className="flex items-center space-x-2">
                      <FaFile className="text-gray-300" />
                      <span className="text-sm text-gray-300">{file.name}</span>
                      <span className="text-xs text-gray-400">
                        ({(file.size / 1024 / 1024).toFixed(2)} MB)
                      </span>
                      <span className="text-xs text-[#20A64B]">
                        {documentTypeOptions.find(opt => opt.value === file.documentType)?.label}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        type="button"
                        onClick={() => handleEditDocumentType(index, file)}
                        className="text-gray-400 hover:text-[#20A64B] transition-colors"
                      >
                        <FaEdit />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setAttachments(prev => prev.filter((_, i) => i !== index));
                        }}
                        className="text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Fájl feltöltés gomb */}
            <div className="text-center">
              <label className="relative cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  multiple
                  onChange={handleFileUpload}
                />
                <div className="flex flex-col items-center space-y-2 p-4 bg-gray-600 rounded-lg hover:bg-gray-500 transition-colors">
                  {uploading ? (
                    <div className="animate-pulse">Feltöltés folyamatban...</div>
                  ) : (
                    <>
                      <FaCloudUploadAlt className="text-2xl text-[#20A64B]" />
                      <span className="text-sm text-gray-300">
                        Kattintson vagy húzza ide a fájlokat
                      </span>
                      <span className="text-xs text-gray-400">
                        PDF, Word, Excel, képek vagy ZIP fájlok (max. 10MB)
                      </span>
                    </>
                  )}
                </div>
              </label>
            </div>
          </div>
        </div>

        <p className="mt-2 text-sm text-gray-400">
          Csatolhat dokumentumokat, képeket, terveket vagy bármilyen más releváns fájlt a projekthez.
        </p>
      </div>

      {/* Dokumentum típus szerkesztő modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 bg-black opacity-30" />

          <div className="relative bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
            <Dialog.Title className="text-lg font-medium text-white mb-4">
              Dokumentum típus módosítása
            </Dialog.Title>

            <div className="space-y-4">
              {documentTypeOptions.map((option) => (
                <button
                  key={option.value}
                  className={`w-full p-3 rounded-lg text-left ${
                    editingFile?.attachment.documentType === option.value
                      ? 'bg-[#20A64B] text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                  onClick={() => handleUpdateDocumentType(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>

            <button
              className="mt-4 w-full p-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600"
              onClick={() => setIsEditModalOpen(false)}
            >
              Mégse
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}; 