import React, { useState } from 'react';
import { Popover } from '@headlessui/react';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover className="relative">
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Popover.Button as="div" className="cursor-help">
          {children}
        </Popover.Button>

        {isOpen && (
          <Popover.Panel static className="absolute z-10 w-64 px-3 py-2 -mt-1 text-sm text-white transform -translate-x-1/2 bg-gray-900 rounded-lg shadow-lg left-1/2">
            {content}
          </Popover.Panel>
        )}
      </div>
    </Popover>
  );
}; 