import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase';
import { collection, query, where, getDocs, orderBy, updateDoc, doc, addDoc, Timestamp, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { CATEGORIES } from '../../constants/projectCategories';
import { SERVICES } from '../../constants/profileServices';
import { Project, Proposal, ProjectTimeline } from '../../types/project';
import { User } from 'firebase/auth';
import { 
  FaMapMarkerAlt, 
  FaCalendar, 
  FaTag, 
  FaFilter,
  FaCode,
  FaMobile,
  FaWordpress,
  FaPaintBrush,
  FaPalette,
  FaBullhorn,
  FaVideo,
  FaChartLine,
  FaDatabase,
  FaShoppingCart,
  FaRobot,
  FaServer,
  FaShieldAlt,
  FaGamepad,
  FaDesktop,
  FaCogs,
  FaTools,
  FaCheckSquare,
  FaChartBar,
  FaPlug,
  FaLightbulb,
  FaTimes,
  FaFile,
  FaUpload,
  FaTrash
} from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

// Külön interface a Firestore adatokhoz
interface ProjectData extends Omit<Project, 'createdAt' | 'updatedAt' | 'timeline'> {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  timeline: {
    expectedStartDate: Timestamp;
    deadline: Timestamp;
    isFlexible: boolean;
  };
}

// Helper az ikonok kiválasztásához
const getCategoryIcon = (categoryValue: string) => {
  const service = SERVICES.find(s => s.id === categoryValue);
  return service?.icon || FaTag;
};

// Új komponens az ajánlat küldéséhez
interface ProposalModalProps {
  project: Project;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (proposal: Omit<Proposal, 'id' | 'status' | 'createdAt'>) => Promise<void>;
}

interface AttachmentFile {
  file: File;
  id: string;
  uploadProgress?: number;
}

const ProposalModal: React.FC<ProposalModalProps> = ({ project, isOpen, onClose, onSubmit }) => {
  const { user } = useAuth();
  const [amount, setAmount] = useState<number>(project.budget.min);
  const [description, setDescription] = useState('');
  const [estimatedDuration, setEstimatedDuration] = useState<number>(30);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const newAttachments = files.map(file => ({
      file,
      id: crypto.randomUUID()
    }));
    setAttachments(prev => [...prev, ...newAttachments]);
    
    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemoveFile = (id: string) => {
    setAttachments(prev => prev.filter(a => a.id !== id));
  };

  const uploadFiles = async () => {
    const uploadedFiles = await Promise.all(
      attachments.map(async (attachment) => {
        const fileRef = ref(storage, `proposals/${user?.uid}/${project.id}/${attachment.id}-${attachment.file.name}`);
        await uploadBytes(fileRef, attachment.file);
        const url = await getDownloadURL(fileRef);
        
        return {
          id: attachment.id,
          name: attachment.file.name,
          url,
          size: attachment.file.size,
          type: attachment.file.type
        };
      })
    );
    
    return uploadedFiles;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      
      // Fájlok feltöltése
      const uploadedFiles = attachments.length > 0 ? await uploadFiles() : [];
      
      await onSubmit({
        contractorId: user.uid,
        contractorName: user.email?.split('@')[0] || 'Névtelen kivitelező',
        amount,
        currency: 'HUF',
        description,
        estimatedDuration,
        attachments: uploadedFiles
      });
      
      onClose();
      toast.success('Ajánlat sikeresen elküldve!');
    } catch (error) {
      console.error('Error sending proposal:', error);
      toast.error('Hiba történt az ajánlat küldése során');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-gray-800 rounded-lg p-8 w-full max-w-6xl mx-auto max-h-[90vh] overflow-y-auto"
      >
        <div className="flex justify-between items-start mb-8">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold text-white">Ajánlat küldése</h2>
            <p className="text-gray-400">Projekt: {project.title}</p>
            <p className="text-sm text-gray-500">Kategória: {CATEGORIES.find(c => c.value === project.category)?.label}</p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-700/50 rounded-lg"
          >
            <FaTimes className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Projekt leírása (csak olvasható) */}
          <div className="bg-gray-700/30 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-400 mb-2">Projekt leírása</h3>
            <p className="text-gray-300 whitespace-pre-wrap">{project.description}</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Ajánlott összeg (HUF)
              </label>
              <input
                type="number"
                min={project.budget.min}
                max={project.budget.max}
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#20A64B]"
                required
              />
              <p className="text-sm text-gray-400 mt-2">
                Elvárt költségkeret: {project.budget.min.toLocaleString()} - {project.budget.max.toLocaleString()} HUF
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Becsült időtartam (nap)
              </label>
              <input
                type="number"
                min={1}
                value={estimatedDuration}
                onChange={(e) => setEstimatedDuration(Number(e.target.value))}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#20A64B]"
                required
              />
              <p className="text-sm text-gray-400 mt-2">
                Projekt határidő: {project.timeline.deadline ? new Date(project.timeline.deadline).toLocaleDateString('hu-HU') : 'Nincs megadva'}
              </p>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Ajánlat leírása
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#20A64B] min-h-[250px] resize-y"
              placeholder="Írja le az ajánlatát részletesen..."
              required
            />
            <p className="text-sm text-gray-400 mt-2">
              Részletezze az ajánlatát, térjen ki a megvalósítás főbb pontjaira, az esetleges kérdéseire és a javasolt megoldásokra.
            </p>
          </div>

          {/* Fájl feltöltés */}
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Csatolt fájlok
            </label>
            <div className="space-y-4">
              {/* Feltöltött fájlok listája */}
              {attachments.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {attachments.map((file) => (
                    <div
                      key={file.id}
                      className="flex items-center justify-between bg-gray-700/50 rounded-lg px-4 py-3"
                    >
                      <div className="flex items-center gap-3 text-gray-300 flex-1 min-w-0">
                        <FaFile className="text-[#20A64B] w-5 h-5 flex-shrink-0" />
                        <span className="truncate flex-1">{file.file.name}</span>
                        <span className="text-sm text-gray-400 flex-shrink-0">
                          ({(file.file.size / 1024 / 1024).toFixed(2)} MB)
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(file.id)}
                        className="text-gray-400 hover:text-red-400 transition-colors p-2 ml-2 flex-shrink-0"
                      >
                        <FaTrash className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {/* Fájl feltöltés gomb */}
              <div className="flex items-center justify-center w-full">
                <label className="w-full flex flex-col items-center justify-center px-6 py-8 bg-gray-700/50 border-2 border-gray-600 border-dashed rounded-lg cursor-pointer hover:bg-gray-700/70 transition-colors">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <FaUpload className="w-10 h-10 text-gray-400 mb-4" />
                    <p className="mb-2 text-sm text-gray-400">
                      <span className="font-semibold">Kattintson a feltöltéshez</span> vagy húzza ide a fájlokat
                    </p>
                    <p className="text-xs text-gray-400">
                      PDF, Word, Excel, képek (max. 10MB)
                    </p>
                  </div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    multiple
                    onChange={handleFileSelect}
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-4 pt-4 border-t border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-3 text-gray-300 hover:text-white transition-colors"
              disabled={loading}
            >
              Mégsem
            </button>
            <button
              type="submit"
              className="px-8 py-3 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <>
                  <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                  Küldés...
                </>
              ) : (
                'Ajánlat küldése'
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default function ProjectBrowser() {
  const { user, activeProfile } = useAuth();
  const [projects, setProjects] = useState<Project[]>([]);
  const [userProposals, setUserProposals] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  
  useEffect(() => {
    const fetchProjects = async () => {
      if (!user?.uid) return;
      
      if (!activeProfile || activeProfile.type !== 'contractor') {
        toast.error('Csak kivitelezők férhetnek hozzá ehhez az oldalhoz');
        setLoading(false);
        return;
      }
      
      try {
        const projectsRef = collection(db, 'projects');
        let projectQuery;

        if (selectedCategory) {
          projectQuery = query(
            projectsRef,
            where('status', '==', 'active'),
            where('category', '==', selectedCategory),
            orderBy('createdAt', 'desc')
          );
        } else {
          projectQuery = query(
            projectsRef,
            where('status', '==', 'active'),
            orderBy('createdAt', 'desc')
          );
        }

        const querySnapshot = await getDocs(projectQuery);
        
        const projectsData = querySnapshot.docs.map(doc => {
          const data = doc.data() as ProjectData;
          return {
            ...data,
            id: doc.id,
            createdAt: data.createdAt?.toDate() || new Date(),
            updatedAt: data.updatedAt?.toDate() || new Date(),
            timeline: {
              expectedStartDate: data.timeline?.expectedStartDate?.toDate() || null,
              deadline: data.timeline?.deadline?.toDate() || null,
              isFlexible: data.timeline?.isFlexible || false,
              urgency: 'medium' // Default value for existing projects
            } as ProjectTimeline,
            proposalIds: data.proposalIds || []
          } as Project;
        });

        // Csak azokat a projekteket szűrjük ki, amikhez már van kiválasztott ajánlat
        let filteredProjects = projectsData.filter(project => !project.selectedProposalId);

        // Majd lekérjük az összes ajánlatot
        const proposalsPromises = filteredProjects.flatMap(project => 
          (project.proposalIds || []).map(proposalId => 
            getDoc(doc(db, 'proposals', proposalId))
          )
        );

        const proposalDocs = await Promise.all(proposalsPromises);
        const proposalsData = proposalDocs.map(doc => {
          const data = doc.data();
          return data ? {
            id: doc.id,
            projectId: data.projectId as string,
            contractorId: data.contractorId as string,
            ...data
          } : null;
        }).filter((p): p is NonNullable<typeof p> => p !== null);

        // Készítünk egy map-et a felhasználó ajánlatairól
        const userProposalsMap: Record<string, boolean> = {};
        proposalsData.forEach(proposal => {
          if (proposal.contractorId === user.uid) {
            userProposalsMap[proposal.projectId] = true;
          }
        });
        setUserProposals(userProposalsMap);

        // Minden projektet megtartunk, nem szűrünk az ajánlatok alapján
        setProjects(filteredProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
        toast.error('Hiba történt a projektek betöltése során');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [user?.uid, activeProfile, selectedCategory]);

  const formatBudget = (budget?: { min: number; max: number }) => {
    if (!budget) return 'Nem megadott';
    return `${budget.min.toLocaleString()} Ft - ${budget.max.toLocaleString()} Ft`;
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) return 'Nincs megadva';
    return new Intl.DateTimeFormat('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  const handleSendProposal = async (proposal: Omit<Proposal, 'id' | 'status' | 'createdAt'>) => {
    if (!selectedProject || !user) return;

    try {
      // Új proposal dokumentum létrehozása a proposals kollekcióban
      const proposalData = {
        ...proposal,
        projectId: selectedProject.id,
        projectTitle: selectedProject.title,
        clientId: selectedProject.clientId,
        status: 'pending',
        createdAt: Timestamp.fromDate(new Date())
      };

      const proposalRef = await addDoc(collection(db, 'proposals'), proposalData);

      // Projekt frissítése a proposal referenciával
      const projectRef = doc(db, 'projects', selectedProject.id);
      await updateDoc(projectRef, {
        proposalIds: [...(selectedProject.proposalIds || []), proposalRef.id],
        updatedAt: Timestamp.fromDate(new Date())
      });

    } catch (error) {
      console.error('Error sending proposal:', error);
      throw error;
    }
  };

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        {/* Fejléc és szűrők */}
        <div className="bg-gray-800 rounded-lg p-6 mb-6">
          <h1 className="text-2xl font-bold text-white mb-6">Új projektek böngészése</h1>
          
          {/* Kategória szűrők */}
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-gray-400">
              <FaFilter className="w-4 h-4" />
              <span>Szűrés kategória szerint</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
              <button
                onClick={() => setSelectedCategory('')}
                className={`px-4 py-3 rounded-lg transition-colors text-left flex items-center gap-2 ${
                  selectedCategory === ''
                    ? 'bg-[#20A64B] text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                <FaFilter className="w-4 h-4 flex-shrink-0" />
                <span className="truncate">Összes kategória</span>
              </button>
              {CATEGORIES.map(category => {
                const Icon = getCategoryIcon(category.value);
                return (
                  <button
                    key={category.value}
                    onClick={() => setSelectedCategory(category.value)}
                    className={`px-4 py-3 rounded-lg transition-colors text-left flex items-center gap-2 group ${
                      selectedCategory === category.value
                        ? 'bg-[#20A64B] text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                  >
                    <Icon className={`w-4 h-4 flex-shrink-0 transition-colors ${
                      selectedCategory === category.value
                        ? 'text-white'
                        : 'text-[#20A64B] group-hover:text-white'
                    }`} />
                    <span className="truncate">{category.label}</span>
                    {selectedCategory === category.value && (
                      <span className="text-xs bg-[#178f3f] px-2 py-0.5 rounded-full ml-auto">
                        {projects.filter(p => p.category === category.value).length}
                      </span>
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Projektek listája */}
        <div className="space-y-6">
          {loading ? (
            <div className="bg-gray-800 rounded-lg p-6">
              <div className="flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B]"></div>
              </div>
            </div>
          ) : projects.length === 0 ? (
            <div className="bg-gray-800 rounded-lg p-6">
              <p className="text-gray-400 text-center">
                {selectedCategory 
                  ? 'Ebben a kategóriában jelenleg nincs elérhető projekt'
                  : 'Jelenleg nincs elérhető projekt'
                }
              </p>
            </div>
          ) : (
            <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {projects.map(project => (
                <motion.div
                  key={project.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`bg-gray-800 rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300 ${
                    userProposals[project.id] ? 'ring-2 ring-[#20A64B]/50' : ''
                  }`}
                >
                  {userProposals[project.id] && (
                    <div className="bg-[#20A64B]/10 text-[#20A64B] px-4 py-2 flex items-center gap-2 text-sm">
                      <FaCheckSquare className="w-4 h-4" />
                      <span>Ajánlat elküldve</span>
                    </div>
                  )}
                  
                  <div className="p-6">
                    {/* Projekt fejléc */}
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-semibold text-white mb-2">{project.title}</h3>
                        <div className="flex items-center gap-2 text-sm text-gray-400">
                          <span className="flex items-center gap-1">
                            <FaTag className="w-4 h-4" />
                            {CATEGORIES.find(c => c.value === project.category)?.label}
                          </span>
                          <span>•</span>
                          <span>{project.subcategory}</span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 items-end">
                        <span className="px-3 py-1 bg-[#20A64B] text-white text-sm rounded-full">
                          Új
                        </span>
                        {project.clientId === user.uid && (
                          <span className="px-3 py-1 bg-gray-600 text-gray-300 text-sm rounded-full">
                            Saját projekt
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Projekt leírás */}
                    <p className="text-gray-300 mb-6 line-clamp-3">{project.description}</p>

                    {/* Projekt részletek */}
                    <div className="space-y-3 mb-6">
                      {/* Költségkeret */}
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-gray-400">Költségkeret:</span>
                        <span className="text-[#20A64B] font-medium">{formatBudget(project.budget)}</span>
                      </div>

                      {/* Határidő */}
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-gray-400">Határidő:</span>
                        <span className="text-white">{formatDate(project.timeline.deadline)}</span>
                      </div>

                      {/* Projekt mérete */}
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-gray-400">Projekt mérete:</span>
                        <span className="text-white">
                          {project.scope === 'small' && 'Kis projekt'}
                          {project.scope === 'medium' && 'Közepes projekt'}
                          {project.scope === 'large' && 'Nagy projekt'}
                        </span>
                      </div>
                    </div>

                    {/* Ajánlat küldése gomb */}
                    <div className="p-6">
                      {userProposals[project.id] ? (
                        <button
                          className="w-full py-3 px-4 bg-gray-700 text-gray-300 rounded-lg flex items-center justify-center gap-2 cursor-default"
                          disabled
                        >
                          <FaCheckSquare className="w-4 h-4 text-[#20A64B]" />
                          <span>Ajánlat elküldve</span>
                        </button>
                      ) : (
                        <button
                          className={`w-full py-3 text-white rounded-lg transition-colors flex items-center justify-center gap-2 ${
                            project.clientId === user?.uid
                              ? 'bg-gray-600 cursor-not-allowed'
                              : 'bg-[#20A64B] hover:bg-[#178f3f]'
                          }`}
                          onClick={() => {
                            if (user && project.clientId !== user.uid) {
                              setSelectedProject(project);
                              setIsProposalModalOpen(true);
                            }
                          }}
                          disabled={!user || project.clientId === user.uid}
                        >
                          {project.clientId === user?.uid ? 'Saját projekt' : 'Ajánlat küldése'}
                        </button>
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </div>

      {selectedProject && (
        <ProposalModal
          project={selectedProject}
          isOpen={isProposalModalOpen}
          onClose={() => setIsProposalModalOpen(false)}
          onSubmit={handleSendProposal}
        />
      )}
    </div>
  );
}