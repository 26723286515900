import { 
  FaCode,          // Webfejlesztés
  FaMobile,        // Mobilalkalmazás
  FaWordpress,     // WordPress
  FaPaintBrush,    // UI/UX Design
  FaPalette,       // Grafikai tervezés
  FaBullhorn,      // Marketing
  FaVideo,         // Videókészítés
  FaChartLine,     // SEO
  FaDatabase,      // Backend fejlesztés
  FaShoppingCart,  // E-commerce
  FaRobot,         // AI/ML
  FaServer,        // DevOps
  FaShieldAlt,     // Kiberbiztonság
  FaGamepad,       // Játékfejlesztés
  FaDesktop,       // Asztali alkalmazás
  FaCogs,          // software_development
  FaTools,         // maintenance
  FaCheckSquare,   // testing_qa
  FaChartBar,      // data_analytics
  FaPlug,          // integration
  FaLightbulb      // consulting
} from 'react-icons/fa';

export const SERVICES = [
  {
    id: 'web_development',
    name: 'Webfejlesztés',
    description: 'Egyedi weboldalak és webalkalmazások',
    icon: FaCode
  },
  {
    id: 'mobile_app',
    name: 'Mobilalkalmazás',
    description: 'iOS és Android alkalmazások',
    icon: FaMobile
  },
  {
    id: 'wordpress',
    name: 'WordPress',
    description: 'WordPress fejlesztés és testreszabás',
    icon: FaWordpress
  },
  {
    id: 'ui_design',
    name: 'UI/UX Design',
    description: 'Felhasználói felület tervezés',
    icon: FaPaintBrush
  },
  {
    id: 'graphic_design',
    name: 'Grafikai tervezés',
    description: 'Logók, arculatok, nyomtatványok',
    icon: FaPalette
  },
  {
    id: 'marketing',
    name: 'Marketing',
    description: 'Online marketing és reklám',
    icon: FaBullhorn
  },
  {
    id: 'video',
    name: 'Videókészítés',
    description: 'Videók és animációk készítése',
    icon: FaVideo
  },
  {
    id: 'seo',
    name: 'SEO',
    description: 'Keresőoptimalizálás',
    icon: FaChartLine
  },
  {
    id: 'backend',
    name: 'Backend fejlesztés',
    description: 'Szerveroldali alkalmazások',
    icon: FaDatabase
  },
  {
    id: 'ecommerce',
    name: 'E-commerce',
    description: 'Webáruházak fejlesztése',
    icon: FaShoppingCart
  },
  {
    id: 'ai_ml',
    name: 'AI/ML',
    description: 'Mesterséges intelligencia megoldások',
    icon: FaRobot
  },
  {
    id: 'devops',
    name: 'DevOps',
    description: 'Üzemeltetés és automatizálás',
    icon: FaServer
  },
  {
    id: 'security',
    name: 'Kiberbiztonság',
    description: 'Biztonsági audit és védelem',
    icon: FaShieldAlt
  },
  {
    id: 'game_dev',
    name: 'Játékfejlesztés',
    description: 'Játékok és interaktív alkalmazások',
    icon: FaGamepad
  },
  {
    id: 'desktop_app',
    name: 'Asztali alkalmazás',
    description: 'Windows, macOS, Linux alkalmazások',
    icon: FaDesktop
  },
  {
    id: 'software_development',
    name: 'Egyedi szoftverfejlesztés',
    description: 'Vállalati és üzleti alkalmazások',
    icon: FaCogs
  },
  {
    id: 'maintenance',
    name: 'Üzemeltetés és karbantartás',
    description: 'Rendszerek üzemeltetése és karbantartása',
    icon: FaTools
  },
  {
    id: 'testing_qa',
    name: 'Tesztelés és QA',
    description: 'Minőségbiztosítás és tesztelés',
    icon: FaCheckSquare
  },
  {
    id: 'data_analytics',
    name: 'Adatelemzés és BI',
    description: 'Üzleti intelligencia megoldások',
    icon: FaChartBar
  },
  {
    id: 'integration',
    name: 'Rendszerintegráció',
    description: 'Rendszerek összekapcsolása',
    icon: FaPlug
  },
  {
    id: 'consulting',
    name: 'IT Tanácsadás',
    description: 'Szakértői tanácsadás',
    icon: FaLightbulb
  }
] as const;

// Típus definíció a szolgáltatás ID-khoz
export type ServiceId = typeof SERVICES[number]['id']; 