import { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { User, Profile } from '../types';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, getDoc, setDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: User | null;
  activeProfile: Profile | null;
  profiles: Profile[];
  loading: boolean;
  signInWithGoogle: () => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<boolean>;
  signUpWithEmail: (email: string, password: string, name: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  createProfile: (profileData: Omit<Profile, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  switchProfile: (profileId: string) => Promise<void>;
  updateProfile: (profileId: string, profileData: Partial<Profile>) => Promise<void>;
  deleteProfile: (profileId: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const googleProvider = new GoogleAuthProvider();

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [activeProfile, setActiveProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkAndSetFirstAdmin = async (uid: string) => {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const isFirstUser = usersSnapshot.empty;
      return isFirstUser;
    } catch (error) {
      console.error('Error checking first admin:', error);
      return false;
    }
  };

  const createOrUpdateUser = async (
    uid: string,
    email: string | null,
    displayName: string | null
  ) => {
    try {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        const isFirstUser = await checkAndSetFirstAdmin(uid);
        const userData = {
          uid,
          email: email || '',
          name: displayName || email?.split('@')[0] || 'Felhasználó',
          isAdmin: isFirstUser,
          createdAt: new Date(),
          profiles: [],
          activeProfile: null
        };
        await setDoc(userRef, userData);
        return userData;
      }

      return { ...userDoc.data(), id: userDoc.id } as User;
    } catch (error) {
      console.error('Error creating/updating user:', error);
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const { user: googleUser } = result;

      const userData = await createOrUpdateUser(
        googleUser.uid,
        googleUser.email,
        googleUser.displayName
      );
      
      setUser(userData);

      if (!userData.profiles || userData.profiles.length === 0) {
        navigate('/profile');
      } else {
        navigate('/');
      }

      toast.success('Sikeres bejelentkezés!');
    } catch (error: any) {
      if (error.code === 'auth/popup-closed-by-user') {
        console.log('Bejelentkezés megszakítva');
        return;
      }
      console.error('Google bejelentkezési hiba:', error);
      toast.error('Hiba történt a bejelentkezés során');
      throw error;
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      const userData = await createOrUpdateUser(
        firebaseUser.uid,
        firebaseUser.email,
        firebaseUser.displayName
      );
      
      setUser(userData);

      if (!userData.profiles || userData.profiles.length === 0) {
        navigate('/profile');
      } else {
        navigate('/');
      }

      toast.success('Sikeres bejelentkezés!');
      return true;
    } catch (error) {
      console.error('Error signing in with email:', error);
      toast.error('Hibás email cím vagy jelszó!');
      return false;
    }
  };

  const signUpWithEmail = async (email: string, password: string, name: string) => {
    try {
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);
      const userData = await createOrUpdateUser(firebaseUser.uid, email, name);
      setUser(userData);
      
      navigate('/profile');
      
      toast.success('Sikeres regisztráció!');
    } catch (error: any) {
      console.error('Error signing up with email:', error);
      
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Ez az email cím már használatban van');
      } else if (error.code === 'auth/weak-password') {
        toast.error('A jelszó túl gyenge');
      } else {
        toast.error('Hiba történt a regisztráció során');
      }
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Jelszó visszaállító email elküldve!');
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Hiba történt a jelszó visszaállítás során');
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      toast.success('Sikeres kijelentkezés!');
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Hiba történt a kijelentkezés során');
    }
  };

  const loadProfiles = async (userId: string) => {
    const profilesRef = collection(db, 'profiles');
    const q = query(profilesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const loadedProfiles = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Profile[];
    setProfiles(loadedProfiles);

    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data() as User;
    if (userData.activeProfileId) {
      const activeProfile = loadedProfiles.find(p => p.id === userData.activeProfileId);
      if (activeProfile) setActiveProfile(activeProfile);
    }
  };

  const createProfile = async (profileData: Omit<Profile, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) return;

    try {
      const profileRef = doc(collection(db, 'profiles'));
      const newProfile: Profile = {
        id: profileRef.id,
        userId: user.uid,
        ...profileData,
        createdAt: new Date(),
        updatedAt: new Date()
      };

      await setDoc(profileRef, newProfile);

      if (profiles.length === 0) {
        await setDoc(doc(db, 'users', user.uid), {
          ...user,
          activeProfileId: newProfile.id
        }, { merge: true });
        
        setActiveProfile(newProfile);
      }

      setProfiles(prev => [...prev, newProfile]);

      toast.success('Profil sikeresen létrehozva!');
      
      return newProfile;
    } catch (error) {
      console.error('Error creating profile:', error);
      toast.error('Hiba történt a profil létrehozása során');
      throw error;
    }
  };

  const switchProfile = async (profileId: string) => {
    if (!user) return;

    try {
      await setDoc(doc(db, 'users', user.uid), {
        ...user,
        activeProfileId: profileId
      }, { merge: true });

      const newActiveProfile = profiles.find(p => p.id === profileId);
      if (newActiveProfile) setActiveProfile(newActiveProfile);

      toast.success('Profil sikeresen váltva!');
    } catch (error) {
      console.error('Error switching profile:', error);
      toast.error('Hiba történt a profilváltás során');
    }
  };

  const updateProfile = async (profileId: string, profileData: Partial<Profile>) => {
    if (!user) return;

    try {
      const profileRef = doc(db, 'profiles', profileId);
      await updateDoc(profileRef, {
        ...profileData,
        updatedAt: new Date()
      });

      setProfiles(prev => prev.map(p => 
        p.id === profileId ? { ...p, ...profileData } : p
      ));

      if (activeProfile?.id === profileId) {
        setActiveProfile(prev => prev ? { ...prev, ...profileData } : null);
      }

      toast.success('Profil sikeresen frissítve!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Hiba történt a profil frissítése során');
    }
  };

  const deleteProfile = async (profileId: string) => {
    if (!user) return;

    try {
      const userRef = doc(db, 'users', user.uid);
      const updatedProfiles = profiles.filter(p => p.id !== profileId);
      const deletedProfile = profiles.find(p => p.id === profileId);

      const newActiveProfile = deletedProfile === activeProfile ? updatedProfiles[0] : activeProfile;

      await setDoc(userRef, {
        ...user,
        profiles: updatedProfiles,
        activeProfileId: newActiveProfile?.id
      }, { merge: true });

      setProfiles(updatedProfiles);
      if (activeProfile?.id === profileId) setActiveProfile(newActiveProfile);

      toast.success('Profil sikeresen törölve!');
    } catch (error) {
      console.error('Error deleting profile:', error);
      toast.error('Hiba történt a profil törlése során');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userData = await createOrUpdateUser(
            firebaseUser.uid,
            firebaseUser.email,
            firebaseUser.displayName
          );
          setUser(userData);
          await loadProfiles(userData.uid);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUser(null);
        setProfiles([]);
        setActiveProfile(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    activeProfile,
    profiles,
    loading,
    signInWithGoogle,
    signInWithEmail,
    signUpWithEmail,
    resetPassword,
    signOut,
    createProfile,
    switchProfile,
    updateProfile,
    deleteProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
