import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Profile } from '../../types';
import toast from 'react-hot-toast';
import { FaPaperPlane, FaTimes } from 'react-icons/fa';

interface ContactFormProps {
  contractor: Profile;
  onClose: () => void;
}

export default function ContactForm({ contractor, onClose }: ContactFormProps) {
  const { user } = useAuth();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setLoading(true);
    try {
      await addDoc(collection(db, 'messages'), {
        from: {
          id: user.uid,
          name: user.name,
          email: user.email
        },
        to: {
          id: contractor.userId,
          name: contractor.company?.name || 'Kivitelező',
          email: contractor.contactEmail
        },
        subject,
        message,
        createdAt: serverTimestamp(),
        read: false,
        archived: false
      });

      toast.success('Üzenet sikeresen elküldve!');
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Hiba történt az üzenet küldése során');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-lg w-full max-w-lg">
        <div className="flex items-center justify-between p-6 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-white">
            Kapcsolatfelvétel
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FaTimes className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Címzett
            </label>
            <div className="text-white">
              {contractor.company?.name || 'Kivitelező'}
            </div>
          </div>

          <div>
            <label htmlFor="subject" className="block text-sm font-medium text-gray-300 mb-1">
              Tárgy
            </label>
            <input
              id="subject"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
              placeholder="Üzenet tárgya"
              required
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">
              Üzenet
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-[#20A64B] focus:border-transparent h-32 resize-none"
              placeholder="Írja le üzenetét..."
              required
            />
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Mégse
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-6 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors disabled:opacity-50 flex items-center gap-2"
            >
              <FaPaperPlane className="w-4 h-4" />
              {loading ? 'Küldés...' : 'Üzenet küldése'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 