import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Project, ProjectCategory, ProjectQuestionnaire, ProjectRequirement, DocumentType } from '../../types/project';
import toast from 'react-hot-toast';
import { FaArrowLeft, FaTrash, FaFile, FaCloudUploadAlt, FaEdit, FaLeaf, FaInfoCircle } from 'react-icons/fa';
import { CATEGORIES } from '../../constants/projectCategories';
import { Dialog } from '@headlessui/react';

// Add document type options
const documentTypeOptions: { value: DocumentType; label: string }[] = [
  { value: 'project_description', label: 'Projekt leírás' },
  { value: 'technical_spec', label: 'Műszaki specifikáció' },
  { value: 'design_doc', label: 'Tervrajz, dizájn dokumentum' },
  { value: 'reference', label: 'Referencia anyag' },
  { value: 'legal', label: 'Jogi dokumentum' },
  { value: 'other', label: 'Egyéb' }
] as const;

// Update the ProjectAttachment interface
interface ProjectAttachment {
  name: string;
  url: string;
  type: string;
  size: number;
  documentType: DocumentType;
}

export default function ProjectEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Form állapotok
  const [basicInfo, setBasicInfo] = useState({
    title: '',
    category: '' as ProjectCategory,
    subcategory: '',
    description: '',
    scope: '',
    isGreenfield: false
  });

  const [budget, setBudget] = useState({
    min: 0,
    max: 0,
    isFlexible: false,
    currency: 'HUF',
    advancePayment: {
      enabled: false,
      percentage: 30
    },
    insurance: 'none'
  });

  const [timeline, setTimeline] = useState({
    expectedStartDate: undefined as Date | undefined,
    deadline: undefined as Date | undefined,
    isFlexible: false
  });

  const [requirements, setRequirements] = useState<ProjectRequirement[]>([]);

  const [questionnaire, setQuestionnaire] = useState<ProjectQuestionnaire>({
    // Döntéshozatal és együttműködés
    decisionTime: '',          // Visszajelzési idő kérdések esetén
    urgentDecisionTime: '',    // Sürgős döntések maximális ideje
    meetingAvailability: '',   // Projekt egyeztetések gyakorisága
    unavailablePeriods: '',    // Nem elérhető időszakok

    // Kommunikáció
    urgentResponseTime: '',    // Elvárt válaszidő sürgős esetben
    updateFrequency: '',       // Státusz frissítések gyakorisága
    communicationChannel: {     // Kommunikációs csatornák
      urgent: 'email',         // Sürgős esetben
      normal: 'email'          // Általános esetben
    }
  });

  // State a fájlokhoz
  const [attachments, setAttachments] = useState<ProjectAttachment[]>([]);
  const [uploading, setUploading] = useState(false);

  // Add state for edit modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingFile, setEditingFile] = useState<{ index: number; attachment: ProjectAttachment } | null>(null);

  // Projekt betöltése
  useEffect(() => {
    const fetchProject = async () => {
      if (!id || !user) return;

      try {
        const projectRef = doc(db, 'projects', id);
        const projectSnap = await getDoc(projectRef);

        if (!projectSnap.exists()) {
          toast.error('A projekt nem található');
          navigate('/projects');
          return;
        }

        const projectData = { id: projectSnap.id, ...projectSnap.data() } as Project;
        
        // Form mezők feltöltése
        setBasicInfo({
          title: projectData.title,
          category: projectData.category,
          subcategory: projectData.subcategory || '',
          description: projectData.description,
          scope: projectData.scope,
          isGreenfield: projectData.isGreenfield ?? false
        });

        setBudget({
          min: projectData.budget.min,
          max: projectData.budget.max,
          isFlexible: projectData.budget.isFlexible,
          currency: projectData.budget.currency,
          advancePayment: projectData.budget.advancePayment || { enabled: false, percentage: 30 },
          insurance: projectData.budget.insurance || 'none'
        });

        if (projectData.timeline.expectedStartDate) {
          timeline.expectedStartDate = (projectData.timeline.expectedStartDate as unknown as Timestamp).toDate();
        }
        if (projectData.timeline.deadline) {
          timeline.deadline = (projectData.timeline.deadline as unknown as Timestamp).toDate();
        }
        timeline.isFlexible = projectData.timeline.isFlexible;

        setRequirements(projectData.requirements || []);

        setQuestionnaire(projectData.questionnaire || {
          decisionTime: '',
          urgentDecisionTime: '',
          meetingAvailability: '',
          unavailablePeriods: '',
          urgentResponseTime: '',
          updateFrequency: '',
          communicationChannel: {
            urgent: 'email',
            normal: 'email'
          }
        });

        // Update how attachments are handled
        if (Array.isArray(projectData.attachments)) {
          const formattedAttachments = projectData.attachments.map((attachment: string | ProjectAttachment) => {
            if (typeof attachment === 'string') {
              return {
                name: attachment.split('/').pop() || 'Unnamed file',
                url: attachment,
                type: 'unknown',
                size: 0,
                documentType: 'other' as DocumentType
              };
            }
            return {
              name: attachment.name || 'Unnamed file',
              url: attachment.url || '',
              type: attachment.type || 'unknown',
              size: attachment.size || 0,
              documentType: (attachment.documentType || 'other') as DocumentType
            };
          });
          setAttachments(formattedAttachments);
        } else {
          setAttachments([]);
        }

      } catch (err) {
        console.error('Error fetching project:', err);
        toast.error('Hiba történt a projekt betöltése során');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id, user, navigate]);

  // Form kezelés
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) return;

    try {
      setSaving(true);

      // Validáljuk a kérdőív mezőit
      const questionnaireValidation = {
        decisionTime: questionnaire.decisionTime || '',
        urgentDecisionTime: questionnaire.urgentDecisionTime || '',
        meetingAvailability: questionnaire.meetingAvailability || '',
        unavailablePeriods: questionnaire.unavailablePeriods?.trim() || '',
        urgentResponseTime: questionnaire.urgentResponseTime || '',
        updateFrequency: questionnaire.updateFrequency || '',
        communicationChannel: {
          urgent: questionnaire.communicationChannel?.urgent || 'email',
          normal: questionnaire.communicationChannel?.normal || 'email'
        }
      };

      await updateDoc(doc(db, 'projects', id), {
        title: basicInfo.title,
        category: basicInfo.category,
        subcategory: basicInfo.subcategory,
        description: basicInfo.description,
        scope: basicInfo.scope,
        isGreenfield: basicInfo.isGreenfield,
        budget: {
          min: budget.min,
          max: budget.max,
          isFlexible: budget.isFlexible,
          currency: budget.currency,
          advancePayment: budget.advancePayment,
          insurance: budget.insurance
        },
        timeline: {
          expectedStartDate: timeline.expectedStartDate,
          deadline: timeline.deadline,
          isFlexible: timeline.isFlexible
        },
        requirements: requirements,
        questionnaire: questionnaireValidation,
        attachments: attachments.map(a => ({
          name: a.name,
          url: a.url,
          type: a.type,
          size: a.size,
          documentType: a.documentType
        })),
        updatedAt: new Date()
      });
      
      toast.success('Projekt sikeresen frissítve!');
      navigate(`/projects/${id}`);
    } catch (err) {
      console.error('Error updating project:', err);
      toast.error('Hiba történt a projekt frissítése során');
    } finally {
      setSaving(false);
    }
  };

  const handleAddRequirement = () => {
    setRequirements(prev => [...prev, {
      description: '',
      priority: 'must',
      category: 'functional'
    } as ProjectRequirement]);
  };

  const handleRemoveRequirement = (index: number) => {
    setRequirements(prev => prev.filter((_, i) => i !== index));
  };

  const handleUpdateRequirement = (index: number, field: keyof ProjectRequirement, value: string) => {
    setRequirements(prev => prev.map((req, i) => {
      if (i === index) {
        return {
          ...req,
          [field]: value
        } as ProjectRequirement;
      }
      return req;
    }));
  };

  const handleEditDocumentType = (index: number, attachment: ProjectAttachment) => {
    setEditingFile({ index, attachment });
    setIsEditModalOpen(true);
  };

  const handleUpdateDocumentType = (documentType: DocumentType) => {
    if (editingFile) {
      const newAttachments = [...attachments];
      newAttachments[editingFile.index] = {
        ...editingFile.attachment,
        documentType
      };
      setAttachments(newAttachments);
      setIsEditModalOpen(false);
      setEditingFile(null);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    setUploading(true);
    try {
      for (const file of files) {
        if (file.size > 10 * 1024 * 1024) {
          toast.error(`${file.name} mérete nagyobb mint 10MB`);
          continue;
        }

        const storageRef = ref(storage, `projects/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        const newAttachment: ProjectAttachment = {
          name: file.name,
          url: url,
          type: file.type,
          size: file.size,
          documentType: 'other' as DocumentType
        };
        setAttachments(prev => [...prev, newAttachment]);
      }
      toast.success('Fájlok sikeresen feltöltve!');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Hiba történt a fájl feltöltése során');
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B]"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1920px] mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg p-6">
        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={() => navigate(-1)}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FaArrowLeft className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-bold text-white">Projekt szerkesztése</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Alapinformációk */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Projekt címe
              </label>
              <input
                type="text"
                value={basicInfo.title}
                onChange={(e) => setBasicInfo(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                required
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Kategória
                </label>
                <select
                  value={basicInfo.category}
                  onChange={(e) => setBasicInfo(prev => ({ 
                    ...prev, 
                    category: e.target.value as ProjectCategory,
                    subcategory: ''
                  }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                  required
                >
                  <option value="">Válasszon kategóriát</option>
                  {CATEGORIES.map(category => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>

              {basicInfo.category && (
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Alkategória
                  </label>
                  <select
                    value={basicInfo.subcategory}
                    onChange={(e) => setBasicInfo(prev => ({ ...prev, subcategory: e.target.value }))}
                    className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                    required
                  >
                    <option value="">Válasszon alkategóriát</option>
                    {CATEGORIES.find(c => c.value === basicInfo.category)?.subcategories.map(sub => (
                      <option key={sub} value={sub}>
                        {sub}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            {/* Zöldmezős projekt opció */}
            <div className="mt-6">
              <label className="relative inline-flex items-start gap-3 cursor-pointer group">
                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    checked={basicInfo.isGreenfield}
                    onChange={(e) => setBasicInfo(prev => ({ ...prev, isGreenfield: e.target.checked }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[#20A64B] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray-400 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#20A64B] group-hover:after:bg-gray-300"></div>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-300">A projekt zöldmezős</span>
                  <p className="mt-1 text-sm text-gray-400">
                    Teljesen új projekt, amelyen korábban még nem dolgozott más kivitelező
                  </p>
                </div>
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Projekt leírása
              </label>
              <textarea
                value={basicInfo.description}
                onChange={(e) => setBasicInfo(prev => ({ ...prev, description: e.target.value }))}
                rows={6}
                className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                required
              />
            </div>
          </div>

          {/* Költségvetés */}
          <div className="space-y-6">
            <h2 className="text-lg font-medium text-white">Költségvetés</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Minimum összeg (HUF)
                </label>
                <input
                  type="number"
                  value={budget.min}
                  onChange={(e) => setBudget(prev => ({ ...prev, min: parseInt(e.target.value) }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Maximum összeg (HUF)
                </label>
                <input
                  type="number"
                  value={budget.max}
                  onChange={(e) => setBudget(prev => ({ ...prev, max: parseInt(e.target.value) }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                  required
                />
              </div>
            </div>

            {/* Előlegfizetés */}
            <div className="mt-6">
              <label className="relative inline-flex items-start gap-3 cursor-pointer group">
                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    checked={budget.advancePayment?.enabled || false}
                    onChange={(e) => setBudget(prev => ({ 
                      ...prev, 
                      advancePayment: {
                        enabled: e.target.checked,
                        percentage: e.target.checked ? (prev.advancePayment?.percentage || 30) : 0
                      }
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[#20A64B] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray-400 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#20A64B] group-hover:after:bg-gray-300"></div>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-300">Előlegfizetés engedélyezése</span>
                  <p className="mt-1 text-sm text-gray-400">
                    A projekt kezdetén előleget fizetek
                  </p>
                </div>
              </label>

              {budget.advancePayment?.enabled && (
                <div className="mt-4 ml-14">
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Előleg mértéke (%)
                  </label>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={budget.advancePayment?.percentage || 0}
                    onChange={(e) => setBudget(prev => ({
                      ...prev,
                      advancePayment: {
                        ...prev.advancePayment,
                        percentage: Math.min(100, Math.max(0, parseInt(e.target.value) || 0))
                      }
                    }))}
                    className="w-32 px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                  />
                </div>
              )}
            </div>

            {/* Biztosítás */}
            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Projekt biztosítás
              </label>
              <div className="space-y-3">
                <label className="relative flex items-start cursor-pointer p-3 rounded-lg bg-gray-700/50 hover:bg-gray-700 transition-colors">
                  <div className="flex items-center h-5">
                    <input
                      type="radio"
                      name="insurance"
                      value="none"
                      checked={budget.insurance === 'none'}
                      onChange={(e) => setBudget(prev => ({ ...prev, insurance: 'none' }))}
                      className="h-4 w-4 text-[#20A64B] border-gray-600 focus:ring-[#20A64B]"
                    />
                  </div>
                  <div className="ml-3">
                    <span className="text-sm font-medium text-white">Nincs biztosítás</span>
                    <p className="text-sm text-gray-400">
                      Nem kérek projekt biztosítást
                    </p>
                  </div>
                </label>

                <label className="relative flex items-start cursor-pointer p-3 rounded-lg bg-gray-700/50 hover:bg-gray-700 transition-colors">
                  <div className="flex items-center h-5">
                    <input
                      type="radio"
                      name="insurance"
                      value="basic"
                      checked={budget.insurance === 'basic'}
                      onChange={(e) => setBudget(prev => ({ ...prev, insurance: 'basic' }))}
                      className="h-4 w-4 text-[#20A64B] border-gray-600 focus:ring-[#20A64B]"
                    />
                  </div>
                  <div className="ml-3">
                    <span className="text-sm font-medium text-white">Alap biztosítás</span>
                    <p className="text-sm text-gray-400">
                      Alapvető védelem a projekt során felmerülő kockázatok ellen
                    </p>
                  </div>
                </label>

                <label className="relative flex items-start cursor-pointer p-3 rounded-lg bg-gray-700/50 hover:bg-gray-700 transition-colors">
                  <div className="flex items-center h-5">
                    <input
                      type="radio"
                      name="insurance"
                      value="premium"
                      checked={budget.insurance === 'premium'}
                      onChange={(e) => setBudget(prev => ({ ...prev, insurance: 'premium' }))}
                      className="h-4 w-4 text-[#20A64B] border-gray-600 focus:ring-[#20A64B]"
                    />
                  </div>
                  <div className="ml-3">
                    <span className="text-sm font-medium text-white">Prémium biztosítás</span>
                    <p className="text-sm text-gray-400">
                      Teljes körű védelem minden lehetséges kockázat ellen
                    </p>
                  </div>
                </label>
              </div>
            </div>

            <div className="mt-4">
              <label className="relative inline-flex items-start gap-3 cursor-pointer group">
                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    checked={budget.isFlexible}
                    onChange={(e) => setBudget(prev => ({ ...prev, isFlexible: e.target.checked }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[#20A64B] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray-400 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#20A64B] group-hover:after:bg-gray-300"></div>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-300">A költségvetés rugalmas</span>
                  <p className="mt-1 text-sm text-gray-400">
                    A megadott költségkeret rugalmasan kezelhető a projekt során
                  </p>
                </div>
              </label>
            </div>
          </div>

          {/* Követelmények */}
          <div className="space-y-6">
            <h2 className="text-lg font-medium text-white">Követelmények</h2>
            {requirements.map((req, index) => (
              <div key={index} className="grid grid-cols-1 md:grid-cols-[1fr,auto,auto] gap-4">
                <input
                  type="text"
                  value={req.description}
                  onChange={(e) => handleUpdateRequirement(index, 'description', e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                  placeholder="Követelmény leírása"
                />
                <select
                  value={req.priority}
                  onChange={(e) => handleUpdateRequirement(index, 'priority', e.target.value as 'must' | 'should' | 'could')}
                  className="px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                >
                  <option value="must">Must have</option>
                  <option value="should">Should have</option>
                  <option value="could">Could have</option>
                </select>
                <button
                  type="button"
                  onClick={() => handleRemoveRequirement(index)}
                  className="px-3 py-2 text-gray-400 hover:text-red-500 transition-colors"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddRequirement}
              className="text-[#20A64B] hover:text-[#178f3f] transition-colors"
            >
              + Követelmény hozzáadása
            </button>
          </div>

          {/* Időzítés */}
          <div className="space-y-6">
            <h2 className="text-lg font-medium text-white">Időzítés</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Tervezett kezdés
                </label>
                <input
                  type="date"
                  value={timeline.expectedStartDate?.toISOString().split('T')[0] || ''}
                  onChange={(e) => setTimeline(prev => ({ 
                    ...prev, 
                    expectedStartDate: e.target.value ? new Date(e.target.value) : undefined 
                  }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Határidő
                </label>
                <input
                  type="date"
                  value={timeline.deadline?.toISOString().split('T')[0] || ''}
                  onChange={(e) => setTimeline(prev => ({ 
                    ...prev, 
                    deadline: e.target.value ? new Date(e.target.value) : undefined 
                  }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                />
              </div>
            </div>
            <div className="mt-4">
              <label className="relative inline-flex items-start gap-3 cursor-pointer group">
                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    checked={timeline.isFlexible}
                    onChange={(e) => setTimeline(prev => ({ ...prev, isFlexible: e.target.checked }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[#20A64B] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray-400 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#20A64B] group-hover:after:bg-gray-300"></div>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-300">A határidő rugalmas</span>
                  <p className="mt-1 text-sm text-gray-400">
                    A határidő módosítható a projekt komplexitásának függvényében
                  </p>
                </div>
              </label>
            </div>
          </div>

          {/* Kérdőív */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white">Kérdőív</h2>
            
            {/* Döntéshozatal és együttműködés */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-white mb-4">Döntéshozatal és együttműködés</h3>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Visszajelzési idő kérdések esetén
                  </label>
                  <select
                    value={questionnaire.decisionTime}
                    onChange={(e) => setQuestionnaire({
                      ...questionnaire,
                      decisionTime: e.target.value
                    })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                  >
                    <option value="">Válasszon...</option>
                    <option value="24_hours">24 órán belül</option>
                    <option value="2_days">2 napon belül</option>
                    <option value="1_week">1 héten belül</option>
                    <option value="2_weeks">2 héten belül</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Projekt egyeztetések gyakorisága
                  </label>
                  <select
                    value={questionnaire.meetingAvailability}
                    onChange={(e) => setQuestionnaire({
                      ...questionnaire,
                      meetingAvailability: e.target.value
                    })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                  >
                    <option value="">Válasszon...</option>
                    <option value="daily">Naponta</option>
                    <option value="weekly">Hetente</option>
                    <option value="biweekly">Kéthetente</option>
                    <option value="monthly">Havonta</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Van-e olyan időszak amikor biztosan nem elérhető?
                  </label>
                  <textarea
                    value={questionnaire.unavailablePeriods}
                    onChange={(e) => setQuestionnaire({
                      ...questionnaire,
                      unavailablePeriods: e.target.value
                    })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                    rows={2}
                    placeholder="Pl.: Szabadság, egyéb projektek időszakai"
                  />
                </div>
              </div>
            </div>

            {/* Kommunikáció */}
            <div>
              <h3 className="text-lg font-medium text-white mb-4">Kommunikáció</h3>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Elvárt válaszidő sürgős kérdések esetén
                  </label>
                  <select
                    value={questionnaire.urgentResponseTime}
                    onChange={(e) => setQuestionnaire({
                      ...questionnaire,
                      urgentResponseTime: e.target.value
                    })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                  >
                    <option value="">Válasszon...</option>
                    <option value="1_hour">1 órán belül</option>
                    <option value="2_hours">2 órán belül</option>
                    <option value="4_hours">4 órán belül</option>
                    <option value="same_day">Aznap</option>
                    <option value="next_day">Következő munkanapon</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Milyen gyakran szeretne projekt státusz updateket?
                  </label>
                  <select
                    value={questionnaire.updateFrequency}
                    onChange={(e) => setQuestionnaire({
                      ...questionnaire,
                      updateFrequency: e.target.value
                    })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                  >
                    <option value="">Válasszon...</option>
                    <option value="daily">Naponta</option>
                    <option value="weekly">Hetente</option>
                    <option value="biweekly">Kéthetente</option>
                    <option value="monthly">Havonta</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Preferált kommunikációs csatorna
                  </label>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm text-gray-400 mb-2">
                        Sürgős esetben:
                      </label>
                      <select
                        value={questionnaire.communicationChannel?.urgent || ''}
                        onChange={(e) => setQuestionnaire({
                          ...questionnaire,
                          communicationChannel: {
                            ...questionnaire.communicationChannel,
                            urgent: e.target.value as 'email' | 'phone' | 'both'
                          }
                        })}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                      >
                        <option value="">Válasszon...</option>
                        <option value="email">Email</option>
                        <option value="phone">Telefon</option>
                        <option value="both">Mindkettő</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm text-gray-400 mb-2">
                        Általános esetben:
                      </label>
                      <select
                        value={questionnaire.communicationChannel?.normal || ''}
                        onChange={(e) => setQuestionnaire({
                          ...questionnaire,
                          communicationChannel: {
                            ...questionnaire.communicationChannel,
                            normal: e.target.value as 'email' | 'phone' | 'both'
                          }
                        })}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
                      >
                        <option value="">Válasszon...</option>
                        <option value="email">Email</option>
                        <option value="phone">Telefon</option>
                        <option value="both">Mindkettő</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fájlok */}
          <div className="space-y-6">
            <h2 className="text-lg font-medium text-white mb-2">
              Csatolmányok
              <span className="ml-2 text-gray-400 text-xs font-normal">
                (max. 10MB / fájl)
              </span>
            </h2>
            
            <div className="mt-2 p-4 border-2 border-dashed border-gray-600 rounded-lg">
              <div className="space-y-4">
                {/* Feltöltött fájlok listája */}
                {attachments.length > 0 && (
                  <div className="space-y-2">
                    {attachments.map((file, index) => (
                      <div key={index} className="flex items-center justify-between p-3 bg-gray-700/50 rounded-lg">
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center space-x-2">
                            <FaFile className="text-[#20A64B]" />
                            <span className="text-sm text-white">{file.name}</span>
                            {file.size > 0 && (
                              <span className="text-xs text-gray-400">
                                ({(file.size / 1024 / 1024).toFixed(2)} MB)
                              </span>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="text-sm text-gray-400">
                              {documentTypeOptions.find(opt => opt.value === file.documentType)?.label || 'Egyéb'}
                            </span>
                            <button
                              type="button"
                              onClick={() => handleEditDocumentType(index, file)}
                              className="p-1 text-gray-400 hover:text-[#20A64B] transition-colors"
                            >
                              <FaEdit />
                            </button>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setAttachments(prev => prev.filter((_, i) => i !== index));
                          }}
                          className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* Fájl feltöltés */}
                <div className="text-center">
                  <label className="relative cursor-pointer block">
                    <input
                      type="file"
                      className="hidden"
                      multiple
                      onChange={handleFileUpload}
                    />
                    <div className="flex flex-col items-center space-y-2 p-6 bg-gray-700/50 rounded-lg hover:bg-gray-600/50 transition-colors">
                      {uploading ? (
                        <div className="animate-pulse text-gray-300">Feltöltés folyamatban...</div>
                      ) : (
                        <>
                          <FaCloudUploadAlt className="text-3xl text-[#20A64B]" />
                          <span className="text-sm text-white">
                            Kattintson vagy húzza ide a fájlokat
                          </span>
                          <span className="text-xs text-gray-400">
                            PDF, Word, Excel, képek vagy ZIP fájlok (max. 10MB)
                          </span>
                        </>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Mentés gomb */}
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-6 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Mégse
            </button>
            <button
              type="submit"
              disabled={saving}
              className="px-6 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors disabled:opacity-50"
            >
              {saving ? 'Mentés...' : 'Mentés'}
            </button>
          </div>
        </form>
      </div>

      {/* Document Type Edit Modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="relative bg-gray-800 rounded-lg p-6 max-w-md w-full">
            <Dialog.Title className="text-lg font-medium text-white mb-4">
              Dokumentum típus módosítása
            </Dialog.Title>

            <div className="space-y-4">
              {documentTypeOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleUpdateDocumentType(option.value)}
                  className={`w-full p-3 rounded-lg text-left transition-colors ${
                    editingFile?.attachment.documentType === option.value
                      ? 'bg-[#20A64B]/10 border border-[#20A64B] text-white'
                      : 'bg-gray-700 border border-gray-600 text-gray-300 hover:border-gray-500'
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>

            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => setIsEditModalOpen(false)}
                className="px-4 py-2 text-sm text-gray-400 hover:text-white transition-colors"
              >
                Mégse
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
} 