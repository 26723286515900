import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  Project, 
  ProjectCategory, 
  ProjectBudget, 
  ProjectTimeline, 
  ProjectRequirement, 
  ProjectDetails, 
  ProjectReference, 
  ProjectContact, 
  ProjectQuestionnaire,
  DocumentType,
  ProjectAttachment
} from '../../types/project';
import { db, storage } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { CATEGORIES } from '../../constants/projectCategories';
import { 
  FaArrowLeft, 
  FaInfoCircle, 
  FaRegClock, 
  FaRegMoneyBillAlt, 
  FaRegLightbulb,
  FaCloudUploadAlt,
  FaFile,
  FaTrash,
  FaExclamationTriangle,
  FaEdit,
  FaCheck
} from 'react-icons/fa';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { BasicInfoStep } from './steps/BasicInfoStep';
import { BudgetStep } from './steps/BudgetStep';
import { RequirementsStep } from './steps/RequirementsStep';
import { SummaryStep } from './steps/SummaryStep';
import { calculateProjectScore } from '../../utils/projectScoring';
import { ProjectScoreIndicator } from '../projects/components/ProjectScoreIndicator';
import { QuestionnaireStep } from './steps/QuestionnaireStep';
import { Dialog } from '@headlessui/react';


const STEPS = [
  {
    id: 'basics',
    title: 'Alapinformációk',
    description: 'Projekt címe, kategóriája és rövid leírása',
    fields: ['title', 'category', 'subcategory', 'description', 'scope']
  },
  {
    id: 'requirements',
    title: 'Követelmények',
    description: 'Részletes követelmények és elvárások',
    fields: ['requirements']
  },
  {
    id: 'timeline',
    title: 'Időzítés',
    description: 'Határidők és időbeli elvárások',
    fields: ['timeline']
  },
  {
    id: 'budget',
    title: 'Költségvetés',
    description: 'Pénzügyi keretek és fizetési feltételek',
    fields: ['budget']
  },
  {
    id: 'details',
    title: 'Technikai részletek',
    description: 'Platformok, technológiák és egyéb specifikációk',
    fields: ['details']
  },
  {
    id: 'references',
    title: 'Referenciák',
    description: 'Példák, inspirációk és hasonló projektek',
    fields: ['references']
  },
  {
    id: 'contact',
    title: 'Kapcsolattartás',
    description: 'Kommunikációs preferenciák és elérhetőség',
    fields: ['contact']
  },
  {
    id: 'questionnaire',
    title: 'Kérdőív',
    description: 'Projekt kérdőíve',
    fields: ['questionnaire']
  },
  {
    id: 'review',
    title: 'Áttekintés',
    description: 'Projekt adatainak ellenőrzése',
    fields: []
  }
];

const PROJECT_SIZES = [
  {
    value: 'small',
    label: 'Kis projekt',
    duration: '1-2 hónap',
    description: 'Egyszerűbb weboldalak, landing page-ek, kisebb módosítások',
    examples: [
      'Céges bemutatkozó oldal',
      'Landing page készítés',
      'Meglévő weboldal frissítése',
      'Egyszerű WordPress oldal'
    ],
    icon: FaRegLightbulb
  },
  {
    value: 'medium',
    label: 'Közepes projekt',
    duration: '2-4 hónap',
    description: 'Összetettebb weboldalak, webshopok, egyedi funkciók',
    examples: [
      'Webáruház készítés',
      'Foglalási rendszer',
      'Komplex WordPress oldal',
      'Mobilalkalmazás'
    ],
    icon: FaRegClock
  },
  {
    value: 'large',
    label: 'Nagy projekt',
    duration: '4+ hónap',
    description: 'Komplex rendszerek, egyedi fejlesztések',
    examples: [
      'Vállalatirányítási rendszer',
      'Egyedi szoftverrendszer',
      'Többnyelvű webáruház',
      'Komplex mobilalkalmazás'
    ],
    icon: FaRegMoneyBillAlt
  }
];

const STORAGE_KEY = 'webtender_project_draft';

export default function CreateProject() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  
  // Alap információk
  const [basicInfo, setBasicInfo] = useState({
    title: '',
    category: 'web_development' as ProjectCategory,
    subcategory: '',
    description: '',
    scope: 'medium' as 'small' | 'medium' | 'large',
    isGreenfield: true
  });

  // Követelmények
  const [requirements, setRequirements] = useState<ProjectRequirement[]>([{
    description: '',
    priority: 'must',
    category: 'functional'
  }]);

  // Időzítés
  const [timeline, setTimeline] = useState<ProjectTimeline>({
    expectedStartDate: undefined,
    deadline: undefined,
    isFlexible: true,
    urgency: 'medium'
  });

  // Költségvetés
  const [budget, setBudget] = useState<ProjectBudget>({
    min: 0,
    max: 0,
    currency: 'HUF',
    isFlexible: true,
    advancePayment: {
      enabled: false,
      percentage: 10
    },
    insurance: 'none'
  });

  // Technikai részletek
  const [details, setDetails] = useState<ProjectDetails>({
    targetAudience: [],
    platforms: [],
    browserSupport: [],
    responsiveDesign: true,
    seoRequirements: false,
    accessibility: false,
    analytics: false,
    maintenance: false,
    training: false
  });

  // Referenciák
  const [references, setReferences] = useState<ProjectReference[]>([]);

  // Kapcsolattartás
  const [contact, setContact] = useState<ProjectContact>({
    preferredContact: 'both',
    preferredLanguages: ['magyar'],
    availableHours: undefined
  });

  // Fájlfeltöltés
  const [attachments, setAttachments] = useState<ProjectAttachment[]>([]);
  const [uploading, setUploading] = useState(false);

  // Adjuk hozzá az új state-et a validációs hibákhoz
  const [validationErrors, setValidationErrors] = useState<{
    title?: string;
    category?: string;
    subcategory?: string;
    scope?: string;
    description?: string;
    budget?: string;
    timeline?: string;
    requirements?: string;
  }>({});

  // Kérdőív
  const [questionnaire, setQuestionnaire] = useState<ProjectQuestionnaire>({
    // Pénzügyi kérdések
    extraCostHandling: 'A projekt során felmerülő többletköltségeket egyeztetés után tudjuk kezelni.',
    decisionTime: '1_week',
    budgetFlexibility: 10,
    
    // Időbeli rugalmasság
    urgentDecisionTime: '24_hours',
    meetingAvailability: 'weekly',
    unavailablePeriods: 'Nincs előre ismert időszak.',
    
    // Kommunikáció
    urgentResponseTime: '4_hours',
    updateFrequency: 'weekly',
    communicationChannel: {
      urgent: 'both',
      normal: 'email'
    }
  });

  // Számoljuk ki a projekt pontszámát
  const projectScore = calculateProjectScore({
    description: basicInfo.description,
    budget,
    timeline,
    attachments,
    references,
    details,
    questionnaire
  });

  // Validáció
  const validateStep = (step: number): boolean => {
    switch (step) {
      case 1: // Alapinformációk
        return (
          basicInfo.title.length >= 5 &&
          basicInfo.description.length >= 20
        );
      case 2: // Követelmények
        return requirements.some(req => 
          req.description.length >= 10
        );
      case 3: // Időzítés és követelmények
        return (
          timeline.expectedStartDate !== undefined &&
          timeline.deadline !== undefined &&
          timeline.expectedStartDate < timeline.deadline &&
          // Ha van követelmény, akkor ellenőrizzük, hogy ki van-e töltve
          (requirements.length === 0 || requirements.every(req => req.description.trim().length > 0))
        );
      case 4: // Áttekintés
        return true;
      default:
        return true;
    }
  };

  // Lépések közötti navigáció
  const handleNext = () => {
    // Validáljuk az aktuális lépést
    const errors: typeof validationErrors = {};
    
    if (currentStep === 1) {
      if (!basicInfo.title?.trim()) {
        errors.title = 'A projekt címe kötelező';
      } else if (basicInfo.title.length < 5) {
        errors.title = 'A címnek legalább 5 karakter hosszúnak kell lennie';
      }
      
      if (!basicInfo.category) {
        errors.category = 'Válasszon kategóriát';
      }
      
      if (basicInfo.category && !basicInfo.subcategory) {
        errors.subcategory = 'Válasszon alkategóriát';
      }
      
      if (!basicInfo.scope) {
        errors.scope = 'Válassza ki a projekt méretét';
      }
      
      if (!basicInfo.description?.trim()) {
        errors.description = 'A projekt leírása kötelező';
      } else if (basicInfo.description.length < 20) {
        errors.description = 'A leírásnak legalább 20 karakter hosszúnak kell lennie';
      }
    } else if (currentStep === 3) {
      if (!timeline.expectedStartDate || !timeline.deadline) {
        errors.timeline = 'A kezdési dátum és határidő megadása kötelező';
      } else if (timeline.expectedStartDate > timeline.deadline) {
        errors.timeline = 'A kezdési dátum nem lehet későbbi, mint a határidő';
      }

      // Ha van követelmény, akkor ellenőrizzük, hogy ki van-e töltve
      if (requirements.length > 0 && requirements.some(req => !req.description.trim())) {
        errors.requirements = 'Ha ad meg követelményt, akkor mindegyiknek legyen leírása';
      }
    }

    // Ha van hiba, ne lépjünk tovább
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      // Scrollozzunk az első hibához
      const firstErrorElement = document.querySelector('[data-error]');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    // Ha nincs hiba, töröljük a validációs hibákat és lépjünk tovább
    setValidationErrors({});
    setCurrentStep(prev => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  // Projekt létrehozása
  const handleSubmit = async () => {
    setLoading(true);
    
    try {
      const projectData = {
        ...basicInfo,
        clientId: user?.uid,
        clientName: user?.displayName || '',
        requirements,
        timeline,
        budget,
        details,
        references,
        contact,
        attachments: attachments.map(a => ({
          name: a.name,
          url: a.url,
          type: a.type,
          size: a.size,
          documentType: a.documentType || 'other'
        })),
        status: 'draft' as const,
        proposalIds: [],
        createdAt: new Date(),
        updatedAt: new Date(),
        questionnaire
      };

      // Ellenőrizzük, hogy minden attachment rendelkezik-e URL-lel
      const hasInvalidAttachments = projectData.attachments.some(a => !a.url);
      if (hasInvalidAttachments) {
        toast.error('Hiba: Néhány fájl feltöltése nem sikerült. Kérjük, próbálja újra.');
        return;
      }

      const docRef = await addDoc(collection(db, 'projects'), projectData);
      toast.success('Projekt sikeresen létrehozva!');
      navigate(`/client/projects/${docRef.id}`);
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error('Hiba történt a projekt létrehozása során');
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    'Alapinformációk',
    'Költségvetés',
    'Követelmények és határidők',
    'Kérdőív',
    'Áttekintés'
  ];

  const [editingFile, setEditingFile] = useState<{index: number, file: ProjectAttachment} | null>(null);

  const documentTypeOptions: Array<{value: DocumentType, label: string}> = [
    { value: 'project_description', label: 'Projekt leírás' },
    { value: 'technical_spec', label: 'Műszaki specifikáció' },
    { value: 'design_doc', label: 'Tervrajz, dizájn' },
    { value: 'reference', label: 'Referencia anyag' },
    { value: 'legal', label: 'Jogi dokumentum' },
    { value: 'other', label: 'Egyéb' }
  ];

  // Restore data from localStorage
  const handleDataRestore = (data: any) => {
    if (data.basicInfo) setBasicInfo(data.basicInfo);
    if (data.requirements) setRequirements(data.requirements);
    if (data.timeline) {
      setTimeline({
        ...data.timeline,
        expectedStartDate: data.timeline.expectedStartDate ? new Date(data.timeline.expectedStartDate) : undefined,
        deadline: data.timeline.deadline ? new Date(data.timeline.deadline) : undefined
      });
    }
    if (data.budget) setBudget(data.budget);
    if (data.details) setDetails(data.details);
    if (data.references) setReferences(data.references);
    if (data.contact) setContact(data.contact);
    if (data.attachments) setAttachments(data.attachments);
    if (data.questionnaire) setQuestionnaire(data.questionnaire);
  };

  // Try to restore data on component mount
  useEffect(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        handleDataRestore(parsedData);
      } catch (error) {
        console.error('Error restoring project data:', error);
      }
    }
  }, []);

  // Save data to localStorage when it changes
  useEffect(() => {
    const projectData = {
      basicInfo,
      requirements,
      timeline,
      budget,
      details,
      references,
      contact,
      attachments,
      questionnaire
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(projectData));
  }, [basicInfo, requirements, timeline, budget, details, references, contact, attachments, questionnaire]);

  return (
    <div className="max-w-[1920px] mx-auto px-4 py-8">
      {/* Pontszám indikátor */}
      <div className="mb-8">
        <ProjectScoreIndicator 
          description={basicInfo.description}
          category={basicInfo.category}
          subcategory={basicInfo.subcategory}
          budget={budget}
          timeline={timeline}
          requirements={requirements}
          questionnaire={questionnaire}
          attachments={attachments}
          references={references}
        />
        
        {projectScore.total < 40 && (
          <div className="mt-4 p-4 bg-gray-700 rounded-lg border border-red-500/20">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <FaExclamationTriangle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-400">
                  A projekt még nem publikálható
                </h3>
                <div className="mt-2 text-sm text-gray-300">
                  <p>
                    A projekt publikálásához minimum 40 pont szükséges. 
                    Nézze meg a "Még szerezhető pontok" szekciót a hiányzó információkért.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Form tartalom */}
      <div className="bg-gray-800 rounded-lg p-6">
        <h1 className="text-2xl font-bold text-white mb-8">Új projekt létrehozása</h1>

        {/* Progress bar */}
        <div className="mb-8">
          <div className="flex items-center space-x-4">
            {steps.map((step, index) => (
              <div
                key={step}
                className={`flex items-center ${
                  index < steps.length - 1 ? 'flex-1' : ''
                }`}
              >
                <div className="flex flex-col items-center relative">
                  <div
                    className={`w-8 h-8 rounded-full border-2 flex items-center justify-center ${
                      currentStep > index + 1
                        ? 'bg-[#20A64B] border-[#20A64B] text-white'
                        : currentStep === index + 1
                        ? 'border-[#20A64B] text-[#20A64B]'
                        : 'border-gray-600 text-gray-600'
                    }`}
                  >
                    {currentStep > index + 1 ? (
                      <FaCheck className="w-4 h-4" />
                    ) : (
                      index + 1
                    )}
                  </div>
                  <span className={`text-xs mt-2 whitespace-nowrap ${
                    currentStep === index + 1
                      ? 'text-[#20A64B]'
                      : currentStep > index + 1
                      ? 'text-gray-300'
                      : 'text-gray-500'
                  }`}>
                    {step}
                  </span>
                </div>
                {index < steps.length - 1 && (
                  <div className="flex-1 mx-4">
                    <div className="h-0.5 relative">
                      <div
                        className="absolute inset-0 bg-gray-600"
                      />
                      <div
                        className={`absolute inset-0 bg-[#20A64B] transition-all duration-300 ${
                          currentStep > index + 1
                            ? 'w-full'
                            : currentStep === index + 1
                            ? 'w-1/2'
                            : 'w-0'
                        }`}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className="bg-gray-700 rounded-lg p-6">
            {/* Form steps */}
            {currentStep === 1 && (
              <BasicInfoStep 
                basicInfo={basicInfo} 
                setBasicInfo={setBasicInfo}
                validationErrors={validationErrors}
                attachments={attachments}
                setAttachments={setAttachments}
              />
            )}

            {currentStep === 2 && (
              <BudgetStep 
                budget={budget}
                setBudget={setBudget}
                validationErrors={validationErrors}
              />
            )}

            {currentStep === 3 && (
              <RequirementsStep 
                requirements={requirements}
                setRequirements={setRequirements}
                timeline={timeline}
                setTimeline={setTimeline}
                validationErrors={validationErrors}
              />
            )}

            {currentStep === 4 && (
              <QuestionnaireStep 
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                validationErrors={validationErrors}
              />
            )}

            {currentStep === 5 && (
              <SummaryStep 
                basicInfo={basicInfo}
                budget={budget}
                requirements={requirements}
                timeline={timeline}
                contact={contact}
                references={references}
                attachments={attachments}
                questionnaire={questionnaire}
                onDataRestore={handleDataRestore}
              />
            )}
          </div>

          {/* Navigation buttons */}
          <div className="flex justify-between pt-6">
            {currentStep > 1 && (
              <button
                type="button"
                onClick={handleBack}
                className="px-6 py-2 text-gray-300 hover:text-[#20A64B] transition-colors"
              >
                Vissza
              </button>
            )}
            {currentStep < steps.length ? (
              <button
                type="button"
                onClick={handleNext}
                className="ml-auto px-6 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors"
              >
                Tovább
              </button>
            ) : (
              <button
                type="button"
                onClick={handleSubmit}
                disabled={loading}
                className="ml-auto px-6 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors disabled:opacity-50"
              >
                {loading ? 'Létrehozás...' : 'Projekt létrehozása'}
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Dokumentum típus választó modal */}
      <Dialog 
        open={editingFile !== null} 
        onClose={() => setEditingFile(null)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-gray-800 p-6">
            <Dialog.Title className="text-lg font-medium text-white mb-4">
              Dokumentum típus módosítása
            </Dialog.Title>

            <div className="space-y-4">
              {documentTypeOptions.map((option) => (
                <button
                  key={option.value}
                  className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                    editingFile?.file.documentType === option.value
                      ? 'bg-[#20A64B] text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                  onClick={() => {
                    if (editingFile) {
                      setAttachments(prev => prev.map((a, i) => 
                        i === editingFile.index 
                          ? { ...a, documentType: option.value as DocumentType } 
                          : a
                      ));
                      setEditingFile(null);
                    }
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>

            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => setEditingFile(null)}
                className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
              >
                Mégse
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
