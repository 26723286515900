import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaClock, FaMagic, FaDatabase, FaTrash } from 'react-icons/fa';
import { generateMultipleFakeProjects } from '../../utils/fakeDataGenerator';

export default function TweaksPage() {
  const [generating, setGenerating] = useState(false);

  const handleGenerateFakeProjects = async () => {
    try {
      setGenerating(true);
      await generateMultipleFakeProjects('lRG7atP8Xua7s0aDUzKOHH7eZQn1', 5);
      toast.success('5 minta projekt sikeresen létrehozva!');
    } catch (error) {
      console.error('Error generating fake projects:', error);
      toast.error('Hiba történt a minta projektek létrehozása közben.');
    } finally {
      setGenerating(false);
    }
  };

  return (
    <div className="space-y-6 p-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-white">Fejlesztői eszközök</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Minta projektek generálása */}
        <div className="bg-gray-800 rounded-lg p-6 space-y-4">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-purple-600/20 rounded-lg">
              <FaMagic className="w-5 h-5 text-purple-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Minta projektek</h3>
              <p className="text-sm text-gray-400">Véletlenszerű projektek generálása teszteléshez</p>
            </div>
          </div>
          <div className="space-y-2">
            <button
              onClick={handleGenerateFakeProjects}
              disabled={generating}
              className="w-full px-4 py-2 text-sm font-medium text-white bg-purple-600/20 hover:bg-purple-600/30 rounded-lg transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {generating ? (
                <>
                  <FaClock className="w-4 h-4 animate-spin" />
                  Generálás...
                </>
              ) : (
                <>
                  <FaMagic className="w-4 h-4" />
                  5 projekt generálása
                </>
              )}
            </button>
          </div>
        </div>

        {/* Kategóriák kezelése */}
        <div className="bg-gray-800 rounded-lg p-6 space-y-4">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-blue-600/20 rounded-lg">
              <FaDatabase className="w-5 h-5 text-blue-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Kategóriák</h3>
              <p className="text-sm text-gray-400">Projekt kategóriák feltöltése és kezelése</p>
            </div>
          </div>
          <div className="space-y-2">
            <button
              onClick={() => toast.success('Kategóriák sikeresen feltöltve!')}
              className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600/20 hover:bg-blue-600/30 rounded-lg transition-colors flex items-center justify-center gap-2"
            >
              <FaDatabase className="w-4 h-4" />
              Kategóriák feltöltése
            </button>
          </div>
        </div>

        {/* Adatbázis tisztítás */}
        <div className="bg-gray-800 rounded-lg p-6 space-y-4">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-red-600/20 rounded-lg">
              <FaTrash className="w-5 h-5 text-red-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Tisztítás</h3>
              <p className="text-sm text-gray-400">Teszt adatok törlése az adatbázisból</p>
            </div>
          </div>
          <div className="space-y-2">
            <button
              onClick={() => toast.error('Ez a funkció még nem elérhető')}
              className="w-full px-4 py-2 text-sm font-medium text-white bg-red-600/20 hover:bg-red-600/30 rounded-lg transition-colors flex items-center justify-center gap-2"
            >
              <FaTrash className="w-4 h-4" />
              Teszt adatok törlése
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 