import React from 'react';
import { FaShieldAlt } from 'react-icons/fa';

export default function Privacy() {
  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto py-6">
        <div className="bg-gray-800 rounded-lg">
          {/* Header */}
          <div className="p-6 border-b border-gray-700">
            <h1 className="text-2xl font-bold text-white flex items-center gap-2">
              <FaShieldAlt className="w-6 h-6 text-[#20A64B]" />
              Adatvédelmi irányelvek
            </h1>
            <p className="text-gray-400 mt-1">
              A WebTender platform adatkezelési és adatvédelmi szabályai
            </p>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="space-y-6 text-gray-300">
              <section>
                <h2 className="text-xl font-medium text-white mb-4">1. Bevezetés</h2>
                <div className="space-y-3">
                  <p>
                    A WebTender platform elkötelezett a felhasználók személyes adatainak védelme mellett.
                    Jelen adatvédelmi irányelvek részletesen bemutatják, hogyan gyűjtjük, használjuk és védjük az Ön adatait.
                  </p>
                  <p>
                    Az irányelvek összhangban vannak az EU Általános Adatvédelmi Rendeletével (GDPR) és 
                    a hatályos magyar jogszabályokkal.
                  </p>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">2. Gyűjtött adatok köre</h2>
                <div className="space-y-3">
                  <p>
                    A Platform működéséhez és szolgáltatásaink nyújtásához különböző típusú adatokat gyűjtünk.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4 space-y-2">
                    <h3 className="text-white font-medium">Személyes adatok:</h3>
                    <ul className="list-disc list-inside text-gray-300">
                      <li>Név és elérhetőségi adatok</li>
                      <li>Számlázási és pénzügyi információk</li>
                      <li>Cégadatok (kivitelezők esetén)</li>
                      <li>Szakmai referenciák és minősítések</li>
                    </ul>
                  </div>
                  <div className="bg-gray-700/50 rounded-lg p-4 space-y-2">
                    <h3 className="text-white font-medium">Automatikusan gyűjtött adatok:</h3>
                    <ul className="list-disc list-inside text-gray-300">
                      <li>IP cím és böngészési adatok</li>
                      <li>Platform használati statisztikák</li>
                      <li>Eszközinformációk</li>
                      <li>Cookie-k és hasonló technológiák által gyűjtött adatok</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">3. Adatkezelés célja</h2>
                <div className="space-y-3">
                  <p>
                    Az adatkezelés elsődleges célja szolgáltatásaink biztosítása és a Platform megfelelő működtetése.
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Felhasználói fiókok kezelése és azonosítás</li>
                    <li>Projektek és ajánlatok kezelése</li>
                    <li>Kommunikáció biztosítása a felhasználók között</li>
                    <li>Minőségbiztosítási rendszer működtetése</li>
                    <li>Számlázás és pénzügyi tranzakciók kezelése</li>
                    <li>Jogszabályi kötelezettségek teljesítése</li>
                  </ul>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">4. Adatbiztonság</h2>
                <div className="space-y-3">
                  <p>
                    Kiemelt figyelmet fordítunk az Ön adatainak biztonságára. Technikai és szervezési
                    intézkedésekkel biztosítjuk az adatok védelmét.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>SSL/TLS titkosítás a kommunikáció során</li>
                      <li>Rendszeres biztonsági mentések</li>
                      <li>Hozzáférés-szabályozás és naplózás</li>
                      <li>Adatvédelmi incidensek kezelése</li>
                      <li>Rendszeres biztonsági auditok</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">5. Adattovábbítás és feldolgozás</h2>
                <div className="space-y-3">
                  <p>
                    Adatait kizárólag a szükséges mértékben és megfelelő garanciák mellett továbbítjuk.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Adatfeldolgozók igénybevétele (pl. tárhelyszolgáltató)</li>
                      <li>Hatósági megkeresések teljesítése</li>
                      <li>Statisztikai célú adatfeldolgozás</li>
                      <li>EU-n kívüli adattovábbítás esetén megfelelő garanciák biztosítása</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">6. Az Ön jogai</h2>
                <div className="space-y-3">
                  <p>
                    A GDPR és a vonatkozó jogszabályok alapján Önt különböző jogok illetik meg:
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Hozzáférés a személyes adatokhoz</li>
                    <li>Helyesbítés és törlés kérése</li>
                    <li>Adatkezelés korlátozása</li>
                    <li>Adathordozhatóság</li>
                    <li>Tiltakozás az adatkezelés ellen</li>
                  </ul>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">7. Kapcsolat</h2>
                <div className="space-y-3">
                  <p>
                    Adatvédelemmel kapcsolatos kérdéseivel forduljon adatvédelmi tisztviselőnkhöz:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-none text-gray-300 space-y-2">
                      <li>E-mail: privacy@webtender.hu</li>
                      <li>Telefon: +36 1 234 5678</li>
                      <li>Cím: 1234 Budapest, Példa utca 1.</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">8. Az irányelvek módosítása</h2>
                <div className="space-y-3">
                  <p>
                    Fenntartjuk a jogot az adatvédelmi irányelvek módosítására. 
                    A változásokról a felhasználókat előzetesen értesítjük.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4 text-sm">
                    <p className="text-gray-300">
                      Utolsó módosítás: 2025. február 7.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 