import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Profile, Reference } from '../../types';
import { SERVICES } from '../../constants/profileServices';
import { 
  FaUserCheck, 
  FaStar, 
  FaPhone, 
  FaEnvelope, 
  FaGlobe,
  FaMapMarkerAlt,
  FaBuilding,
  FaIdCard,
  FaLock
} from 'react-icons/fa';
import ContactForm from '../../components/contractors/ContactForm';

interface ContactInfoProps {
  contractor: Profile;
  isContractor: boolean;
}

// Kontakt információk komponens
const ContactInfo = ({ contractor, isContractor = false }: ContactInfoProps) => {
  if (!isContractor) { // Ha megrendelő nézi, vagy nem bejelentkezett felhasználó
    return (
      <>
        {contractor.contactEmail && (
          <div className="flex items-center gap-2 text-gray-300">
            <FaEnvelope className="w-4 h-4 text-gray-400" />
            <a href={`mailto:${contractor.contactEmail}`} className="hover:text-[#20A64B]">
              {contractor.contactEmail}
            </a>
          </div>
        )}

        {contractor.phone && (
          <div className="flex items-center gap-2 text-gray-300">
            <FaPhone className="w-4 h-4 text-gray-400" />
            <a href={`tel:${contractor.phone}`} className="hover:text-[#20A64B]">
              {contractor.phone}
            </a>
          </div>
        )}

        {contractor.company?.website && (
          <div className="flex items-center gap-2 text-gray-300">
            <FaGlobe className="w-4 h-4 text-gray-400" />
            <a 
              href={contractor.company.website} 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:text-[#20A64B]"
            >
              {contractor.company.website}
            </a>
          </div>
        )}
      </>
    );
  }

  // Ha kivitelező nézi és nincs előfizetése
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 text-gray-400">
        <FaEnvelope className="w-4 h-4" />
        <span className="flex items-center gap-2">
          <FaLock className="w-3 h-3" />
          Kivitelezőként csak előfizetéssel látható
        </span>
      </div>
      <div className="flex items-center gap-2 text-gray-400">
        <FaPhone className="w-4 h-4" />
        <span className="flex items-center gap-2">
          <FaLock className="w-3 h-3" />
          Kivitelezőként csak előfizetéssel látható
        </span>
      </div>
      <div className="flex items-center gap-2 text-gray-400">
        <FaGlobe className="w-4 h-4" />
        <span className="flex items-center gap-2">
          <FaLock className="w-3 h-3" />
          Kivitelezőként csak előfizetéssel látható
        </span>
      </div>
    </div>
  );
};

export default function ContractorProfile() {
  const { id } = useParams();
  const { user } = useAuth();
  const [contractor, setContractor] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [showContactForm, setShowContactForm] = useState(false);

  // Ellenőrizzük, hogy a bejelentkezett felhasználó kivitelező-e
  const isContractor = user?.activeProfile?.type === 'contractor';

  useEffect(() => {
    const fetchContractor = async () => {
      if (!id) return;

      try {
        const contractorDoc = await getDoc(doc(db, 'profiles', id));
        if (contractorDoc.exists()) {
          setContractor({ 
            id: contractorDoc.id, 
            ...contractorDoc.data() 
          } as Profile);
        }
      } catch (error) {
        console.error('Error fetching contractor:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContractor();
  }, [id]);

  if (loading) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B]"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!contractor) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="text-center py-12">
              <p className="text-gray-400">A kivitelező nem található</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Bal oldali profil információk */}
          <div className="lg:col-span-1 space-y-6">
            {/* Alap információk */}
            <div className="bg-gray-800 rounded-lg p-6">
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h1 className="text-2xl font-bold text-white">
                    {contractor.company?.name}
                  </h1>
                  <p className="text-gray-400">
                    {contractor.userType === 'company' ? 'Cég' : 'Egyéni vállalkozó'}
                  </p>
                </div>
                {contractor.verified && (
                  <div className="flex items-center gap-2 text-[#20A64B]">
                    <FaUserCheck className="w-5 h-5" />
                    <span className="text-sm">Ellenőrzött</span>
                  </div>
                )}
              </div>

              <div className="space-y-4">
                {contractor.company && (
                  <>
                    <div className="flex items-center gap-2 text-gray-300">
                      <FaBuilding className="w-4 h-4 text-gray-400" />
                      <span>Cégnév: {contractor.company.name}</span>
                    </div>
                    {contractor.company.taxNumber && (
                      <div className="flex items-center gap-2 text-gray-300">
                        <FaIdCard className="w-4 h-4 text-gray-400" />
                        <span>Adószám: {contractor.company.taxNumber}</span>
                      </div>
                    )}
                  </>
                )}
                
                {/* Kontakt információk komponens használata */}
                <ContactInfo contractor={contractor} isContractor={isContractor} />
              </div>
            </div>

            {/* Szolgáltatások */}
            {contractor.services && contractor.services.length > 0 && (
              <div className="bg-gray-800 rounded-lg p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Szolgáltatások</h2>
                <div className="flex flex-wrap gap-2">
                  {contractor.services.map(service => (
                    <span
                      key={service}
                      className="px-3 py-1 bg-gray-700 rounded-full text-sm text-gray-300"
                    >
                      {SERVICES.find(s => s.id === service)?.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Jobb oldali tartalom */}
          <div className="lg:col-span-2 space-y-6">
            {/* Bemutatkozás */}
            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-lg font-semibold text-white mb-4">Bemutatkozás</h2>
              <p className="text-gray-300 whitespace-pre-wrap">
                {contractor.description || 'Nincs bemutatkozás megadva.'}
              </p>
            </div>

            {/* Portfólió */}
            {contractor.portfolio && contractor.portfolio.length > 0 && (
              <div className="bg-gray-800 rounded-lg p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Portfólió</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {contractor.portfolio.map((item, index) => (
                    <div key={index} className="aspect-video rounded-lg overflow-hidden">
                      <img 
                        src={item} 
                        alt={`Portfólió ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Referenciák */}
            {contractor.references && contractor.references.length > 0 && (
              <div className="bg-gray-800 rounded-lg p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Referenciák</h2>
                <div className="space-y-6">
                  {contractor.references.map((reference, index) => (
                    <div key={index} className="p-4 bg-gray-700/50 rounded-lg">
                      <div className="flex items-start justify-between mb-4">
                        <div>
                          <h3 className="text-lg font-medium text-white">
                            {reference.projectName}
                          </h3>
                          <p className="text-gray-400">{reference.clientName}</p>
                        </div>
                        <div className="flex items-center gap-1">
                          {[...Array(5)].map((_, i) => (
                            <FaStar
                              key={i}
                              className={`w-4 h-4 ${
                                i < reference.rating
                                  ? 'text-yellow-400'
                                  : 'text-gray-600'
                              }`}
                            />
                          ))}
                        </div>
                      </div>

                      <p className="text-gray-300 mb-4">{reference.description}</p>

                      {/* Képek megjelenítése */}
                      {reference.images && reference.images.length > 0 && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
                          {reference.images.map((image, imageIndex) => (
                            <div key={imageIndex} className="aspect-video rounded-lg overflow-hidden">
                              <img
                                src={image}
                                alt={`${reference.projectName} - ${imageIndex + 1}`}
                                className="w-full h-full object-cover hover:scale-105 transition-transform cursor-pointer"
                                onClick={() => window.open(image, '_blank')}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      {reference.date && (
                        <p className="text-sm text-gray-400 mt-4">
                          {new Date(reference.date).toLocaleDateString('hu-HU', {
                            year: 'numeric',
                            month: 'long'
                          })}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Kapcsolatfelvétel gomb */}
            <div className="bg-gray-800 rounded-lg p-6">
              <button
                onClick={() => setShowContactForm(true)}
                className="w-full py-3 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors flex items-center justify-center gap-2"
              >
                <FaEnvelope className="w-4 h-4" />
                <span>Kapcsolatfelvétel</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Kapcsolatfelvételi űrlap modal */}
      {showContactForm && contractor && (
        <ContactForm
          contractor={contractor}
          onClose={() => setShowContactForm(false)}
        />
      )}
    </div>
  );
} 