import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import WebTenderLogo from '../assets/images/webtender_logo.svg';
import { motion } from 'framer-motion';
import { 
  FaUserCircle, FaBell, FaCog, FaSignOutAlt, FaUser, FaSearch, 
  FaFileAlt, FaList, FaPlus, FaInbox, FaUserTie, FaTasks, 
  FaBriefcase, FaChevronDown, FaFolder, FaComments 
} from 'react-icons/fa';
import ProfileSwitcher from './ProfileSwitcher';
import { useState } from 'react';

interface DropdownProps {
  label: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

function NavDropdown({ label, icon, children }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative group" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <button 
        className="flex items-center gap-2 text-gray-300 hover:text-white px-3 py-2 rounded-lg transition-colors"
      >
        {icon}
        <span>{label}</span>
        <FaChevronDown className={`w-3 h-3 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      
      {isOpen && (
        <div className="absolute left-0 mt-1 w-64 bg-gray-700 rounded-lg shadow-xl border border-gray-600 py-2 z-50">
          {children}
        </div>
      )}
    </div>
  );
}

function DropdownItem({ to, icon, children }: { to: string; icon: React.ReactNode; children: React.ReactNode }) {
  return (
    <Link
      to={to}
      className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:bg-gray-600 hover:text-white transition-colors"
    >
      {icon}
      <span>{children}</span>
    </Link>
  );
}

export default function Navbar() {
  const { user, signOut, activeProfile } = useAuth();

  return (
    <motion.nav
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gray-800 border-b border-gray-700 w-full"
    >
      <div className="w-full px-4">
        <div className="flex items-center justify-between h-16 max-w-[1920px] mx-auto">
          {/* Logo és beta jelzés */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center gap-2 hover:opacity-90 transition-opacity">
              <img 
                src={WebTenderLogo} 
                alt="WebTender" 
                className="h-8 w-auto"
              />
              <div className="flex items-center gap-2">
                <span className="px-2 py-0.5 text-xs font-medium bg-[#20A64B]/20 text-[#20A64B] rounded-full border border-[#20A64B]/20">
                  BETA
                </span>
                <div className="group relative">
                  <button className="text-gray-400 hover:text-white">
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </button>
                  <div className="absolute left-0 mt-2 w-72 px-4 py-3 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 text-xs text-gray-300 z-50">
                    Ez egy beta verzió. A rendszer tesztelés alatt áll, egyes funkciók korlátozottan érhetők el. Amennyiben észrevétele vagy javaslata van, kérjük írjon a{' '}
                    <span 
                      onClick={() => window.location.href = 'mailto:nroland89@gmail.com'} 
                      className="text-[#20A64B] hover:underline cursor-pointer"
                    >
                      nroland89@gmail.com
                    </span>{' '}
                    címre.
                  </div>
                </div>
              </div>
            </Link>

            {/* Főmenü */}
            {user && (
              <div className="hidden md:flex items-center ml-8 space-x-4">
                <Link
                  to="/"
                  className="text-gray-300 hover:text-white px-3 py-2 rounded-lg transition-colors"
                >
                  Vezérlőpult
                </Link>
                
                {activeProfile?.type === 'client' && (
                  <>
                    <NavDropdown label="Projektek" icon={<FaFolder className="w-4 h-4" />}>
                      <DropdownItem to="/client/projects" icon={<FaList className="w-4 h-4" />}>
                        Projektjeim
                      </DropdownItem>
                      <DropdownItem to="/client/projects/create" icon={<FaPlus className="w-4 h-4" />}>
                        Új projekt
                      </DropdownItem>
                    </NavDropdown>

                    <NavDropdown label="Ajánlatok" icon={<FaFileAlt className="w-4 h-4" />}>
                      <DropdownItem to="/client/proposals" icon={<FaInbox className="w-4 h-4" />}>
                        Beérkezett ajánlatok
                      </DropdownItem>
                      <DropdownItem to="/contractors" icon={<FaUserTie className="w-4 h-4" />}>
                        Kivitelezők keresése
                      </DropdownItem>
                    </NavDropdown>
                  </>
                )}

                {activeProfile?.type === 'contractor' && (
                  <>
                    <NavDropdown label="Projektek" icon={<FaFolder className="w-4 h-4" />}>
                      <DropdownItem to="/contractor/projects/browse" icon={<FaSearch className="w-4 h-4" />}>
                        Projektek böngészése
                      </DropdownItem>
                      <DropdownItem to="/contractor/projects/active" icon={<FaTasks className="w-4 h-4" />}>
                        Aktív projektjeim
                      </DropdownItem>
                    </NavDropdown>

                    <NavDropdown label="Ajánlatok és Referenciák" icon={<FaFileAlt className="w-4 h-4" />}>
                      <DropdownItem to="/contractor/proposals" icon={<FaFileAlt className="w-4 h-4" />}>
                        Ajánlataim
                      </DropdownItem>
                      <DropdownItem to="/profile" icon={<FaBriefcase className="w-4 h-4" />}>
                        Referenciák
                      </DropdownItem>
                    </NavDropdown>
                  </>
                )}
              </div>
            )}
          </div>

          {/* Jobb oldali elemek */}
          {user ? (
            <div className="flex items-center gap-6">
              {/* Értesítések */}
              <button className="text-gray-400 hover:text-white transition-colors relative">
                <FaBell className="w-5 h-5" />
                <span className="absolute -top-1 -right-1 bg-[#20A64B] text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                  2
                </span>
              </button>

              {/* Profil váltó */}
              <ProfileSwitcher />

              {/* Profil menü */}
              <div className="relative group">
                <Link 
                  to="/profile" 
                  className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
                >
                  <div className="flex items-center gap-2">
                    <FaUser />
                    <span>{user.name || user.email}</span>
                  </div>
                </Link>

                <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50 border border-gray-600">
                  <div className="py-1">
                    <Link
                      to="/profile"
                      className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:bg-gray-600 hover:text-white transition-colors"
                    >
                      <FaCog className="w-4 h-4" />
                      Profil beállítások
                    </Link>
                    {user.isAdmin && (
                      <Link
                        to="/admin"
                        className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:bg-gray-600 hover:text-white transition-colors"
                      >
                        <FaCog className="w-4 h-4" />
                        Admin felület
                      </Link>
                    )}
                    <button
                      onClick={signOut}
                      className="flex items-center gap-2 w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600 hover:text-white transition-colors"
                    >
                      <FaSignOutAlt className="w-4 h-4" />
                      Kijelentkezés
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <Link
                to="/login"
                className="bg-[#20A64B] hover:bg-[#178f3f] text-white px-4 py-2 rounded-lg transition-colors text-sm"
              >
                Bejelentkezés
              </Link>
            </div>
          )}
        </div>
      </div>
    </motion.nav>
  );
} 