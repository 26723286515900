import React from 'react';
import { FaFileAlt } from 'react-icons/fa';

export default function Terms() {
  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto py-6">
        <div className="bg-gray-800 rounded-lg">
          {/* Header */}
          <div className="p-6 border-b border-gray-700">
            <h1 className="text-2xl font-bold text-white flex items-center gap-2">
              <FaFileAlt className="w-6 h-6 text-[#20A64B]" />
              Felhasználási feltételek
            </h1>
            <p className="text-gray-400 mt-1">
              A WebTender platform használatára vonatkozó szabályok és feltételek
            </p>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="space-y-6 text-gray-300">
              <section>
                <h2 className="text-xl font-medium text-white mb-4">1. Általános rendelkezések</h2>
                <div className="space-y-3">
                  <p>
                    A WebTender platform (továbbiakban: Platform) használatával Ön elfogadja az alábbi feltételeket. 
                    Kérjük, figyelmesen olvassa el a dokumentumot a Platform használata előtt.
                  </p>
                  <p>
                    A Platform célja, hogy összekösse a minőségi kivitelezőket a megrendelőkkel, 
                    és biztosítsa a projektek sikeres megvalósítását.
                  </p>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">2. Regisztráció és felhasználói fiókok</h2>
                <div className="space-y-3">
                  <p>
                    A Platform használatához regisztráció szükséges. A regisztráció során megadott adatoknak 
                    valósnak és pontosnak kell lenniük.
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Minden felhasználó csak egy fiókot hozhat létre</li>
                    <li>A felhasználói fiók nem átruházható</li>
                    <li>A felhasználó felelős a fiókja biztonságáért</li>
                    <li>A valótlan adatok megadása a fiók felfüggesztéséhez vezethet</li>
                  </ul>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">3. Projektek és ajánlatok</h2>
                <div className="space-y-3">
                  <p>
                    A Platform lehetővé teszi projektek létrehozását és ajánlatok beküldését. 
                    Minden felhasználó felelős az általa közzétett tartalmakért.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4 space-y-2">
                    <h3 className="text-white font-medium">Megrendelők kötelezettségei:</h3>
                    <ul className="list-disc list-inside text-gray-300">
                      <li>Pontos és részletes projektleírás biztosítása</li>
                      <li>A projekt követelményeinek egyértelmű meghatározása</li>
                      <li>A kommunikációs csatornák és határidők tisztázása</li>
                      <li>A fizetési feltételek és ütemezés meghatározása</li>
                    </ul>
                  </div>
                  <div className="bg-gray-700/50 rounded-lg p-4 space-y-2">
                    <h3 className="text-white font-medium">Kivitelezők kötelezettségei:</h3>
                    <ul className="list-disc list-inside text-gray-300">
                      <li>Valós referenciák és képességek feltüntetése</li>
                      <li>Reális ajánlatok és határidők megadása</li>
                      <li>A vállalt kötelezettségek teljesítése</li>
                      <li>Professzionális kommunikáció a megrendelőkkel</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">4. Minőségbiztosítás és értékelések</h2>
                <div className="space-y-3">
                  <p>
                    A Platform minőségbiztosítási rendszere segít fenntartani a magas színvonalat 
                    és a felhasználók közötti bizalmat.
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Az értékeléseknek objektívnek és tényszerűnek kell lenniük</li>
                    <li>Tilos hamis vagy manipulált értékelések közzététele</li>
                    <li>A Platform fenntartja a jogot a nem megfelelő tartalmak eltávolítására</li>
                  </ul>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">5. Díjak és fizetési feltételek</h2>
                <div className="space-y-3">
                  <p>
                    A Platform használata különböző díjakhoz és jutalékokhoz kötött, 
                    amelyek részletei a mindenkori árazási oldalon találhatók.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>A megrendelők számára a projektek létrehozása ingyenes</li>
                      <li>A kivitelezők előfizetési díjat fizetnek a Platform használatáért</li>
                      <li>A sikeres projektek után jutalék fizetendő</li>
                      <li>A biztosítási díjak a projekt értékének százalékában kerülnek meghatározásra</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">6. Adatvédelem és biztonság</h2>
                <div className="space-y-3">
                  <p>
                    A Platform elkötelezett a felhasználók adatainak védelme mellett. 
                    Az adatkezelés részletei az Adatvédelmi irányelvekben találhatók.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>A személyes adatok biztonságos tárolása és kezelése</li>
                      <li>A kommunikáció titkosítása</li>
                      <li>Rendszeres biztonsági auditok és frissítések</li>
                      <li>Átlátható adatkezelési gyakorlatok</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">7. A feltételek módosítása</h2>
                <div className="space-y-3">
                  <p>
                    A Platform fenntartja a jogot a felhasználási feltételek módosítására. 
                    A változásokról a felhasználókat előzetesen értesítjük.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4 text-sm">
                    <p className="text-gray-300">
                      Utolsó módosítás: 2025. február 7.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 