import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { FaShieldAlt, FaExclamationTriangle } from 'react-icons/fa';
import { UserWithStats } from '../../../../types/user';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

interface UserPermissionsModalProps {
  user: UserWithStats | null;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export default function UserPermissionsModal({ 
  user, 
  isOpen, 
  onClose,
  onUpdate 
}: UserPermissionsModalProps) {
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin || false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Reset state when modal opens with new user
  React.useEffect(() => {
    if (user) {
      setIsAdmin(user.isAdmin || false);
      setError(null);
    }
  }, [user]);

  const handleSave = async () => {
    if (!user) return;

    try {
      setIsSaving(true);
      setError(null);

      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        isAdmin
      });

      onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating user permissions:', error);
      setError('Hiba történt a jogosultságok mentése közben');
    } finally {
      setIsSaving(false);
    }
  };

  if (!user) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-md w-full bg-gray-800 rounded-xl shadow-xl">
          <div className="p-6">
            <div className="flex items-start justify-between">
              <div>
                <Dialog.Title className="text-xl font-semibold text-white mb-1">
                  Jogosultságok kezelése
                </Dialog.Title>
                <p className="text-gray-400 text-sm">
                  {user.name}
                </p>
              </div>
              <div className="text-gray-400">
                <FaShieldAlt className="w-6 h-6" />
              </div>
            </div>

            <div className="mt-6">
              <div className="space-y-4">
                <div className="flex items-center justify-between p-4 bg-gray-700/50 rounded-lg">
                  <div>
                    <h4 className="text-white font-medium">Admin jogosultság</h4>
                    <p className="text-sm text-gray-400">
                      Az admin felhasználók hozzáférnek az admin felülethez és kezelhetik a felhasználókat
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                    />
                    <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[#20A64B] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#20A64B]"></div>
                  </label>
                </div>
              </div>

              {error && (
                <div className="mt-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg">
                  <div className="flex items-center gap-2 text-red-400">
                    <FaExclamationTriangle className="w-4 h-4" />
                    <p className="text-sm">{error}</p>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors"
                disabled={isSaving}
              >
                Mégsem
              </button>
              <button
                onClick={handleSave}
                disabled={isSaving}
                className="px-4 py-2 bg-[#20A64B] text-white rounded-lg hover:bg-[#1a8b3e] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSaving ? 'Mentés...' : 'Mentés'}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
} 