import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDCYcOZWBDZeTfXobYyiIEA_2vKM8ZblM0",
  authDomain: "webtender-98dcc.firebaseapp.com",
  projectId: "webtender-98dcc",
  storageBucket: "webtender-98dcc.firebasestorage.app",
  messagingSenderId: "697861325633",
  appId: "1:697861325633:web:030ba13e2fd71cd6d557c0",
  measurementId: "G-JE439QWQKB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export default app; 