import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Profile, Company } from '../types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { 
  FaUserTie, // Kivitelező
  FaUsers,   // Megrendelő
  FaBuilding, // Cég
  FaUser,    // Magánszemély
  FaBriefcase, // Egyéni vállalkozó
  FaCheck,
  FaStar,
  FaTrash,
  FaPlus,
  FaImage,
  FaTimes,
  FaChevronDown,
  FaChevronUp
} from 'react-icons/fa';
import { SERVICES } from '../constants/profileServices';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const profileTypes = [
  {
    type: 'client',
    title: 'Megrendelő',
    description: 'Projektek létrehozása és kivitelezők keresése',
    icon: FaUsers,
    color: 'bg-gradient-to-br from-[#20A64B] to-[#178f3f]'
  },
  {
    type: 'contractor',
    title: 'Kivitelező',
    description: 'Projektek elvállalása és szolgáltatások nyújtása',
    icon: FaUserTie,
    color: 'bg-gradient-to-br from-[#20A64B] to-[#178f3f]'
  }
];

const getBusinessTypes = (profileType: 'contractor' | 'client') => {
  const allTypes = [
    {
      type: 'individual',
      title: 'Magánszemély',
      description: 'Egyéni felhasználóként szeretnék regisztrálni',
      icon: FaUser,
      color: 'bg-gradient-to-br from-[#20A64B] to-[#178f3f]'
    },
    {
      type: 'company',
      title: 'Cég',
      description: 'Cégként szeretnék regisztrálni',
      icon: FaBuilding,
      color: 'bg-gradient-to-br from-[#20A64B] to-[#178f3f]'
    },
    {
      type: 'entrepreneur',
      title: 'Egyéni vállalkozó',
      description: 'Egyéni vállalkozóként szeretnék regisztrálni',
      icon: FaBriefcase,
      color: 'bg-gradient-to-br from-[#20A64B] to-[#178f3f]'
    }
  ];

  // Ha kivitelező, akkor csak cég vagy egyéni vállalkozó lehet
  return profileType === 'contractor' 
    ? allTypes.filter(type => type.type !== 'individual')
    : allTypes;
};

// Lépések definíciója
const STEPS = [
  'Profil típusa',
  'Vállalkozás típusa',
  'Cégadatok',
  'Szolgáltatások'
];

const ProgressBar = ({ currentStep, maxSteps, onStepClick }: { 
  currentStep: number;
  maxSteps: number;
  onStepClick: (step: number) => void;
}) => (
  <div className="mb-8">
    <div className="flex justify-between mb-2">
      {STEPS.slice(0, maxSteps).map((stepName, index) => (
        <button
          key={index}
          onClick={() => index < currentStep && onStepClick(index + 1)}
          className={`flex-1 text-center transition-colors ${
            index === currentStep - 1
              ? 'text-[#20A64B] font-medium'
              : index < currentStep - 1
              ? 'text-gray-400 hover:text-[#20A64B] cursor-pointer'
              : 'text-gray-600 cursor-default'
          }`}
          disabled={index >= currentStep}
        >
          <span className="relative">
            {stepName}
            {index < currentStep - 1 && (
              <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-[#20A64B] transform scale-x-0 transition-transform group-hover:scale-x-100" />
            )}
          </span>
        </button>
      ))}
    </div>
    <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
      <div
        className="h-full bg-[#20A64B] transition-all duration-300 rounded-full"
        style={{
          width: `${((currentStep - 1) / (maxSteps - 1)) * 100}%`
        }}
      />
    </div>
  </div>
);

const TypeSelector = ({ 
  items, 
  selectedValue, 
  onSelect, 
  title 
}: { 
  items: typeof profileTypes | typeof businessTypes, 
  selectedValue: string, 
  onSelect: (value: any) => void,
  title: string
}) => (
    <div className="space-y-6">
      <h3 className="text-xl text-white mb-4">{title}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {items.map(({ type, title, description, icon: Icon, color }) => (
          <button
            key={type}
            onClick={() => onSelect(type)}
            className={`relative overflow-hidden group rounded-xl transition-all duration-300 ${
              selectedValue === type 
                ? `${color} shadow-lg scale-105` 
                : 'bg-gray-700 hover:bg-gray-600 hover:scale-105'
            }`}
          >
            <div className="p-6 relative z-10">
              <Icon className={`text-5xl mb-4 ${selectedValue === type ? 'text-white' : 'text-[#20A64B]'}`} />
              <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
              <p className="text-gray-100 text-sm opacity-90">{description}</p>
            </div>
            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity" />
          </button>
        ))}
      </div>
    </div>
  );

export default function ProfileSetup({ 
  onComplete, 
  initialProfile,
  isEditing = false
}: {
  onComplete: () => void;
  initialProfile?: Profile | null;
  isEditing?: boolean;
}) {
  const { createProfile, updateProfile } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [profileType, setProfileType] = useState<'contractor' | 'client'>(
    initialProfile?.type || 'client'
  );
  const [userType, setUserType] = useState<'individual' | 'company' | 'entrepreneur'>(
    initialProfile?.userType || (profileType === 'contractor' ? 'company' : 'individual')
  );
  const [company, setCompany] = useState<Company>({
    name: initialProfile?.company?.name || '',
    taxNumber: initialProfile?.company?.taxNumber || '',
    registrationNumber: initialProfile?.company?.registrationNumber || '',
    address: initialProfile?.company?.address || '',
    website: initialProfile?.company?.website || ''
  });
  const [services, setServices] = useState<string[]>(
    initialProfile?.services || []
  );
  const [contactInfo, setContactInfo] = useState({
    contactName: initialProfile?.contactName || '',
    contactEmail: initialProfile?.contactEmail || '',
    phone: initialProfile?.phone || '',
    website: initialProfile?.website || '',
    description: initialProfile?.description || ''
  });
  const [references, setReferences] = useState<Reference[]>(
    initialProfile?.references || []
  );
  const [isReferencesOpen, setIsReferencesOpen] = useState(false);

  // Ha kivitelezőre váltunk és magánszemély volt kiválasztva, állítsuk át cégre
  useEffect(() => {
    if (profileType === 'contractor' && userType === 'individual') {
      setUserType('company');
    }
  }, [profileType]);

  const handleSubmit = async () => {
    try {
      const profileData = {
        type: profileType,
        userType,
        ...(userType !== 'individual' && company.name && company.taxNumber && {
          company: {
            name: company.name,
            taxNumber: company.taxNumber,
            ...(company.registrationNumber && { registrationNumber: company.registrationNumber }),
            ...(company.address && { address: company.address })
          }
        }),
        ...(profileType === 'contractor' && services.length > 0 && { services }),
        ...(contactInfo.description && { description: contactInfo.description }),
        ...(contactInfo.contactEmail && { contactEmail: contactInfo.contactEmail }),
        ...(contactInfo.phone && { phone: contactInfo.phone }),
        ...(contactInfo.contactName && { contactName: contactInfo.contactName }),
        ...(contactInfo.website && { website: contactInfo.website }),
        ...(profileType === 'contractor' && references.length > 0 && { references }),
      };

      if (isEditing && initialProfile) {
        // Meglévő profil frissítése
        await updateProfile(initialProfile.id, profileData);
      } else {
        // Új profil létrehozása
        await createProfile(profileData);
      }

      onComplete();
    } catch (error) {
      console.error('Error saving profile:', error);
      toast.error('Hiba történt a profil mentése során');
    }
  };

  // Aktuális maximális lépésszám kiszámítása
  const getMaxSteps = () => {
    if (profileType === 'contractor') {
      return 4; // Kivitelezőnél mindig 4 lépés (nincs magánszemély opció)
    }
    return userType === 'individual' ? 2 : 3;
  };

  const handleStepChange = (newStep: number) => {
    // Csak visszafelé lehet lépni
    if (newStep < step) {
      setStep(newStep);
    }
  };

  return (
    <div className="space-y-8">
      {/* 1. Profil típus választó */}
      <div className="space-y-4">
        <h2 className="text-lg font-medium text-white">Profil típusa</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <button
            onClick={() => setProfileType('client')}
            className={`flex items-center gap-4 p-6 rounded-lg border-2 transition-colors ${
              profileType === 'client'
                ? 'border-[#20A64B] bg-[#20A64B]/10'
                : 'border-gray-600 hover:border-gray-500'
            }`}
          >
            <FaUsers className="text-2xl text-[#20A64B]" />
            <div className="text-left">
              <div className="font-medium text-white">Megrendelő</div>
              <div className="text-sm text-gray-400">Projektek létrehozása és kezelése</div>
            </div>
          </button>

          <button
            onClick={() => setProfileType('contractor')}
            className={`flex items-center gap-4 p-6 rounded-lg border-2 transition-colors ${
              profileType === 'contractor'
                ? 'border-[#20A64B] bg-[#20A64B]/10'
                : 'border-gray-600 hover:border-gray-500'
            }`}
          >
            <FaUserTie className="text-2xl text-[#20A64B]" />
            <div className="text-left">
              <div className="font-medium text-white">Kivitelező</div>
              <div className="text-sm text-gray-400">Ajánlatok készítése és projektek vállalása</div>
            </div>
          </button>
        </div>
      </div>

      {/* 2. Vállalkozási forma */}
      <div className="space-y-4">
        <h2 className="text-lg font-medium text-white">Vállalkozási forma</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Magánszemély opció csak megrendelőknél */}
          {profileType === 'client' && (
            <button
              onClick={() => setUserType('individual')}
              className={`flex items-center gap-4 p-6 rounded-lg border-2 transition-colors ${
                userType === 'individual'
                  ? 'border-[#20A64B] bg-[#20A64B]/10'
                  : 'border-gray-600 hover:border-gray-500'
              }`}
            >
              <FaUser className="text-2xl text-[#20A64B]" />
              <div className="text-left">
                <div className="font-medium text-white">Magánszemély</div>
                <div className="text-sm text-gray-400">Egyéni felhasználóként</div>
              </div>
            </button>
          )}

          <button
            onClick={() => setUserType('company')}
            className={`flex items-center gap-4 p-6 rounded-lg border-2 transition-colors ${
              userType === 'company'
                ? 'border-[#20A64B] bg-[#20A64B]/10'
                : 'border-gray-600 hover:border-gray-500'
            }`}
          >
            <FaBuilding className="text-2xl text-[#20A64B]" />
            <div className="text-left">
              <div className="font-medium text-white">Cég</div>
              <div className="text-sm text-gray-400">Bejegyzett vállalkozásként</div>
            </div>
          </button>

          <button
            onClick={() => setUserType('entrepreneur')}
            className={`flex items-center gap-4 p-6 rounded-lg border-2 transition-colors ${
              userType === 'entrepreneur'
                ? 'border-[#20A64B] bg-[#20A64B]/10'
                : 'border-gray-600 hover:border-gray-500'
            }`}
          >
            <FaBriefcase className="text-2xl text-[#20A64B]" />
            <div className="text-left">
              <div className="font-medium text-white">Egyéni vállalkozó</div>
              <div className="text-sm text-gray-400">Egyéni vállalkozóként</div>
            </div>
          </button>
        </div>
      </div>

      {/* 3. Cégadatok (ha nem magánszemély) */}
      {userType !== 'individual' && (
        <div className="space-y-4">
          <h2 className="text-lg font-medium text-white">Cégadatok</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Cégnév
              </label>
              <input
                type="text"
                value={company.name}
                onChange={(e) => setCompany({...company, name: e.target.value})}
                className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Adószám
              </label>
              <input
                type="text"
                value={company.taxNumber}
                onChange={(e) => setCompany({...company, taxNumber: e.target.value})}
                className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                required
              />
            </div>
          </div>
        </div>
      )}

      {/* 4. Elérhetőségi adatok */}
      <div className="space-y-4">
        <h2 className="text-lg font-medium text-white">Elérhetőségi adatok</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Kapcsolattartó neve
            </label>
            <input
              type="text"
              value={contactInfo.contactName}
              onChange={(e) => setContactInfo({...contactInfo, contactName: e.target.value})}
              className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Email cím
            </label>
            <input
              type="email"
              value={contactInfo.contactEmail}
              onChange={(e) => setContactInfo({...contactInfo, contactEmail: e.target.value})}
              className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Telefonszám
            </label>
            <input
              type="tel"
              value={contactInfo.phone}
              onChange={(e) => setContactInfo({...contactInfo, phone: e.target.value})}
              className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Weboldal
            </label>
            <input
              type="url"
              value={contactInfo.website}
              onChange={(e) => setContactInfo({...contactInfo, website: e.target.value})}
              className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
              placeholder="https://"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Rövid bemutatkozás
          </label>
          <textarea
            value={contactInfo.description}
            onChange={(e) => setContactInfo({...contactInfo, description: e.target.value})}
            className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
            rows={4}
            placeholder="Írjon egy rövid bemutatkozást..."
          />
        </div>
      </div>

      {/* 5. Szolgáltatások (csak kivitelezőknél, mindig utoljára) */}
      {profileType === 'contractor' && (
        <div className="space-y-6 mt-8 pt-8 border-t border-gray-700">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-medium text-white">Szolgáltatások</h2>
            <span className="text-sm text-gray-400">
              Válassza ki a nyújtott szolgáltatásokat
            </span>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {SERVICES.map((service) => (
              <div
                key={service.id}
                className={`relative p-4 rounded-lg border-2 transition-all cursor-pointer ${
                  services.includes(service.id)
                    ? 'border-[#20A64B] bg-[#20A64B]/10'
                    : 'border-gray-600 hover:border-gray-500'
                }`}
                onClick={() => {
                  if (services.includes(service.id)) {
                    setServices(services.filter(s => s !== service.id));
                  } else {
                    setServices([...services, service.id]);
                  }
                }}
              >
                <div className="flex items-start gap-3">
                  <div className={`p-2 rounded-lg ${
                    services.includes(service.id)
                      ? 'bg-[#20A64B]/20 text-[#20A64B]'
                      : 'bg-gray-700 text-gray-400'
                  }`}>
                    <service.icon className="w-5 h-5" />
                  </div>
                  <div>
                    <div className="flex items-center gap-2">
                      <h3 className="font-medium text-white">
                        {service.name}
                      </h3>
                      {services.includes(service.id) && (
                        <FaCheck className="w-4 h-4 text-[#20A64B]" />
                      )}
                    </div>
                    <p className="text-sm text-gray-400 mt-1">
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {services.length === 0 && (
            <p className="text-sm text-yellow-500">
              Legalább egy szolgáltatást ki kell választania
            </p>
          )}
        </div>
      )}

      {/* Referenciák (csak kivitelezőknél) */}
      {profileType === 'contractor' && (
        <div className="space-y-6 mt-8 pt-8 border-t border-gray-700">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setIsReferencesOpen(!isReferencesOpen)}
          >
            <div className="flex items-center gap-2">
              <h2 className="text-lg font-medium text-white">Referenciák</h2>
              {references.length > 0 && (
                <span className="text-sm text-gray-400">
                  ({references.length} db)
                </span>
              )}
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setReferences([...references, {
                    projectName: '',
                    clientName: '',
                    description: '',
                    rating: 5,
                    date: new Date().toISOString().split('T')[0]
                  }]);
                  setIsReferencesOpen(true);
                }}
                className="px-4 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors text-sm flex items-center gap-2"
              >
                <FaPlus className="w-3.5 h-3.5" />
                <span>Új referencia</span>
              </button>
              {isReferencesOpen ? (
                <FaChevronUp className="w-5 h-5 text-gray-400" />
              ) : (
                <FaChevronDown className="w-5 h-5 text-gray-400" />
              )}
            </div>
          </div>

          {isReferencesOpen && (
            <div className="space-y-4">
              {references.map((reference, index) => (
                <div key={index} className="bg-gray-700/50 rounded-lg p-4">
                  <div className="flex items-start justify-between mb-4">
                    <h3 className="text-white font-medium">
                      {index + 1}. referencia
                    </h3>
                    <button
                      onClick={() => setReferences(references.filter((_, i) => i !== index))}
                      className="text-red-500 hover:text-red-400 transition-colors"
                    >
                      <FaTrash className="w-4 h-4" />
                    </button>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Projekt neve
                      </label>
                      <input
                        type="text"
                        value={reference.projectName}
                        onChange={(e) => {
                          const newReferences = [...references];
                          newReferences[index] = {
                            ...reference,
                            projectName: e.target.value
                          };
                          setReferences(newReferences);
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Megrendelő neve
                      </label>
                      <input
                        type="text"
                        value={reference.clientName}
                        onChange={(e) => {
                          const newReferences = [...references];
                          newReferences[index] = {
                            ...reference,
                            clientName: e.target.value
                          };
                          setReferences(newReferences);
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                        required
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Leírás
                      </label>
                      <textarea
                        value={reference.description}
                        onChange={(e) => {
                          const newReferences = [...references];
                          newReferences[index] = {
                            ...reference,
                            description: e.target.value
                          };
                          setReferences(newReferences);
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                        rows={3}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Értékelés
                      </label>
                      <div className="flex items-center gap-1">
                        {[...Array(5)].map((_, i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={() => {
                              const newReferences = [...references];
                              newReferences[index] = {
                                ...reference,
                                rating: i + 1
                              };
                              setReferences(newReferences);
                            }}
                            className="focus:outline-none"
                          >
                            <FaStar 
                              className={`w-6 h-6 ${
                                i < reference.rating 
                                  ? 'text-yellow-400' 
                                  : 'text-gray-600'
                              }`}
                            />
                          </button>
                        ))}
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Dátum
                      </label>
                      <input
                        type="date"
                        value={reference.date}
                        onChange={(e) => {
                          const newReferences = [...references];
                          newReferences[index] = {
                            ...reference,
                            date: e.target.value
                          };
                          setReferences(newReferences);
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                        required
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Képek
                      </label>
                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-4">
                        {reference.images?.map((image, imageIndex) => (
                          <div key={imageIndex} className="relative aspect-video group">
                            <img
                              src={image}
                              alt={`Referencia ${imageIndex + 1}`}
                              className="w-full h-full object-cover rounded-lg"
                            />
                            <button
                              onClick={() => {
                                const newReferences = [...references];
                                newReferences[index] = {
                                  ...reference,
                                  images: reference.images?.filter((_, i) => i !== imageIndex)
                                };
                                setReferences(newReferences);
                              }}
                              className="absolute top-2 right-2 w-8 h-8 bg-red-500 rounded-full flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-opacity"
                            >
                              <FaTimes className="w-4 h-4" />
                            </button>
                          </div>
                        ))}
                        <label className="relative aspect-video bg-gray-600 rounded-lg hover:bg-gray-500 transition-colors cursor-pointer flex flex-col items-center justify-center gap-2">
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            className="hidden"
                            onChange={async (e) => {
                              if (!e.target.files?.length) return;

                              const files = Array.from(e.target.files);
                              const newImages: string[] = [];

                              for (const file of files) {
                                try {
                                  // Generálunk egy egyedi fájlnevet
                                  const fileName = `references/${Date.now()}-${file.name}`;
                                  const storageRef = ref(storage, fileName);
                                  
                                  // Feltöltjük a képet
                                  await uploadBytes(storageRef, file);
                                  
                                  // Lekérjük a letöltési URL-t
                                  const url = await getDownloadURL(storageRef);
                                  newImages.push(url);
                                } catch (error) {
                                  console.error('Error uploading image:', error);
                                  toast.error('Hiba történt a kép feltöltése során');
                                }
                              }

                              // Hozzáadjuk az új képeket a referenciához
                              const newReferences = [...references];
                              newReferences[index] = {
                                ...reference,
                                images: [...(reference.images || []), ...newImages]
                              };
                              setReferences(newReferences);
                            }}
                          />
                          <FaImage className="w-6 h-6 text-gray-400" />
                          <span className="text-sm text-gray-400">Képek hozzáadása</span>
                        </label>
                      </div>
                      {reference.images && reference.images.length > 0 && (
                        <p className="text-sm text-gray-400">
                          {reference.images.length} kép feltöltve
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {references.length === 0 && (
                <p className="text-gray-400 text-center py-4">
                  Még nincsenek referenciák. Kattintson az "Új referencia" gombra a hozzáadáshoz.
                </p>
              )}
            </div>
          )}
        </div>
      )}

      {/* Mentés gomb */}
      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={onComplete}
          className="px-6 py-2 text-gray-300 hover:text-white transition-colors"
        >
          Mégse
        </button>
        <button
          onClick={handleSubmit}
          className="px-6 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors"
        >
          {isEditing ? 'Módosítások mentése' : 'Profil létrehozása'}
        </button>
      </div>
    </div>
  );
} 