import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Profile } from '../../types';
import { FaSearch, FaFilter, FaStar, FaUserCheck } from 'react-icons/fa';
import { SERVICES } from '../../constants/profileServices';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function ContractorSearch() {
  const { user } = useAuth();
  const [contractors, setContractors] = useState<Profile[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContractors = async () => {
      try {
        const q = query(
          collection(db, 'profiles'),
          where('type', '==', 'contractor')
        );
        const querySnapshot = await getDocs(q);
        const contractorsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Profile[];
        
        setContractors(contractorsData);
      } catch (error) {
        console.error('Error fetching contractors:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContractors();
  }, []);

  const filteredContractors = contractors.filter(contractor => {
    // Keresési szűrő
    const matchesSearch = 
      contractor.company?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contractor.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contractor.services?.some(service => 
        service.toLowerCase().includes(searchTerm.toLowerCase())
      );

    // Szolgáltatás szűrő
    const matchesServices = selectedServices.length === 0 || 
      selectedServices.every(service => contractor.services?.includes(service));

    // Értékelés szűrő
    const matchesRating = !selectedRating || 
      (contractor.rating && contractor.rating >= selectedRating);

    return matchesSearch && matchesServices && matchesRating;
  });

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="bg-gray-800 rounded-lg p-6">
          {/* Fejléc */}
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-white">Kivitelezők keresése</h1>
            <p className="text-gray-400 mt-1">
              Találja meg a megfelelő szakembert projektjéhez
            </p>
          </div>

          {/* Szűrők */}
          <div className="space-y-6 mb-8">
            {/* Keresés */}
            <div className="relative">
              <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Keresés név, leírás vagy szolgáltatás alapján..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
              />
            </div>

            {/* Szolgáltatások */}
            <div>
              <h3 className="text-white font-medium mb-3">Szolgáltatások</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {SERVICES.map(service => (
                  <button
                    key={service.id}
                    onClick={() => {
                      setSelectedServices(prev => 
                        prev.includes(service.id)
                          ? prev.filter(id => id !== service.id)
                          : [...prev, service.id]
                      );
                    }}
                    className={`flex items-center gap-3 p-3 rounded-lg transition-all ${
                      selectedServices.includes(service.id)
                        ? 'bg-[#20A64B] text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                  >
                    <div className={`w-10 h-10 rounded-lg flex items-center justify-center ${
                      selectedServices.includes(service.id)
                        ? 'bg-[#178f3f]'
                        : 'bg-gray-600'
                    }`}>
                      {React.createElement(service.icon, { className: "w-6 h-6" })}
                    </div>
                    <div className="flex-1">
                      <div className="text-sm font-medium">{service.name}</div>
                      <div className="text-xs opacity-75">{service.description}</div>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Értékelés szűrő */}
            <div>
              <h3 className="text-white font-medium mb-3">Minimum értékelés</h3>
              <div className="flex items-center gap-2">
                {[5,4,3,2,1].map(rating => (
                  <button
                    key={rating}
                    onClick={() => setSelectedRating(rating === selectedRating ? null : rating)}
                    className={`flex items-center gap-1 px-4 py-2 rounded-lg transition-colors ${
                      rating === selectedRating
                        ? 'bg-[#20A64B] text-white'
                        : 'bg-gray-700 text-gray-400 hover:bg-gray-600'
                    }`}
                  >
                    <FaStar className="w-4 h-4" />
                    <span>{rating}+</span>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Kivitelezők lista */}
          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B] mx-auto"></div>
            </div>
          ) : filteredContractors.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredContractors.map(contractor => (
                <div
                  key={contractor.id}
                  className="bg-gray-700 rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300"
                >
                  <div className="p-6">
                    <div className="flex items-start justify-between mb-4">
                      <div>
                        <h3 className="text-lg font-semibold text-white">
                          {contractor.company?.name}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {contractor.userType === 'company' ? 'Cég' : 'Egyéni vállalkozó'}
                        </p>
                      </div>
                      {contractor.verified && (
                        <FaUserCheck className="text-[#20A64B] w-5 h-5" />
                      )}
                    </div>
                    
                    <p className="text-gray-300 text-sm mb-4 line-clamp-2">
                      {contractor.description}
                    </p>

                    {contractor.services && (
                      <div className="flex flex-wrap gap-2 mb-4">
                        {contractor.services.map(service => (
                          <span
                            key={service}
                            className="px-2 py-1 bg-gray-600 rounded-full text-xs text-gray-300"
                          >
                            {SERVICES.find(s => s.id === service)?.name}
                          </span>
                        ))}
                      </div>
                    )}

                    <div className="flex items-center justify-between text-sm">
                      <div className="flex items-center gap-1 text-yellow-400">
                        <FaStar />
                        <span>{contractor.rating || 'Új'}</span>
                      </div>
                      <button
                        onClick={() => navigate(`/contractors/${contractor.id}`)}
                        className="text-[#20A64B] hover:underline"
                      >
                        Profil megtekintése
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-400">
                Nem található kivitelező{searchTerm && ' a keresési feltételekkel'}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 