import React from 'react';
import { FaSearch, FaUser, FaHardHat, FaBriefcase, FaShieldAlt } from 'react-icons/fa';

type UserFilter = 'all' | 'client' | 'contractor' | 'admin';

interface UserFilterProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  activeFilter: UserFilter;
  onFilterChange: (filter: UserFilter) => void;
  totalUsers: number;
  loading: boolean;
}

const FILTERS: { value: UserFilter; label: string; icon: React.ReactNode }[] = [
  { value: 'all', label: 'Összes', icon: <FaUser className="w-4 h-4" /> },
  { value: 'client', label: 'Megrendelők', icon: <FaBriefcase className="w-4 h-4" /> },
  { value: 'contractor', label: 'Kivitelezők', icon: <FaHardHat className="w-4 h-4" /> },
  { value: 'admin', label: 'Adminok', icon: <FaShieldAlt className="w-4 h-4" /> }
];

export default function UserFilter({
  searchTerm,
  onSearchChange,
  activeFilter,
  onFilterChange,
  totalUsers,
  loading
}: UserFilterProps) {
  return (
    <div className="mb-6">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4">
        <div className="relative flex-1">
          <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="text"
            placeholder="Keresés név vagy email alapján..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
          />
        </div>
        <div className="flex items-center gap-2">
          {FILTERS.map(({ value, label, icon }) => (
            <button
              key={value}
              onClick={() => onFilterChange(value)}
              className={`px-3 py-1.5 rounded-lg flex items-center gap-2 transition-colors ${
                activeFilter === value
                  ? 'bg-[#20A64B] text-white'
                  : 'bg-gray-700/50 text-gray-300 hover:bg-gray-600'
              }`}
            >
              {icon}
              <span className="hidden sm:inline">{label}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="mt-2 text-sm text-gray-400">
        {loading ? (
          'Betöltés...'
        ) : (
          `${totalUsers} felhasználó`
        )}
      </div>
    </div>
  );
} 