import React, { useEffect } from 'react';
import { FaFile, FaRegMoneyBillAlt, FaCheck, FaStar, FaRegClock, FaTrash, FaInfoCircle, FaDownload } from 'react-icons/fa';
import { CATEGORIES, PROJECT_SIZES } from '../../../constants/projectCategories';
import { ProjectCategory, ProjectRequirement, ProjectTimeline, ProjectBudget, ProjectContact, ProjectReference, ProjectQuestionnaire, ProjectAttachment, DocumentType } from '../../../types/project';
import { calculateAdvancePaymentScore, getInsuranceScore, getMinimumAdvancePayment, calculateProjectScore } from '../../../utils/projectScoring';
import { formatDate } from '../../../utils/dateUtils';

const STORAGE_KEY = 'webtender_project_draft';

interface SummaryStepProps {
  basicInfo: {
    title: string;
    category: ProjectCategory;
    subcategory: string;
    description: string;
    scope: 'small' | 'medium' | 'large';
    isGreenfield: boolean;
  };
  budget: ProjectBudget;
  requirements: ProjectRequirement[];
  timeline: ProjectTimeline;
  contact: ProjectContact;
  references: ProjectReference[];
  attachments: Array<{
    name: string;
    url: string;
    type: string;
    size: number;
    documentType?: string;
  }>;
  questionnaire: ProjectQuestionnaire;
  onDataRestore?: (data: any) => void;
}

export const SummaryStep: React.FC<SummaryStepProps> = ({
  basicInfo,
  budget,
  requirements,
  timeline,
  contact,
  references,
  attachments,
  questionnaire,
  onDataRestore
}) => {
  // Próbáljuk visszatölteni a mentett adatokat
  useEffect(() => {
    const hasRestoredData = localStorage.getItem('webtender_data_restored');
    if (hasRestoredData) return;

    const savedData = localStorage.getItem(STORAGE_KEY);
    if (savedData && onDataRestore) {
      try {
        const parsedData = JSON.parse(savedData);
        onDataRestore(parsedData);
        localStorage.setItem('webtender_data_restored', 'true');
      } catch (error) {
        console.error('Error restoring project data:', error);
      }
    }
  }, []); // Empty dependency array

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      localStorage.removeItem('webtender_data_restored');
    };
  }, []);

  // Helper function to format decision times
  const formatDecisionTime = (time: string) => {
    switch (time) {
      case '1_day': return '24 órán belül';
      case '2_3_days': return '2-3 napon belül';
      case '1_week': return '1 héten belül';
      case '2_weeks': return '2 héten belül';
      case '2_hours': return '2 órán belül';
      case '4_hours': return '4 órán belül';
      case '8_hours': return '8 órán belül';
      case '24_hours': return '24 órán belül';
      case '1_hour': return '1 órán belül';
      default: return time;
    }
  };

  // Helper function to format meeting frequency
  const formatFrequency = (freq: string) => {
    switch (freq) {
      case 'daily': return 'Naponta';
      case '2_3_times_week': return 'Hetente 2-3 alkalommal';
      case 'weekly': return 'Hetente';
      case 'biweekly': return 'Kéthetente';
      default: return freq;
    }
  };

  // Helper function to format communication channel
  const formatChannel = (channel: 'email' | 'phone' | 'both') => {
    switch (channel) {
      case 'email': return 'Email';
      case 'phone': return 'Telefon';
      case 'both': return 'Email és telefon';
      default: return channel;
    }
  };

  // Számoljuk ki a pontszámokat
  const projectScore = calculateProjectScore({
    description: basicInfo.description,
    category: basicInfo.category,
    subcategory: basicInfo.subcategory,
    budget,
    timeline,
    attachments: attachments.map(a => ({ 
      url: a.url,
      name: a.name,
      type: a.type,
      size: a.size,
      documentType: (a.documentType || 'other') as DocumentType
    })),
    references,
    requirements,
    details: {
      platforms: [],
      responsiveDesign: false,
      seoRequirements: false,
      accessibility: false,
      analytics: false,
      maintenance: false,
      training: false
    },
    questionnaire
  });

  // Ellenőrizzük a részpontszámok összegét
  const totalPoints = 
    projectScore.breakdown.basicInfo +
    projectScore.breakdown.documentation +
    projectScore.breakdown.requirements +
    projectScore.breakdown.questionnaire +
    projectScore.breakdown.budget +
    projectScore.breakdown.timeline;

  // Ha eltérés van, korrigáljuk
  if (totalPoints !== projectScore.total) {
    console.warn('Score mismatch detected:', { 
      calculatedTotal: totalPoints, 
      reportedTotal: projectScore.total 
    });
    projectScore.total = totalPoints;
  }

  return (
    <div>
      {/* Fejléc */}
      <div className="mb-8 pb-6 border-b border-gray-700">
        <h2 className="text-2xl font-bold text-white mb-2">{basicInfo.title}</h2>
        <div className="flex items-center gap-4 text-gray-400">
          <div className="flex items-center gap-2">
            <FaRegClock className="w-4 h-4" />
            {timeline.deadline ? formatDate(timeline.deadline) : 'Nincs megadva határidő'}
          </div>
          <div className="flex items-center gap-2">
            <FaRegMoneyBillAlt className="w-4 h-4" />
            {budget.min > 0 && budget.max > 0 
              ? `${budget.min.toLocaleString()} - ${budget.max.toLocaleString()} ${budget.currency}`
              : 'Nincs megadva költségkeret'}
          </div>
        </div>
      </div>

      {/* Tartalmi szekciók */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Bal oldali szekció */}
        <div className="space-y-8">
          {/* Alapinformációk és dokumentáció */}
          <section className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-medium text-white mb-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FaInfoCircle className="w-5 h-5 text-[#20A64B]" />
                Alapinformációk és dokumentáció
              </div>
              <span className="text-sm text-gray-400">max 80 pont</span>
            </h3>
            <div className="space-y-6">
              {/* Projekt leírás */}
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Projekt leírás</div>
                <div className="text-gray-400">
                  {basicInfo.description ? (
                    <div className="flex items-start gap-2">
                      <FaCheck className="text-[#20A64B] w-4 h-4 mt-1" />
                      <div>
                        <div className="text-sm">{basicInfo.description}</div>
                        <div className="text-sm text-[#20A64B] mt-1">
                          {basicInfo.description.length > 500 ? '20 pont' : 
                           basicInfo.description.length > 200 ? '10 pont' : '4 pont'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-gray-500">Nincs megadva (0 pont)</div>
                  )}
                </div>
              </div>

              {/* Kategória és alkategória */}
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Kategória és alkategória</div>
                <div className="text-gray-400">
                  <div className="flex items-center gap-2">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Kategória: {CATEGORIES.find(c => c.value === basicInfo.category)?.label || basicInfo.category}</span>
                      <span className="text-[#20A64B] ml-2">{basicInfo.category ? '10 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Alkategória: {basicInfo.subcategory}</span>
                      <span className="text-[#20A64B] ml-2">{basicInfo.subcategory ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dokumentumok */}
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Dokumentumok</div>
                <div className="text-gray-400">
                  {attachments.length > 0 ? (
                    attachments.map((attachment, index) => (
                      <div key={index} className="flex items-center gap-2 py-1">
                        <FaCheck className="text-[#20A64B] w-4 h-4" />
                        <div>
                          <div className="flex items-center gap-2">
                            <FaFile className="text-gray-400 w-4 h-4" />
                            <a 
                              href={attachment.url}
                              download={attachment.name}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-[#20A64B] hover:text-[#178f3f] transition-colors"
                            >
                              {attachment.name}
                            </a>
                          </div>
                          <div className="text-sm text-[#20A64B] mt-1">
                            {attachment.documentType === 'project_description' ? '5 pont' : 
                             attachment.documentType === 'technical_spec' ? '5 pont' : 
                             attachment.documentType === 'design_doc' ? '5 pont' : '2 pont'}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-gray-500">Nincsenek csatolt dokumentumok (0 pont)</div>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* Időzítés és követelmények */}
          <section className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-medium text-white mb-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FaRegClock className="w-5 h-5 text-[#20A64B]" />
                Időzítés és követelmények
              </div>
              <span className="text-sm text-gray-400">max 20 pont</span>
            </h3>
            <div className="space-y-4">
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Határidők</div>
                <div className="text-gray-400">
                  <div className="flex items-center gap-2">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Kezdés: {timeline.expectedStartDate ? formatDate(timeline.expectedStartDate) : 'Nincs megadva'}</span>
                      <span className="text-[#20A64B] ml-2">{timeline.expectedStartDate ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Határidő: {timeline.deadline ? formatDate(timeline.deadline) : 'Nincs megadva'}</span>
                      <span className="text-[#20A64B] ml-2">{timeline.deadline ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Rugalmasság: {timeline.isFlexible ? 'Rugalmas' : 'Fix határidő'}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Követelmények</div>
                <div className="text-gray-400">
                  {requirements.map((req, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <FaCheck className="text-[#20A64B] w-4 h-4" />
                      <div className="text-sm text-gray-400 flex items-center gap-2">
                        <FaCheck className="text-[#20A64B] w-4 h-4" />
                        <div>
                          <span>{req.description}</span>
                          <span className="text-[#20A64B] ml-2">2 pont</span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="text-sm text-gray-500 mt-1">Maximum 5 követelményig (2 pont / követelmény)</div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Jobb oldali szekció */}
        <div className="space-y-8">
          {/* Költségvetés */}
          <section className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-medium text-white mb-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FaRegMoneyBillAlt className="w-5 h-5 text-[#20A64B]" />
                Költségvetés
              </div>
              <span className="text-sm text-gray-400">max 60 pont</span>
            </h3>
            <div className="space-y-4">
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Költségvetési keretek</div>
                <div className="text-gray-400">
                  <div className="flex items-center gap-2">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>
                        {budget.min > 0 && budget.max > 0 
                          ? `${budget.min.toLocaleString()} - ${budget.max.toLocaleString()} ${budget.currency}` 
                          : 'Nincs megadva'}
                      </span>
                      <span className="text-[#20A64B] ml-2">
                        {budget.min > 0 && budget.max > 0 ? '10 pont' : '0 pont'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Előlegfizetés</div>
                <div className="text-gray-400">
                  {budget.advancePayment?.enabled ? (
                    <div className="flex items-center gap-2">
                      <FaCheck className="text-[#20A64B] w-4 h-4" />
                      <div>
                        <span>{budget.advancePayment.percentage}% előleg</span>
                        <span className="text-[#20A64B] ml-2">
                          {calculateAdvancePaymentScore(budget)} pont
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="text-gray-500">Nincs előlegfizetés (0 pont)</div>
                  )}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-300 mb-2">Projekt biztosítás</div>
                <div className="text-gray-400">
                  <div className="flex items-center gap-2">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>
                        {budget.insurance === 'premium' ? 'Prémium biztosítás' : 
                         budget.insurance === 'basic' ? 'Alap biztosítás' : 
                         'Nincs biztosítás'}
                      </span>
                      <span className="text-[#20A64B] ml-2">
                        {budget.insurance === 'premium' ? '30 pont' : 
                         budget.insurance === 'basic' ? '15 pont' : 
                         '0 pont'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Kérdőív */}
          <section className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-medium text-white mb-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FaInfoCircle className="w-5 h-5 text-[#20A64B]" />
                Kérdőív
              </div>
              <span className="text-sm text-gray-400">max 40 pont</span>
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="text-sm font-medium text-gray-300">Döntéshozatal (20 pont)</div>
                <div>
                  <div className="text-sm text-gray-400 flex items-center gap-2">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Költségkezelés: {questionnaire.extraCostHandling || 'Nincs megadva'}</span>
                      <span className="text-[#20A64B] ml-2">{questionnaire.extraCostHandling ? '10 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400 flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Döntési idő: {formatDecisionTime(questionnaire.decisionTime || '')}</span>
                      <span className="text-[#20A64B] ml-2">{questionnaire.decisionTime ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400 flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Sürgős döntések: {formatDecisionTime(questionnaire.urgentDecisionTime || '')}</span>
                      <span className="text-[#20A64B] ml-2">{questionnaire.urgentDecisionTime ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="text-sm font-medium text-gray-300">Együttműködés (20 pont)</div>
                <div>
                  <div className="text-sm text-gray-400 flex items-center gap-2">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Egyeztetések: {formatFrequency(questionnaire.meetingAvailability || '')}</span>
                      <span className="text-[#20A64B] ml-2">{questionnaire.meetingAvailability ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400 flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Státusz frissítések: {formatFrequency(questionnaire.updateFrequency || '')}</span>
                      <span className="text-[#20A64B] ml-2">{questionnaire.updateFrequency ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400 flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Kommunikációs csatornák: {questionnaire.communicationChannel ? formatChannel(questionnaire.communicationChannel.normal) : 'Nincs megadva'}</span>
                      <span className="text-[#20A64B] ml-2">{questionnaire.communicationChannel ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400 flex items-center gap-2 mt-1">
                    <FaCheck className="text-[#20A64B] w-4 h-4" />
                    <div>
                      <span>Elérhetőség: {questionnaire.unavailablePeriods ? 'Vannak nem elérhető időszakok' : 'Folyamatosan elérhető'}</span>
                      <span className="text-[#20A64B] ml-2">{!questionnaire.unavailablePeriods ? '5 pont' : '0 pont'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Összpontszám */}
      <div className="mt-8">
        <div className="bg-gray-800 rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FaStar className="text-[#20A64B] w-5 h-5" />
              <span className="text-lg font-medium text-white">Összpontszám</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-3xl font-bold text-[#20A64B]">{projectScore.total}</span>
              <span className="text-gray-400">/ 200 pont</span>
            </div>
          </div>
        </div>
      </div>

      {/* Mentett adatok visszatöltése */}
      <div className="mt-8 text-center">
        <button
          type="button"
          onClick={() => {
            const savedData = localStorage.getItem(STORAGE_KEY);
            if (savedData && onDataRestore) {
              try {
                const parsedData = JSON.parse(savedData);
                onDataRestore(parsedData);
              } catch (error) {
                console.error('Error restoring project data:', error);
              }
            }
          }}
          className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-gray-300 rounded-lg transition-colors"
        >
          Mentett adatok visszatöltése
        </button>
      </div>
    </div>
  );
}; 