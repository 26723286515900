import React, { useEffect } from 'react';
import { FaRegMoneyBillAlt, FaPercentage, FaShieldAlt, FaCreditCard, FaTimes, FaCheck, FaInfoCircle, FaUserShield, FaUsers } from 'react-icons/fa';

interface BudgetStepProps {
  budget: {
    min: number;
    max: number;
    isFlexible: boolean;
    advancePayment: {
      enabled: boolean;
      percentage: number;
    };
    insurance: 'none' | 'basic' | 'premium';
  };
  setBudget: React.Dispatch<React.SetStateAction<{
    min: number;
    max: number;
    isFlexible: boolean;
    advancePayment: {
      enabled: boolean;
      percentage: number;
    };
    insurance: 'none' | 'basic' | 'premium';
  }>>;
  validationErrors: {
    budget?: string;
  };
}

export const BudgetStep: React.FC<BudgetStepProps> = ({
  budget,
  setBudget,
  validationErrors
}) => {
  // Számoljuk ki az előleg összegét
  const calculateAdvanceAmount = () => {
    const avgBudget = (budget.min + budget.max) / 2;
    return Math.round((avgBudget * budget.advancePayment.percentage) / 100);
  };

  // Számoljuk ki a biztosítás összegét
  const calculateInsuranceAmount = () => {
    const avgBudget = (budget.min + budget.max) / 2;
    switch (budget.insurance) {
      case 'basic': return Math.round(avgBudget * 0.1);
      case 'premium': return Math.round(avgBudget * 0.2);
      default: return 0;
    }
  };

  // Számoljuk ki a fizetési ütemezést
  const calculatePaymentSchedule = () => {
    const avgBudget = (budget.min + budget.max) / 2;
    const remainingPercentage = 100 - budget.advancePayment.percentage;
    
    if (avgBudget < 1000000) {
      // 2 részlet: előleg + végösszeg
      return [
        { label: 'Előlegfizetés', percentage: budget.advancePayment.percentage },
        { label: 'Projekt átadásakor', percentage: remainingPercentage }
      ];
    } else if (avgBudget < 5000000) {
      // 3 részlet: előleg + köztes + végösszeg
      const installment = Math.round(remainingPercentage / 2);
      return [
        { label: 'Előlegfizetés', percentage: budget.advancePayment.percentage },
        { label: 'Projekt közepén', percentage: installment },
        { label: 'Projekt átadásakor', percentage: remainingPercentage - installment }
      ];
    } else {
      // 5 részlet: előleg + 3 köztes + végösszeg
      const installment = Math.round(remainingPercentage / 4);
      return [
        { label: 'Előlegfizetés', percentage: budget.advancePayment.percentage },
        { label: 'Projekt 25%-ánál', percentage: installment },
        { label: 'Projekt felénél', percentage: installment },
        { label: 'Projekt 75%-ánál', percentage: installment },
        { label: 'Projekt átadásakor', percentage: remainingPercentage - (installment * 3) }
      ];
    }
  };

  // Számoljuk ki egy részlet összegét
  const calculateInstallmentAmount = (percentage: number) => {
    const avgBudget = (budget.min + budget.max) / 2;
    return Math.round((avgBudget * percentage) / 100);
  };

  // Helper function to get minimum advance payment based on budget
  const getMinimumAdvancePayment = (min: number, max: number) => {
    const avgBudget = (min + max) / 2;
    if (avgBudget < 1000000) return 50;
    if (avgBudget < 5000000) return 30;
    return 20;
  };

  // Effect to update advance payment when budget changes
  useEffect(() => {
    if (budget.advancePayment.enabled) {
      const minAdvance = getMinimumAdvancePayment(budget.min, budget.max);
      if (budget.advancePayment.percentage < minAdvance) {
        setBudget(prev => ({
          ...prev,
          advancePayment: { ...prev.advancePayment, percentage: minAdvance }
        }));
      }
    }
  }, [budget.min, budget.max]);

  return (
    <div className="space-y-8">
      {/* Alap költségvetés szekció */}
      <div className="space-y-6">
        <h3 className="text-lg font-medium text-white mb-4">Költségvetés</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <label htmlFor="min" className="text-sm font-medium text-gray-300">
                Minimum összeg (HUF)
              </label>
              <div className="group relative">
                <FaInfoCircle className="w-4 h-4 text-gray-400 cursor-help" />
                <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-72 p-2 bg-gray-700 text-xs text-gray-300 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                  Adja meg azt a minimum összeget, amit egy minőségi munka elvégzéséért reálisnak tart kifizetni. Ez segít elkerülni az irreálisan alacsony ajánlatokat.
                </div>
              </div>
            </div>
            <div className="relative">
              <input
                type="number"
                value={budget.min}
                onChange={(e) => setBudget(prev => ({ ...prev, min: Number(e.target.value) }))}
                className={`w-full pl-10 pr-4 py-3 rounded-lg border ${
                  validationErrors.budget 
                    ? 'border-red-500 focus:ring-red-500' 
                    : 'border-gray-600 focus:ring-[#20A64B]'
                } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
                placeholder="pl. 500000"
                required
              />
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaRegMoneyBillAlt className="text-gray-400" />
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-2 mb-2">
              <label htmlFor="max" className="text-sm font-medium text-gray-300">
                Maximum összeg (HUF)
              </label>
              <div className="group relative">
                <FaInfoCircle className="w-4 h-4 text-gray-400 cursor-help" />
                <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-72 p-2 bg-gray-700 text-xs text-gray-300 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                  Adja meg a maximum összeget, amit hajlandó lenne kifizetni a projekt megvalósításáért. Ez a felső határ segít a reális ajánlatok szűrésében.
                </div>
              </div>
            </div>
            <div className="relative">
              <input
                type="number"
                value={budget.max}
                onChange={(e) => setBudget(prev => ({ ...prev, max: Number(e.target.value) }))}
                className={`w-full pl-10 pr-4 py-3 rounded-lg border ${
                  validationErrors.budget 
                    ? 'border-red-500 focus:ring-red-500' 
                    : 'border-gray-600 focus:ring-[#20A64B]'
                } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
                placeholder="pl. 1000000"
                required
              />
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaRegMoneyBillAlt className="text-gray-400" />
              </div>
            </div>
          </div>
        </div>

        {budget.min > 0 && budget.max > 0 && (
          <div className="mt-4 p-4 bg-gray-700/50 rounded-lg">
            <div className="flex items-center justify-between">
              <div className="space-y-3">
                <div className="flex items-center gap-2">
                  <FaRegMoneyBillAlt className="text-[#20A64B] w-5 h-5" />
                  <div>
                    <div className="text-white font-medium">
                      Projekt értéke: {Math.round((budget.min + budget.max) / 2).toLocaleString()} Ft
                    </div>
                    <div className="text-sm text-gray-300">
                      Tartomány: {budget.min.toLocaleString()} Ft - {budget.max.toLocaleString()} Ft
                    </div>
                  </div>
                </div>
              </div>
              {((budget.min + budget.max) / 2) < 1000000 ? (
                <span className="text-xs text-yellow-500 bg-yellow-500/10 px-3 py-1 rounded-full">
                  1M Ft alatti projekt
                </span>
              ) : ((budget.min + budget.max) / 2) < 5000000 ? (
                <span className="text-xs text-yellow-500 bg-yellow-500/10 px-3 py-1 rounded-full">
                  1-5M Ft közötti projekt
                </span>
              ) : (
                <span className="text-xs text-yellow-500 bg-yellow-500/10 px-3 py-1 rounded-full">
                  5M Ft feletti projekt
                </span>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Előlegfizetés szekció */}
      <div className="space-y-6">
        <label className="flex items-center gap-2 text-sm font-medium text-gray-300 mb-4">
          <span>Előlegfizetés</span>
          <div className="group relative inline-block">
            <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help" />
            <div className="absolute left-0 top-full mt-2 w-64 p-4 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
              <div className="flex items-center gap-2 mb-2">
                <FaCreditCard className="text-[#20A64B]" />
                <p className="text-sm text-white font-medium">Előlegfizetés</p>
              </div>
              <p className="text-sm text-gray-300">
                Az előleg segít a projekt gyorsabb elindításában és a kivitelező kezdeti költségeinek fedezésében.
                {((budget.min + budget.max) / 2) < 1000000 ? (
                  <span className="block mt-2 text-yellow-500">
                    1 millió Ft alatti projekteknél a magasabb kockázat miatt minimum 50% előleg szükséges a projekt elindításához.
                  </span>
                ) : ((budget.min + budget.max) / 2) < 5000000 ? (
                  <span className="block mt-2 text-yellow-500">
                    1-5 millió Ft közötti projekteknél minimum 30% előleg szükséges a projekt elindításához.
                  </span>
                ) : (
                  <span className="block mt-2 text-yellow-500">
                    5 millió Ft feletti projekteknél minimum 20% előleg szükséges a projekt elindításához.
                  </span>
                )}
              </p>
            </div>
          </div>
        </label>
        <div className="flex items-center gap-4">
          <button
            type="button"
            onClick={() => setBudget(prev => ({ 
              ...prev, 
              advancePayment: { enabled: true, percentage: getMinimumAdvancePayment(prev.min, prev.max) }
            }))}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-all ${
              budget.advancePayment.enabled
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaCheck className={budget.advancePayment.enabled ? 'text-[#20A64B]' : 'text-gray-400'} />
            <span>Igen, fizetnék előleget</span>
          </button>
          <button
            type="button"
            onClick={() => setBudget(prev => ({ 
              ...prev, 
              advancePayment: { enabled: false, percentage: 0 }
            }))}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-all ${
              !budget.advancePayment.enabled
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaTimes className={!budget.advancePayment.enabled ? 'text-[#20A64B]' : 'text-gray-400'} />
            <span>Nem fizetek előleget</span>
          </button>
        </div>

        {budget.advancePayment.enabled && (
          <div className="mt-4 space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Előleg mértéke (%)
              </label>
              <input
                type="range"
                min={getMinimumAdvancePayment(budget.min, budget.max)}
                max="100"
                step="10"
                value={budget.advancePayment.percentage}
                onChange={(e) => setBudget(prev => ({
                  ...prev,
                  advancePayment: { ...prev.advancePayment, percentage: parseInt(e.target.value) }
                }))}
                className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer accent-[#20A64B]"
              />
              <div className="flex justify-between text-sm text-gray-400 mt-1">
                {((budget.min + budget.max) / 2) < 1000000 ? (
                  <>
                    <span>50%</span>
                    <span>60%</span>
                    <span>70%</span>
                    <span>80%</span>
                    <span>90%</span>
                    <span>100%</span>
                  </>
                ) : ((budget.min + budget.max) / 2) < 5000000 ? (
                  <>
                    <span>30%</span>
                    <span>40%</span>
                    <span>50%</span>
                    <span>60%</span>
                    <span>70%</span>
                    <span>80%</span>
                    <span>90%</span>
                    <span>100%</span>
                  </>
                ) : (
                  <>
                    <span>20%</span>
                    <span>30%</span>
                    <span>40%</span>
                    <span>50%</span>
                    <span>60%</span>
                    <span>70%</span>
                    <span>80%</span>
                    <span>90%</span>
                    <span>100%</span>
                  </>
                )}
              </div>
              <div className="text-center mt-2">
                <span className="text-[#20A64B] text-sm font-medium">
                  Választott előleg: {budget.advancePayment.percentage}%
                </span>
              </div>
            </div>
            <div className="text-sm text-gray-400">
              Előleg összege: {calculateAdvanceAmount().toLocaleString()} Ft
            </div>
            
            {/* Fizetési ütemezés */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Bal oldali panel - Kalkuláció */}
              <div className="p-4 bg-gray-700/50 rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <h4 className="text-sm font-medium text-white flex items-center gap-2">
                    <FaRegMoneyBillAlt />
                    Fizetési ütemezés
                  </h4>
                  <div className="group relative">
                    <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help" />
                    <div className="absolute right-0 top-full mt-2 w-80 p-4 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                      <div className="space-y-4 text-sm text-gray-300">
                        <div>
                          <p className="font-medium mb-2">1 millió Ft alatt</p>
                          <p>A projekt két részletben kerül kifizetésre:</p>
                          <ul className="list-disc list-inside mt-1 ml-2 text-gray-400">
                            <li>Előlegfizetés a projekt kezdetén</li>
                            <li>Fennmaradó összeg a projekt átadásakor</li>
                          </ul>
                        </div>
                        <div>
                          <p className="font-medium mb-2">1-5 millió Ft között</p>
                          <p>A projekt három részletben kerül kifizetésre:</p>
                          <ul className="list-disc list-inside mt-1 ml-2 text-gray-400">
                            <li>Előlegfizetés a projekt kezdetén</li>
                            <li>Második részlet a projekt közepén</li>
                            <li>Végső részlet a projekt átadásakor</li>
                          </ul>
                        </div>
                        <div>
                          <p className="font-medium mb-2">5 millió Ft felett</p>
                          <p>A projekt öt részletben kerül kifizetésre:</p>
                          <ul className="list-disc list-inside mt-1 ml-2 text-gray-400">
                            <li>Előlegfizetés a projekt kezdetén</li>
                            <li>Második részlet a projekt 25%-ánál</li>
                            <li>Harmadik részlet a projekt felénél</li>
                            <li>Negyedik részlet a projekt 75%-ánál</li>
                            <li>Végső részlet a projekt átadásakor</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-3">
                  {calculatePaymentSchedule().map((payment, index) => (
                    <div key={index} className="flex items-center justify-between text-sm">
                      <div className="flex items-center gap-2">
                        <div className={`w-6 h-6 rounded-full ${
                          index === 0 
                            ? 'bg-[#20A64B]/20 text-[#20A64B]' 
                            : 'bg-gray-600 text-gray-300'
                        } flex items-center justify-center text-xs`}>
                          {index + 1}
                        </div>
                        <span className="text-gray-300">{payment.label}</span>
                      </div>
                      <span className={index === 0 ? 'text-[#20A64B]' : 'text-gray-300'}>
                        {payment.percentage}%
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Jobb oldali panel - Összegek */}
              <div className="p-4 bg-gray-700/50 rounded-lg">
                <h4 className="text-sm font-medium text-white mb-4 flex items-center gap-2">
                  <FaCreditCard />
                  Fizetendő összegek
                </h4>
                {budget.min > 0 && budget.max > 0 ? (
                  <div className="space-y-3">
                    {calculatePaymentSchedule().map((payment, index) => (
                      <div key={index} className="flex items-center justify-between text-sm">
                        <div className="flex items-center gap-2">
                          <div className={`w-6 h-6 rounded-full ${
                            index === 0 
                              ? 'bg-[#20A64B]/20 text-[#20A64B]' 
                              : 'bg-gray-600 text-gray-300'
                          } flex items-center justify-center text-xs`}>
                            {index + 1}
                          </div>
                          <span className="text-gray-300">{payment.label}</span>
                        </div>
                        <span className={index === 0 ? 'text-[#20A64B]' : 'text-gray-300'}>
                          {calculateInstallmentAmount(payment.percentage).toLocaleString()} Ft
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-sm text-gray-400">
                    Add meg a költségvetést a pontos összegek kiszámításához
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Biztosítás szekció */}
      <div className="space-y-6">
        <label className="flex items-center gap-2 text-sm font-medium text-gray-300 mb-4">
          <span>Projekt biztosítás</span>
          <div className="group relative inline-block">
            <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help" />
            <div className="absolute left-0 top-full mt-2 w-64 p-4 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
              <div className="flex items-center gap-2 mb-2">
                <FaShieldAlt className="text-[#20A64B]" />
                <p className="text-sm text-white font-medium">Projekt biztosítás</p>
              </div>
              <p className="text-sm text-gray-300">
                A biztosítás védelmet nyújt a projekt során felmerülő problémák esetén.
              </p>
            </div>
          </div>
        </label>
        <div className="flex flex-col md:flex-row gap-4">
          <button
            type="button"
            onClick={() => setBudget(prev => ({ ...prev, insurance: 'none' }))}
            className={`flex-1 flex items-center gap-2 px-4 py-3 rounded-lg border transition-all ${
              budget.insurance === 'none'
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaTimes className={budget.insurance === 'none' ? 'text-[#20A64B]' : 'text-gray-400'} />
            <div className="text-left flex items-center gap-2">
              <div className="font-medium">Nem kérek biztosítást</div>
              <div className="group relative inline-block">
                <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help w-4 h-4" />
                <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-48 p-3 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                  <p className="text-sm text-gray-300">Saját felelősségre történő projektmegvalósítás, biztosítási védelem nélkül.</p>
                </div>
              </div>
              <div className="text-sm text-gray-400">0 Ft</div>
            </div>
          </button>
          <button
            type="button"
            onClick={() => setBudget(prev => ({ ...prev, insurance: 'basic' }))}
            className={`flex-1 flex items-center gap-2 px-4 py-3 rounded-lg border transition-all ${
              budget.insurance === 'basic'
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaUserShield className={budget.insurance === 'basic' ? 'text-[#20A64B]' : 'text-gray-400'} />
            <div className="text-left flex items-center gap-2">
              <div className="font-medium">Alap biztosítás</div>
              <div className="group relative inline-block">
                <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help w-4 h-4" />
                <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-3 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                  <p className="text-sm text-gray-300 mb-2">Az alap biztosítási csomag tartalma (projekt érték 10%-a):</p>
                  <ul className="text-sm text-gray-300 space-y-1">
                    <li>• Projekt mentorálás</li>
                    <li>• Alapszintű minőségbiztosítás</li>
                    <li>• Konfliktuskezelés</li>
                  </ul>
                  <p className="text-xs text-gray-400 mt-2 border-t border-gray-600 pt-2">
                    További részletek a Fontos tudnivalók / Biztosítás oldalon
                  </p>
                </div>
              </div>
              <div className="text-sm text-gray-400">
                <p>Projekt érték 10%-a</p>
                {budget.min > 0 && budget.max > 0 && (
                  <p className="font-medium text-[#20A64B]">
                    ~{Math.round((budget.min + budget.max) * 0.1).toLocaleString()} Ft
                  </p>
                )}
              </div>
            </div>
          </button>
          <button
            type="button"
            onClick={() => setBudget(prev => ({ ...prev, insurance: 'premium' }))}
            className={`flex-1 flex items-center gap-2 px-4 py-3 rounded-lg border transition-all ${
              budget.insurance === 'premium'
                ? 'border-[#20A64B] bg-[#20A64B]/10 text-white'
                : 'border-gray-600 hover:border-gray-500 text-gray-300'
            }`}
          >
            <FaUsers className={budget.insurance === 'premium' ? 'text-[#20A64B]' : 'text-gray-400'} />
            <div className="text-left flex items-center gap-2">
              <div className="font-medium">Prémium biztosítás</div>
              <div className="group relative inline-block">
                <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help w-4 h-4" />
                <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-3 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                  <p className="text-sm text-gray-300 mb-2">A prémium biztosítási csomag tartalma (projekt érték 20%-a):</p>
                  <ul className="text-sm text-gray-300 space-y-1">
                    <li>• Minden ami az alap csomagban</li>
                    <li>• Új kivitelező keresése probléma esetén</li>
                    <li>• Szakértői támogatás</li>
                    <li>• Emelt szintű minőségbiztosítás</li>
                    <li>• Code review és technikai tanácsadás</li>
                    <li>• Proaktív kockázatkezelés</li>
                  </ul>
                  <p className="text-xs text-gray-400 mt-2 border-t border-gray-600 pt-2">
                    További részletek a Fontos tudnivalók / Biztosítás oldalon
                  </p>
                </div>
              </div>
              <div className="text-sm text-gray-400">
                <p>Projekt érték 20%-a</p>
                {budget.min > 0 && budget.max > 0 && (
                  <p className="font-medium text-[#20A64B]">
                    ~{Math.round((budget.min + budget.max) * 0.2).toLocaleString()} Ft
                  </p>
                )}
              </div>
            </div>
          </button>
        </div>
        {budget.min > 0 && budget.max > 0 && budget.insurance !== 'none' && (
          <div className="space-y-2 mt-4">
            <div className="text-sm text-gray-400">
              Biztosítás összege: {calculateInsuranceAmount().toLocaleString()} Ft
            </div>
            <div className="text-sm text-yellow-500">
              <FaInfoCircle className="inline-block mr-2" />
              A biztosítás díja ÁFA köteles.
            </div>
          </div>
        )}
      </div>

      {/* Fizetési kötelezettség figyelmeztetés */}
      {budget.min > 0 && budget.max > 0 && budget.advancePayment.enabled && budget.insurance !== 'none' && (
        <div className="mt-8 p-4 bg-yellow-500/10 border border-yellow-500/50 rounded-lg">
          <div className="flex items-start gap-3">
            <FaInfoCircle className="text-yellow-500 mt-1" />
            <div className="text-sm text-yellow-500">
              <p className="font-medium mb-1">Fizetési kötelezettség!</p>
              <p>Az előleg és a biztosítás kiválasztásával azonnali fizetési kötelezettség keletkezik. A projekt csak a díjak befizetése után indul el.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
