import React from 'react';
import { Menu } from '@headlessui/react';
import { 
  FaUserCog, 
  FaEnvelope, 
  FaEllipsisV,
  FaBuilding,
  FaUser,
  FaHardHat,
  FaBriefcase
} from 'react-icons/fa';
import { UserWithStats } from '../../../../types/user';

interface UserListItemProps {
  user: UserWithStats;
  onAction: (userId: string, action: 'view' | 'permissions' | 'suspend') => void;
}

export default function UserListItem({ user, onAction }: UserListItemProps) {
  if (!user) {
    return null;
  }

  // Számoljuk meg a profilok típusait
  const contractorProfiles = user.profiles?.filter(p => p.type === 'contractor').length || 0;
  const clientProfiles = user.profiles?.filter(p => p.type === 'client').length || 0;

  return (
    <div className="bg-gray-700/50 rounded-lg p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 bg-gray-600 rounded-full flex items-center justify-center">
            {user.profile?.userType === 'company' ? (
              <FaBuilding className="w-5 h-5 text-gray-400" />
            ) : (
              <FaUser className="w-5 h-5 text-gray-400" />
            )}
          </div>
          <div>
            <div className="flex items-center gap-2">
              <h3 className="text-white font-medium">
                {user.profile?.contactName || user.name || 'Névtelen felhasználó'}
              </h3>
              {user.isAdmin && (
                <span className="px-2 py-0.5 bg-[#20A64B]/20 text-[#20A64B] text-xs rounded-full">
                  Admin
                </span>
              )}
              {user.profile?.userType === 'company' && (
                <span className="px-2 py-0.5 bg-blue-500/20 text-blue-400 text-xs rounded-full">
                  Cég
                </span>
              )}
              {contractorProfiles > 0 && (
                <span className="px-2 py-0.5 bg-orange-500/20 text-orange-400 text-xs rounded-full flex items-center gap-1">
                  <FaHardHat className="w-3 h-3" />
                  {contractorProfiles}
                </span>
              )}
              {clientProfiles > 0 && (
                <span className="px-2 py-0.5 bg-purple-500/20 text-purple-400 text-xs rounded-full flex items-center gap-1">
                  <FaBriefcase className="w-3 h-3" />
                  {clientProfiles}
                </span>
              )}
            </div>
            <div className="flex items-center gap-3 mt-1">
              <span className="text-gray-400 text-sm flex items-center gap-1">
                <FaEnvelope className="w-3.5 h-3.5" />
                {user.profile?.contactEmail || user.email}
              </span>
              {user.profile?.type && (
                <>
                  <span className="text-gray-400 text-sm">•</span>
                  <span className="text-gray-400 text-sm">
                    {user.profile.type === 'client' ? 'Megrendelő' : 'Kivitelező'}
                  </span>
                </>
              )}
              {user.projectCount !== undefined && user.projectCount > 0 && (
                <>
                  <span className="text-gray-400 text-sm">•</span>
                  <span className="text-gray-400 text-sm">
                    {user.projectCount} projekt
                  </span>
                </>
              )}
              {user.profile?.type === 'contractor' && user.proposalCount !== undefined && user.proposalCount > 0 && (
                <>
                  <span className="text-gray-400 text-sm">•</span>
                  <span className="text-gray-400 text-sm">
                    {user.proposalCount} ajánlat
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <Menu as="div" className="relative">
          <Menu.Button className="p-2 hover:bg-gray-600 rounded-lg transition-colors">
            <FaEllipsisV className="w-5 h-5 text-gray-400" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-lg shadow-lg overflow-hidden z-10">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`w-full px-4 py-2 text-left text-sm ${
                    active ? 'bg-gray-600 text-white' : 'text-gray-300'
                  }`}
                  onClick={() => onAction(user.id, 'view')}
                >
                  Profil megtekintése
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`w-full px-4 py-2 text-left text-sm ${
                    active ? 'bg-gray-600 text-white' : 'text-gray-300'
                  }`}
                  onClick={() => onAction(user.id, 'permissions')}
                >
                  Jogosultságok
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
} 