import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { FaClock, FaExclamationCircle } from 'react-icons/fa';

interface Category {
  id: string;
  name: string;
  description?: string;
  order: number;
  createdAt: Date;
  updatedAt: Date;
}

export default function CategoriesPage() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      
      // Kategóriák lekérése
      const categoriesRef = collection(db, 'categories');
      const categoriesQuery = query(
        categoriesRef,
        orderBy('order', 'asc')
      );
      const categoriesSnap = await getDocs(categoriesQuery);
      
      // Kategóriák adatainak összeállítása
      const categoriesData = categoriesSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Category[];
      
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <FaClock className="w-6 h-6 text-gray-400 animate-spin mx-auto mb-2" />
        <p className="text-gray-400">Kategóriák betöltése...</p>
      </div>
    );
  }

  if (categories.length === 0) {
    return (
      <div className="text-center py-12">
        <FaExclamationCircle className="w-6 h-6 text-gray-400 mx-auto mb-2" />
        <p className="text-gray-400">Nincsenek még kategóriák.</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {categories.map((category) => (
        <div
          key={category.id}
          className="bg-gray-700/50 rounded-lg p-4"
        >
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-lg font-medium text-white">
                {category.name}
              </h3>
              {category.description && (
                <p className="mt-1 text-sm text-gray-400">
                  {category.description}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
} 