import { Link, useLocation } from 'react-router-dom';
import { FaChevronRight, FaHome } from 'react-icons/fa';
import { motion } from 'framer-motion';

const routeNames: { [key: string]: string } = {
  // Admin útvonalak
  admin: 'Admin felület',
  users: 'Felhasználók',
  projects: 'Projektek',
  categories: 'Kategóriák',
  statistics: 'Statisztikák',
  reports: 'Bejelentések',
  tweaks: 'Fejlesztői eszközök',
  
  // Projekt útvonalak
  create: 'Új projekt',
  edit: 'Szerkesztés',
  details: 'Részletek',
  
  // Felhasználói útvonalak
  profile: 'Profil',
  settings: 'Beállítások',
  
  // Kivitelezői útvonalak
  contractors: 'Kivitelezők',
  proposals: 'Ajánlatok',
  browse: 'Böngészés',
  
  // Szerepkörök
  client: 'Megrendelő',
  contractor: 'Kivitelező',

  // Info útvonalak
  info: 'Fontos tudnivalók',
  'minosegi-mutato': 'Minőségi mutató',
  biztositas: 'Biztosítás',

  // Jogi útvonalak
  terms: 'Felhasználási feltételek',
  privacy: 'Adatvédelmi irányelvek',
  gdpr: 'GDPR Szabályzat'
};

export default function Breadcrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  // Dinamikus útvonalnév generálás
  const getRouteName = (name: string): string => {
    if (name.match(/^[A-Za-z0-9]{20}$/)) { // Firebase ID formátum
      if (location.pathname.includes('/contractors/')) {
        return 'Kivitelező profil';
      }
      return 'Projekt részletek';
    }
    return typeof routeNames[name] === 'function' 
      ? (routeNames[name] as (id: string) => string)(name)
      : (routeNames[name] as string) || name;
  };

  // Ne jelenítse meg a breadcrumb-ot a főoldalon és auth oldalakon
  if (location.pathname === '/' || 
      location.pathname === '/login' || 
      location.pathname === '/register') {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gray-800 border-b border-gray-700"
    >
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto py-3">
          <nav className="flex items-center text-sm">
            <Link
              to="/"
              className="text-gray-400 hover:text-white transition-colors flex items-center gap-2"
            >
              <FaHome className="w-4 h-4" />
              <span className="hidden sm:inline">Kezdőlap</span>
            </Link>

            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;

              return (
                <div key={name} className="flex items-center">
                  <FaChevronRight className="mx-2 text-gray-600" />
                  {isLast ? (
                    <span className="text-gray-300">
                      {getRouteName(name)}
                    </span>
                  ) : (
                    <Link
                      to={routeTo}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {getRouteName(name)}
                    </Link>
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </motion.div>
  );
} 