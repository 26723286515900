import { Project, ProjectBudget, ProjectTimeline, ProjectRequirement, ProjectAttachment } from '../types/project';

interface ProjectScore {
  total: number;
  breakdown: {
    basicInfo: number;
    documentation: number;
    questionnaire: number;
    requirements: number;
    budget: number;
    timeline: number;
  };
  missingPoints: {
    description: string;
    potentialPoints: number;
  }[];
}

interface Budget {
  min: number;
  max: number;
  advancePayment: {
    enabled: boolean;
    percentage: number;
  };
  insurance: 'none' | 'basic' | 'premium';
}

export const calculateProjectScore = (project: Partial<Project>): ProjectScore => {
  const score: ProjectScore = {
    total: 0,
    breakdown: {
      basicInfo: 0,
      documentation: 0,
      questionnaire: 0,
      requirements: 0,
      budget: 0,
      timeline: 0
    },
    missingPoints: []
  };

  // Alapinformációk (40 pont)
  if (project.description) {
    const descLength = project.description.length;
    if (descLength > 500) score.breakdown.basicInfo += 20;
    else if (descLength > 200) score.breakdown.basicInfo += 10;
    else if (descLength > 100) score.breakdown.basicInfo += 4;
    else score.breakdown.basicInfo += 2;
  } else {
    score.missingPoints.push({ description: 'Projekt leírás megadása', potentialPoints: 20 });
  }

  // Kategória és alkategória pontok
  if (project.category && project.category !== '') {
    score.breakdown.basicInfo += 10;
    if (project.subcategory && project.subcategory !== '') {
      score.breakdown.basicInfo += 5;
    } else {
      score.missingPoints.push({ description: 'Alkategória megadása', potentialPoints: 5 });
    }
  } else {
    score.missingPoints.push({ description: 'Kategória megadása', potentialPoints: 10 });
  }

  if (project.scope) score.breakdown.basicInfo += 5;

  // Dokumentáció (40 pont)
  if (project.attachments && project.attachments.length > 0) {
    const hasTechnicalDoc = project.attachments.some(
      doc => doc.documentType === 'technical_spec' || doc.documentType === 'design_doc'
    );
    const hasReference = project.attachments.some(
      doc => doc.documentType === 'reference'
    );

    if (hasTechnicalDoc) score.breakdown.documentation += 40;
    else if (hasReference) score.breakdown.documentation += 20;

    if (!hasTechnicalDoc) {
      score.missingPoints.push({ 
        description: 'Műszaki dokumentáció vagy tervrajz csatolása', 
        potentialPoints: 40 
      });
    }
  } else {
    score.missingPoints.push({ 
      description: 'Dokumentumok csatolása (műszaki dokumentáció és referencia)', 
      potentialPoints: 40 
    });
  }

  // Költségvetés (60 pont)
  if (project.budget) {
    if (project.budget.min > 0 && project.budget.max > 0) {
      score.breakdown.budget = 10;
    }
    
    // Előlegfizetés (20 pont)
    if (project.budget?.advancePayment?.enabled) {
      const minAdvance = getMinimumAdvancePayment(project.budget.min, project.budget.max);
      score.breakdown.budget += 10;  // Alap 10 pont az előleg engedélyezéséért
      const extraPercentage = project.budget.advancePayment.percentage - minAdvance;
      const extraScore = Math.min(10, Math.floor(extraPercentage / 10) * 2);  // Extra pontok a minimum feletti százalékért
      score.breakdown.budget += extraScore;
    }

    // Biztosítás (max 30 pont)
    if (project.budget?.insurance) {
      switch (project.budget.insurance) {
        case 'premium':
          score.breakdown.budget += 30;
          break;
        case 'basic':
          score.breakdown.budget += 15;
          break;
        default:
          score.missingPoints.push({ description: 'Projekt biztosítás választása', potentialPoints: 30 });
      }
    }
  }

  // Követelmények és időzítés (20 pont)
  if (project.timeline) {
    // Időzítés pontok (max 10 pont)
    if (project.timeline.expectedStartDate) score.breakdown.timeline += 5;
    if (project.timeline.deadline) score.breakdown.timeline += 5;
  } else {
    score.missingPoints.push({ description: 'Időzítés megadása', potentialPoints: 10 });
  }

  if (project.requirements && project.requirements.length > 0) {
    // Minden követelmény 2 pontot ér, maximum 10 pontig
    const reqScore = Math.min(10, project.requirements.length * 2);
    score.breakdown.requirements = reqScore;
  } else {
    score.missingPoints.push({ description: 'Követelmények megadása', potentialPoints: 10 });
  }

  // Kérdőív (40 pont)
  if (project.questionnaire) {
    // Döntéshozatal és együttműködés (20 pont)
    if (project.questionnaire.decisionTime) score.breakdown.questionnaire += 5;
    if (project.questionnaire.meetingAvailability) score.breakdown.questionnaire += 5;
    if (project.questionnaire.unavailablePeriods !== undefined) score.breakdown.questionnaire += 5;
    if (project.questionnaire.urgentDecisionTime) score.breakdown.questionnaire += 5;

    // Kommunikáció (20 pont)
    if (project.questionnaire.urgentResponseTime) score.breakdown.questionnaire += 5;
    if (project.questionnaire.updateFrequency) score.breakdown.questionnaire += 5;
    if (project.questionnaire.communicationChannel?.urgent) score.breakdown.questionnaire += 5;
    if (project.questionnaire.communicationChannel?.normal) score.breakdown.questionnaire += 5;
  } else {
    score.missingPoints.push({ description: 'Kérdőív kitöltése', potentialPoints: 40 });
  }

  // Végső pontszám kiszámítása
  score.total = Math.min(200,
    score.breakdown.basicInfo + 
    score.breakdown.documentation + 
    score.breakdown.requirements + 
    score.breakdown.questionnaire +
    score.breakdown.budget +
    score.breakdown.timeline
  );

  return score;
};

export const getProjectBadge = (score: number): {
  label: string;
  color: string;
} => {
  if (score >= 160) return { label: 'Kiemelt Projekt', color: 'emerald' };
  if (score >= 120) return { label: 'Részletes Projekt', color: 'blue' };
  if (score >= 80) return { label: 'Alap Projekt', color: 'yellow' };
  return { label: 'Hiányos Projekt', color: 'red' };
};

export const getMinimumAdvancePayment = (min: number, max: number) => {
  const avgBudget = (min + max) / 2;
  if (avgBudget < 1000000) return 50;
  if (avgBudget < 5000000) return 30;
  return 20;
};

export const calculateAdvancePaymentScore = (budget?: Budget) => {
  if (!budget || !budget.advancePayment || !budget.advancePayment.enabled) return 0;
  
  const minAdvance = getMinimumAdvancePayment(budget.min, budget.max);
  const baseScore = 10;  // Alap 10 pont az előleg engedélyezéséért
  const extraPercentage = budget.advancePayment.percentage - minAdvance;
  const extraScore = Math.min(10, Math.floor(extraPercentage / 10) * 2);  // Maximum 10 extra pont
  
  return baseScore + extraScore;  // Maximum 20 pont összesen
};

export const getInsuranceScore = (insurance: 'none' | 'basic' | 'premium') => {
  switch (insurance) {
    case 'basic': return 15;
    case 'premium': return 30;
    default: return 0;
  }
};

export const calculateTotalProjectScore = (budget: Budget) => {
  return calculateAdvancePaymentScore(budget) + getInsuranceScore(budget.insurance);
}; 