import React from 'react';
import { Dialog } from '@headlessui/react';
import { FaBuilding, FaUser, FaHardHat, FaBriefcase, FaEnvelope, FaPhone, FaGlobe } from 'react-icons/fa';
import { UserWithStats } from '../../../../types/user';

interface UserProfileModalProps {
  user: UserWithStats | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function UserProfileModal({ user, isOpen, onClose }: UserProfileModalProps) {
  if (!user) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-3xl w-full bg-gray-800 rounded-xl shadow-xl">
          <div className="p-6">
            <div className="flex items-start justify-between">
              <div>
                <Dialog.Title className="text-xl font-semibold text-white mb-1">
                  {user.name}
                </Dialog.Title>
                <p className="text-gray-400 text-sm flex items-center gap-2">
                  <FaEnvelope className="w-4 h-4" />
                  {user.email}
                </p>
              </div>
              {user.isAdmin && (
                <span className="px-2 py-0.5 bg-[#20A64B]/20 text-[#20A64B] text-xs rounded-full">
                  Admin
                </span>
              )}
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-medium text-white mb-4">Profilok</h3>
              <div className="space-y-4">
                {user.profiles?.map((profile) => (
                  <div 
                    key={profile.id} 
                    className="bg-gray-700/50 rounded-lg p-4"
                  >
                    <div className="flex items-center gap-2 mb-2">
                      {profile.userType === 'company' ? (
                        <FaBuilding className="w-5 h-5 text-gray-400" />
                      ) : (
                        <FaUser className="w-5 h-5 text-gray-400" />
                      )}
                      <h4 className="text-white font-medium">
                        {profile.contactName}
                      </h4>
                      <span className={`px-2 py-0.5 text-xs rounded-full flex items-center gap-1
                        ${profile.type === 'contractor' 
                          ? 'bg-orange-500/20 text-orange-400' 
                          : 'bg-purple-500/20 text-purple-400'
                        }`}
                      >
                        {profile.type === 'contractor' ? (
                          <>
                            <FaHardHat className="w-3 h-3" />
                            Kivitelező
                          </>
                        ) : (
                          <>
                            <FaBriefcase className="w-3 h-3" />
                            Megrendelő
                          </>
                        )}
                      </span>
                      {profile.userType === 'company' && (
                        <span className="px-2 py-0.5 bg-blue-500/20 text-blue-400 text-xs rounded-full">
                          Cég
                        </span>
                      )}
                    </div>
                    
                    <div className="space-y-2 text-sm text-gray-400">
                      <div className="flex items-center gap-2">
                        <FaEnvelope className="w-4 h-4" />
                        {profile.contactEmail}
                      </div>
                      {profile.phone && (
                        <div className="flex items-center gap-2">
                          <FaPhone className="w-4 h-4" />
                          {profile.phone}
                        </div>
                      )}
                      {profile.website && (
                        <div className="flex items-center gap-2">
                          <FaGlobe className="w-4 h-4" />
                          <a 
                            href={profile.website} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-400 hover:text-blue-300"
                          >
                            {profile.website}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors"
              >
                Bezárás
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
} 