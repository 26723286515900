import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { 
  FaUsers, 
  FaProjectDiagram, 
  FaTags, 
  FaChartBar, 
  FaFlag,
  FaUserShield,
  FaUserTimes,
  FaTools
} from 'react-icons/fa';
import { IconType } from 'react-icons';

interface AdminTab {
  id: string;
  label: string;
  icon: IconType;
  href: string;
}

const ADMIN_TABS: AdminTab[] = [
  { id: 'users', label: 'Felhasználók', icon: FaUsers, href: '/admin/users' },
  { id: 'projects', label: 'Projektek', icon: FaProjectDiagram, href: '/admin/projects' },
  { id: 'categories', label: 'Kategóriák', icon: FaTags, href: '/admin/categories' },
  { id: 'statistics', label: 'Statisztikák', icon: FaChartBar, href: '/admin/statistics' },
  { id: 'reports', label: 'Bejelentések', icon: FaFlag, href: '/admin/reports' },
  { id: 'tweaks', label: 'Fejlesztői eszközök', icon: FaTools, href: '/admin/tweaks' }
];

export default function AdminLayout() {
  const { user } = useAuth();
  const location = useLocation();

  if (!user?.isAdmin) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="text-center py-12">
              <FaUserTimes className="w-16 h-16 text-red-500 mx-auto mb-4" />
              <h1 className="text-2xl font-bold text-white mb-2">
                Nincs jogosultságod az admin felület megtekintéséhez!
              </h1>
              <p className="text-gray-400">
                Ez az oldal csak adminisztrátorok számára érhető el.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-900">
      {/* Oldalsáv */}
      <div className="w-64 bg-gray-800 border-r border-gray-700 rounded-l-2xl">
        <div className="flex flex-col h-full">
          <div className="flex-1 flex flex-col overflow-y-auto">
            <div className="px-4 py-3 bg-gray-900/50 border-b border-gray-700 rounded-tl-2xl">
              <h1 className="text-lg font-bold text-white flex items-center gap-2">
                <FaUserShield className="text-[#20A64B]" />
                Admin Panel
              </h1>
            </div>
            <nav className="flex-1 px-2 py-4 space-y-1">
              {ADMIN_TABS.map((item) => {
                const isActive = location.pathname === item.href;
                const Icon = item.icon;
                
                return (
                  <Link
                    key={item.id}
                    to={item.href}
                    className={`
                      group flex items-center px-4 py-3 text-sm font-medium rounded-lg
                      ${isActive 
                        ? 'bg-gray-900 text-white' 
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                      }
                    `}
                  >
                    <Icon 
                      className={`
                        mr-3 h-5 w-5 
                        ${isActive ? 'text-[#20A64B]' : 'text-gray-400 group-hover:text-gray-300'}
                      `}
                    />
                    {item.label}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </div>

      {/* Fő tartalom */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto bg-gray-900 rounded-tr-2xl rounded-br-2xl">
          {/* Header section */}
          {location.pathname !== '/admin' && (
            <div className="bg-gray-800 p-6 border-b border-gray-700">
              <div className="flex items-center gap-3">
                <div className="w-16 h-16 rounded-xl bg-green-500/10 flex items-center justify-center">
                  {(() => {
                    const currentTab = ADMIN_TABS.find(tab => location.pathname.startsWith(tab.href));
                    return currentTab?.icon ? <currentTab.icon className="w-10 h-10 text-green-500" /> : null;
                  })()}
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-white">
                    {ADMIN_TABS.find(tab => location.pathname.startsWith(tab.href))?.label}
                  </h1>
                  <p className="text-sm text-gray-400 mt-1">
                    {getTabDescription(ADMIN_TABS.find(tab => location.pathname.startsWith(tab.href))?.id || '')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* Content */}
          <div className="p-6">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

function getTabDescription(tabId: string): string {
  switch (tabId) {
    case 'users':
      return 'Felhasználók kezelése, jogosultságok beállítása';
    case 'projects':
      return 'Projektek áttekintése és kezelése';
    case 'categories':
      return 'Projekt kategóriák és címkék kezelése';
    case 'statistics':
      return 'Rendszerhasználati statisztikák és elemzések';
    case 'reports':
      return 'Bejelentett problémák és visszajelzések kezelése';
    case 'tweaks':
      return 'Fejlesztői beállítások és rendszerparaméterek';
    default:
      return '';
  }
} 