import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { doc, getDoc, deleteDoc, updateDoc, Timestamp, collection, query, where, getDocs } from 'firebase/firestore';
import { Project, ProjectCategory, ProjectQuestionnaire, ProjectBudget, ProjectTimeline, ProjectRequirement, ProjectAttachment, ProjectDetails as ProjectDetailsType } from '../../types/project';
import { FaClock, FaCheckCircle, FaExclamationCircle, FaFile, FaEdit, FaTrash, FaEllipsisV, FaTag, FaDownload, FaLock } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { Menu } from '@headlessui/react';
import { CATEGORIES } from '../../constants/projectCategories';
import { calculateProjectScore, getProjectBadge } from '../../utils/projectScoring';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Stripe promise
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

// Firebase functions URL
const FUNCTIONS_URL = "https://us-central1-webtender-98dcc.cloudfunctions.net";

// Típus definíciók
type ProjectStatus = 'draft' | 'active' | 'completed' | 'cancelled';
type DocumentType = 'project_description' | 'technical_spec' | 'design_doc' | 'reference' | 'legal' | 'other';

interface ProjectData extends Omit<Project, 'createdAt' | 'updatedAt' | 'attachments'> {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  attachments?: {
    name: string;
    url: string;
    type: string;
    size: number;
    documentType: DocumentType;
  }[];
  insurancePaid?: boolean;
  paymentStatus?: 'pending' | 'completed' | 'expired';
  paymentSessionId?: string;
  paymentCompletedAt?: Timestamp;
}

// Segédfüggvény a timestamp konvertálásához
const formatTimestamp = (timestamp: any) => {
  if (!timestamp) return '';
  
  // Ha Firestore Timestamp objektum
  if (timestamp?.toDate) {
    return timestamp.toDate().toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  }
  
  // Ha seconds property-vel rendelkező objektum
  if (timestamp?.seconds) {
    return new Date(timestamp.seconds * 1000).toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  }
  
  // Ha már Date objektum
  if (timestamp instanceof Date) {
    return timestamp.toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  }
  
  return '';
};

// Státusz opciók
const STATUS_OPTIONS = [
  { value: 'draft', label: 'Vázlat', color: 'text-gray-400' },
  { value: 'active', label: 'Aktív', color: 'text-blue-400' },
  { value: 'completed', label: 'Befejezett', color: 'text-green-400' },
  { value: 'cancelled', label: 'Törölt', color: 'text-red-400' }
] as const;

// Add document type options at the top
const documentTypeOptions: { value: DocumentType; label: string }[] = [
  { value: 'project_description', label: 'Projekt leírás' },
  { value: 'technical_spec', label: 'Műszaki specifikáció' },
  { value: 'design_doc', label: 'Tervrajz, dizájn dokumentum' },
  { value: 'reference', label: 'Referencia anyag' },
  { value: 'legal', label: 'Jogi dokumentum' },
  { value: 'other', label: 'Egyéb' }
] as const;

interface ProjectScore {
  total: number;
  breakdown: {
    basicInfo: number;
    documentation: number;
    questionnaire: number;
    requirements: number;
  };
  missingPoints: {
    description: string;
    potentialPoints: number;
  }[];
}

// Biztosítási díj számítása
const calculateInsuranceFee = (budget: ProjectBudget) => {
  const projectValue = (budget.min + budget.max) / 2;
  return budget.insurance === 'premium' ? projectValue * 0.2 : projectValue * 0.1;
};

export default function ProjectDetails() {
  const { id } = useParams();
  const { user, activeProfile } = useAuth();
  const navigate = useNavigate();
  const [project, setProject] = useState<ProjectData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (!id || !user) return;

      try {
        setLoading(true);
        const projectRef = doc(db, 'projects', id);
        const projectSnap = await getDoc(projectRef);

        if (!projectSnap.exists()) {
          setError('A projekt nem található');
          return;
        }

        const projectData = { id: projectSnap.id, ...projectSnap.data() } as ProjectData;

        // Ellenőrizzük a hozzáférési jogosultságot
        if (activeProfile?.type === 'client') {
          // Megrendelő csak a saját projektjeit láthatja
          if (projectData.clientId !== user.uid) {
            setError('Nincs jogosultsága a projekt megtekintéséhez');
            return;
          }
        } else if (activeProfile?.type === 'contractor') {
          // Kivitelező csak azokat a projekteket láthatja, amelyekre elfogadott ajánlata van
          const proposalsRef = collection(db, 'proposals');
          const proposalsQuery = query(
            proposalsRef,
            where('contractorId', '==', user.uid),
            where('projectId', '==', id),
            where('status', '==', 'accepted')
          );
          const proposalsSnapshot = await getDocs(proposalsQuery);

          if (proposalsSnapshot.empty) {
            setError('Nincs jogosultsága a projekt megtekintéséhez');
            return;
          }
        }

        setProject(projectData);
      } catch (err) {
        console.error('Error fetching project:', err);
        setError('Hiba történt a projekt betöltése során');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id, user, activeProfile]);

  // Helper a projekt URL-ek generálásához
  const getProjectUrl = (projectId: string, action?: string) => {
    const base = activeProfile?.type === 'client' ? '/client/projects' : '/contractor/projects';
    return action ? `${base}/${projectId}/${action}` : `${base}/${projectId}`;
  };

  // Projekt törlése
  const handleDelete = async () => {
    if (!window.confirm('Biztosan törölni szeretné a projektet?')) return;
    
    try {
      await deleteDoc(doc(db, 'projects', id!));
      toast.success('Projekt sikeresen törölve!');
      navigate(activeProfile?.type === 'client' ? '/client/projects' : '/contractor/projects');
    } catch (err) {
      console.error('Error deleting project:', err);
      toast.error('Hiba történt a projekt törlése során');
    }
  };

  // Státusz módosítás kezelése
  const handleStatusChange = async (newStatus: ProjectStatus) => {
    if (!id) return;
    
    try {
      // Ha publikálni szeretnénk és prémium biztosítás van kiválasztva
      if (newStatus === 'active' && project?.budget?.insurance === 'premium') {
        const projectRef = doc(db, 'projects', id);
        const projectSnap = await getDoc(projectRef);
        const projectData = projectSnap.data();

        if (!projectData?.insurancePaid) {
          const insuranceFee = calculateInsuranceFee(project.budget);
          
          try {
            console.log('User auth state:', { uid: user?.uid, isLoggedIn: !!user });
            
            const response = await fetch(`${FUNCTIONS_URL}/createStripeCheckoutSession`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': user?.uid || '',
              },
              body: JSON.stringify({
                projectId: id,
                amount: insuranceFee,
                currency: project.budget.currency || 'HUF'
              })
            });

            if (!response.ok) {
              const errorData = await response.text();
              throw new Error(`Payment session creation failed: ${errorData}`);
            }

            const { sessionId } = await response.json();
            
            const stripe = await stripePromise;
            if (stripe) {
              const { error } = await stripe.redirectToCheckout({
                sessionId
              });
              
              if (error) {
                console.error('Stripe redirect error:', error);
                toast.error('Hiba történt a fizetési oldal betöltése során');
                return;
              }
            }
          } catch (error) {
            console.error('Payment session creation error:', error);
            toast.error('Hiba történt a fizetési folyamat során');
          }
          return;
        }
      }

      // Ha nem prémium biztosítás vagy már ki van fizetve
      await updateDoc(doc(db, 'projects', id), {
        status: newStatus,
        updatedAt: new Date()
      });
      
      setProject(prev => prev ? { ...prev, status: newStatus } : null);
      toast.success('Státusz sikeresen módosítva!');
      
      if (newStatus === 'cancelled') {
        navigate(activeProfile?.type === 'client' ? '/client/projects' : '/contractor/projects');
      }
    } catch (error) {
      console.error('Error updating project status:', error);
      toast.error('Hiba történt a státusz módosítása során');
    }
  };

  if (loading) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B]"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !project) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="text-center py-12">
              <p className="text-red-400">{error || 'A projekt nem található'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="bg-gray-800 rounded-lg p-6">
          {/* Projekt fejléc */}
          <div className="flex justify-between items-start mb-8">
            <div>
              <h1 className="text-2xl font-bold text-white mb-2">{project.title}</h1>
              <div className="flex items-center gap-2 text-gray-400">
                <span className="flex items-center gap-1">
                  {project.status === 'draft' && <FaClock className="text-gray-400" />}
                  {project.status === 'active' && <FaCheckCircle className="text-blue-400" />}
                  {project.status === 'completed' && <FaCheckCircle className="text-green-400" />}
                  {project.status === 'cancelled' && <FaExclamationCircle className="text-red-400" />}
                  {project.status === 'draft' && 'Vázlat'}
                  {project.status === 'active' && 'Aktív'}
                  {project.status === 'completed' && 'Befejezett'}
                  {project.status === 'cancelled' && 'Törölt'}
                </span>
                <span>•</span>
                <span className="flex items-center gap-1">
                  <FaTag className="w-4 h-4" />
                  {CATEGORIES.find(c => c.value === project.category)?.label || project.category}
                </span>
                <span>•</span>
                <span>Létrehozva: {formatTimestamp(project.createdAt)}</span>
              </div>
            </div>
            <div className="flex flex-col items-end gap-4">
              <div className="text-right">
                <div className="text-[#20A64B] font-semibold">
                  {project.budget.min.toLocaleString()} - {project.budget.max.toLocaleString()} Ft
                </div>
                <div className="text-sm text-gray-400">
                  {project.budget.isFlexible ? 'Rugalmas költségvetés' : 'Fix költségvetés'}
                </div>
              </div>
              
              {/* Műveletek */}
              <div className="flex items-center gap-3">
                {/* Státusz módosító dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="flex items-center gap-2 px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors">
                    <span className={`w-2 h-2 rounded-full ${
                      project.status === 'draft' ? 'bg-gray-400' :
                      project.status === 'active' ? 'bg-blue-400' :
                      project.status === 'completed' ? 'bg-green-400' :
                      'bg-red-400'
                    }`} />
                    <span>{STATUS_OPTIONS.find(s => s.value === project.status)?.label}</span>
                    <FaEllipsisV className="w-4 h-4 text-gray-400" />
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-lg shadow-lg overflow-hidden z-10">
                    {STATUS_OPTIONS.map((status) => (
                      <Menu.Item key={status.value}>
                        {({ active }) => (
                          <button
                            onClick={() => handleStatusChange(status.value)}
                            className={`w-full px-4 py-2 text-left flex items-center gap-2 ${
                              active ? 'bg-gray-600' : ''
                            }`}
                          >
                            <span className={`w-2 h-2 rounded-full ${
                              status.value === 'draft' ? 'bg-gray-400' :
                              status.value === 'active' ? 'bg-blue-400' :
                              status.value === 'completed' ? 'bg-green-400' :
                              'bg-red-400'
                            }`} />
                            <span className={status.color}>{status.label}</span>
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>

                {/* Szerkesztés gomb csak draft státusznál */}
                {project.status === 'draft' && (
                  <button
                    onClick={() => navigate(getProjectUrl(id!, 'edit'))}
                    className="flex items-center gap-2 px-4 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors"
                  >
                    <FaEdit className="w-4 h-4" />
                    <span>Szerkesztés</span>
                  </button>
                )}
                
                {/* Törlés gomb csak draft státusznál */}
                {project.status === 'draft' && (
                  <button
                    onClick={handleDelete}
                    className="flex items-center gap-2 px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors"
                  >
                    <FaTrash className="w-4 h-4" />
                    <span>Törlés</span>
                  </button>
                )}

                {project.status === 'draft' && (
                  <div className="flex items-center gap-2">
                    {project.budget?.insurance === 'premium' && !project.insurancePaid && (
                      <div className="bg-yellow-500/10 text-yellow-400 px-3 py-1 rounded-lg flex items-center gap-2">
                        <FaLock className="w-4 h-4" />
                        <span className="text-sm">Biztosítási díj fizetése szükséges</span>
                      </div>
                    )}
                    <button
                      onClick={() => handleStatusChange('active')}
                      className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                        project.budget?.insurance === 'premium' && !project.insurancePaid
                          ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                          : 'bg-[#20A64B] text-white hover:bg-[#1a8b3e]'
                      }`}
                      disabled={project.budget?.insurance === 'premium' && !project.insurancePaid}
                    >
                      <FaCheckCircle className="w-4 h-4" />
                      Projekt publikálása
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Projekt részletek */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Bal oldali fő információk */}
            <div className="lg:col-span-2 space-y-6">
              <div className="bg-gray-700 rounded-lg p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Projekt leírása</h2>
                <p className="text-gray-300 whitespace-pre-wrap">{project.description}</p>
              </div>

              {project.requirements && project.requirements.length > 0 && (
                <div className="bg-gray-700 rounded-lg p-6">
                  <h2 className="text-lg font-semibold text-white mb-4">Követelmények</h2>
                  <div className="space-y-3">
                    {project.requirements.map((req, index) => (
                      <div key={index} className="flex items-start gap-2">
                        <span className={`px-2 py-1 rounded text-xs ${
                          req.priority === 'must' 
                            ? 'bg-red-500/20 text-red-400'
                            : req.priority === 'should'
                            ? 'bg-yellow-500/20 text-yellow-400'
                            : 'bg-blue-500/20 text-blue-400'
                        }`}>
                          {req.priority === 'must' ? 'Must have' : req.priority === 'should' ? 'Should have' : 'Could have'}
                        </span>
                        <span className="text-gray-300">{req.description}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Csatolmányok */}
              {project.attachments && project.attachments.length > 0 && (
                <div className="mt-8">
                  <h2 className="text-lg font-medium text-white mb-4">Csatolmányok</h2>
                  <div className="grid grid-cols-1 gap-3">
                    {project.attachments.map((file, index) => (
                      <div key={index} className="flex items-center justify-between p-3 bg-gray-700/50 rounded-lg">
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center space-x-2">
                            <FaFile className="text-[#20A64B]" />
                            <a 
                              href={file.url} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="text-sm text-white hover:text-[#20A64B] transition-colors"
                            >
                              {file.name}
                            </a>
                            {file.size > 0 && (
                              <span className="text-xs text-gray-400">
                                ({(file.size / 1024 / 1024).toFixed(2)} MB)
                              </span>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="text-sm text-gray-400">
                              {documentTypeOptions.find(opt => opt.value === file.documentType)?.label || 'Egyéb'}
                            </span>
                          </div>
                        </div>
                        <a 
                          href={file.url}
                          download
                          className="p-2 text-gray-400 hover:text-[#20A64B] transition-colors"
                        >
                          <FaDownload className="w-4 h-4" />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Project Score Panel */}
              <div className="bg-gray-700 rounded-lg p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Projekt Pontszám</h2>
                {project && (
                  <>
                    {(() => {
                      const projectForScoring: Partial<Project> = {
                        ...project,
                        createdAt: project.createdAt.toDate(),
                        updatedAt: project.updatedAt.toDate(),
                        budget: project.budget as ProjectBudget,
                        timeline: project.timeline as ProjectTimeline,
                        requirements: project.requirements as ProjectRequirement[],
                        questionnaire: project.questionnaire as ProjectQuestionnaire,
                        details: project.details as ProjectDetailsType,
                        attachments: project.attachments as ProjectAttachment[]
                      };
                      
                      const score = calculateProjectScore(projectForScoring);
                      const badge = getProjectBadge(score.total);
                      
                      return (
                        <div className="space-y-6">
                          <div className="flex items-center justify-between">
                            <span className="text-2xl font-bold text-[#20A64B]">{score.total}/200 pont</span>
                            {badge.label === 'Kiemelt Projekt' ? (
                              <div className="flex items-center gap-2 px-3 py-1.5 bg-gradient-to-r from-emerald-500/20 to-[#20A64B]/20 border border-emerald-500/20 rounded-full">
                                <span className="w-2 h-2 rounded-full bg-emerald-500 animate-pulse"></span>
                                <span className="text-sm font-medium bg-gradient-to-r from-emerald-400 to-[#20A64B] bg-clip-text text-transparent">
                                  {badge.label}
                                </span>
                              </div>
                            ) : (
                              <span className={`px-3 py-1 rounded-full text-sm font-medium 
                                ${badge.color === 'blue' ? 'bg-blue-500/20 text-blue-400' : 
                                  badge.color === 'yellow' ? 'bg-yellow-500/20 text-yellow-400' : 
                                  'bg-red-500/20 text-red-400'}`}>
                                {badge.label}
                              </span>
                            )}
                          </div>
                          
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                              <h3 className="text-sm font-medium text-[#20A64B] mb-3">Részletes pontszámok</h3>
                              <ul className="space-y-3">
                                <li className="flex justify-between">
                                  <span className="text-gray-400">Alapinformációk:</span>
                                  <span className="text-white">{score.breakdown.basicInfo} pont</span>
                                </li>
                                <li className="flex justify-between">
                                  <span className="text-gray-400">Dokumentáció:</span>
                                  <span className="text-white">{score.breakdown.documentation} pont</span>
                                </li>
                                <li className="flex justify-between">
                                  <span className="text-gray-400">Időzítés:</span>
                                  <span className="text-white">{score.breakdown.timeline} pont</span>
                                </li>
                                <li className="flex justify-between">
                                  <span className="text-gray-400">Követelmények:</span>
                                  <span className="text-white">{score.breakdown.requirements} pont</span>
                                </li>
                                <li className="flex justify-between">
                                  <span className="text-gray-400">Költségvetés:</span>
                                  <span className="text-white">{score.breakdown.budget} pont</span>
                                </li>
                                <li className="flex justify-between">
                                  <span className="text-gray-400">Kérdőív:</span>
                                  <span className="text-white">{score.breakdown.questionnaire} pont</span>
                                </li>
                              </ul>
                            </div>
                            
                            {score.missingPoints.length > 0 && (
                              <div>
                                <h3 className="text-sm font-medium text-[#20A64B] mb-3">Tippek a pontszám növeléséhez</h3>
                                <ul className="space-y-3">
                                  {score.missingPoints.map((item, index) => (
                                    <li key={index} className="flex justify-between">
                                      <span className="text-gray-400">{item.description}:</span>
                                      <span className="text-white">+{item.potentialPoints} pont</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })()}
                  </>
                )}
              </div>
            </div>

            {/* Jobb oldali információk */}
            <div className="space-y-6">
              {/* Pénzügyek panel */}
              <div className="bg-gray-700 rounded-lg p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Pénzügyek</h2>
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-gray-400">Költségvetés:</span>
                      <span className="text-white font-medium">
                        {project.budget.min.toLocaleString()} - {project.budget.max.toLocaleString()} Ft
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-400">Rugalmasság:</span>
                      <span className="text-white">
                        {project.budget.isFlexible ? 'Rugalmas' : 'Fix'}
                      </span>
                    </div>
                  </div>

                  {/* Előlegfizetés */}
                  <div className="pt-4 border-t border-gray-600">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-gray-400">Előlegfizetés:</span>
                      <span className="text-white">
                        {project.budget.advancePayment?.enabled ? 'Igen' : 'Nem'}
                      </span>
                    </div>
                    {project.budget.advancePayment?.enabled && (
                      <div className="flex justify-between items-center">
                        <span className="text-gray-400">Előleg mértéke:</span>
                        <span className="text-white">
                          {project.budget.advancePayment.percentage}%
                        </span>
                      </div>
                    )}
                  </div>

                  {/* Biztosítás */}
                  <div className="pt-4 border-t border-gray-600">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-400">Biztosítás:</span>
                      <span className="text-white">
                        {project.budget.insurance === 'none' && 'Nincs biztosítás'}
                        {project.budget.insurance === 'basic' && 'Alap biztosítás'}
                        {project.budget.insurance === 'premium' && 'Prémium biztosítás'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Alapadatok panel */}
              <div className="bg-gray-700/50 rounded-lg p-4">
                <h2 className="text-lg font-medium text-white mb-4">Alapadatok</h2>
                <div className="space-y-3">
                  <div>
                    <div className="text-sm text-gray-400">Kategória</div>
                    <div className="text-white flex items-center gap-2">
                      <FaTag className="w-4 h-4 text-[#20A64B]" />
                      {CATEGORIES.find(c => c.value === project.category)?.label || project.category}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400">Alkategória</div>
                    <div className="text-white">{project.subcategory}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400">Projekt mérete</div>
                    <div className="text-white">
                      {project.scope === 'small' && 'Kis projekt'}
                      {project.scope === 'medium' && 'Közepes projekt'}
                      {project.scope === 'large' && 'Nagy projekt'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Időzítés panel */}
              <div className="bg-gray-700/50 rounded-lg p-4">
                <h2 className="text-lg font-medium text-white mb-4">Időzítés</h2>
                <div className="space-y-3">
                  <div>
                    <div className="text-sm text-gray-400">Tervezett kezdés</div>
                    <div className="text-white">
                      {project.timeline.expectedStartDate ? formatTimestamp(project.timeline.expectedStartDate) : 'Nincs megadva'}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400">Határidő</div>
                    <div className="text-white">
                      {project.timeline.deadline ? formatTimestamp(project.timeline.deadline) : 'Nincs megadva'}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400">Rugalmasság</div>
                    <div className="text-white">
                      {project.timeline.isFlexible ? 'Rugalmas határidő' : 'Fix határidő'}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400">Sürgősség</div>
                    <div className="text-white">
                      {project.timeline.urgency === 'low' && 'Alacsony'}
                      {project.timeline.urgency === 'medium' && 'Közepes'}
                      {project.timeline.urgency === 'high' && 'Magas'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Költségvetés panel */}
              <div className="bg-gray-700/50 rounded-lg p-4">
                <h2 className="text-lg font-medium text-white mb-4">Költségvetés</h2>
                <div className="space-y-3">
                  <div>
                    <div className="text-sm text-gray-400">Minimum - Maximum</div>
                    <div className="text-[#20A64B] font-semibold">
                      {project.budget.min.toLocaleString()} - {project.budget.max.toLocaleString()} {project.budget.currency}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400">Rugalmasság</div>
                    <div className="text-white">
                      {project.budget.isFlexible ? 'Rugalmas költségvetés' : 'Fix költségvetés'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Kérdőív panel */}
              {project.questionnaire && (
                <div className="bg-gray-700/50 rounded-lg p-4">
                  <h2 className="text-lg font-medium text-white mb-4">Kérdőív</h2>
                  
                  {/* Döntéshozatal és együttműködés */}
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-[#20A64B] mb-3">Döntéshozatal és együttműködés</h3>
                    <div className="space-y-3">
                      <div>
                        <div className="text-sm text-gray-400">Preferált döntéshozatali folyamat</div>
                        <div className="text-white">{project.questionnaire.extraCostHandling || 'Nincs megadva'}</div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Visszajelzési idő kérdések esetén</div>
                        <div className="text-white">
                          {project.questionnaire.decisionTime === '1_day' && '24 órán belül'}
                          {project.questionnaire.decisionTime === '2_3_days' && '2-3 napon belül'}
                          {project.questionnaire.decisionTime === '1_week' && '1 héten belül'}
                          {project.questionnaire.decisionTime === '2_weeks' && '2 héten belül'}
                          {!project.questionnaire.decisionTime && 'Nincs megadva'}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Részvétel a részletek kidolgozásában</div>
                        <div className="text-white">
                          {project.questionnaire.budgetFlexibility === 100 && 'Aktív részvétel minden részletben'}
                          {project.questionnaire.budgetFlexibility === 50 && 'Főbb döntési pontoknál'}
                          {project.questionnaire.budgetFlexibility === 20 && 'Csak a kulcsfontosságú kérdésekben'}
                          {!project.questionnaire.budgetFlexibility && 'Nincs megadva'}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Időbeli rugalmasság */}
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-[#20A64B] mb-3">Időbeli rugalmasság</h3>
                    <div className="space-y-3">
                      <div>
                        <div className="text-sm text-gray-400">Sürgős döntések maximális ideje</div>
                        <div className="text-white">
                          {project.questionnaire.urgentDecisionTime === '2_hours' && '2 órán belül'}
                          {project.questionnaire.urgentDecisionTime === '4_hours' && '4 órán belül'}
                          {project.questionnaire.urgentDecisionTime === '8_hours' && '8 órán belül'}
                          {project.questionnaire.urgentDecisionTime === '24_hours' && '24 órán belül'}
                          {!project.questionnaire.urgentDecisionTime && 'Nincs megadva'}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Projekt egyeztetések gyakorisága</div>
                        <div className="text-white">
                          {project.questionnaire.meetingAvailability === 'daily' && 'Naponta'}
                          {project.questionnaire.meetingAvailability === '2_3_times_week' && 'Hetente 2-3 alkalommal'}
                          {project.questionnaire.meetingAvailability === 'weekly' && 'Hetente'}
                          {project.questionnaire.meetingAvailability === 'biweekly' && 'Kéthetente'}
                          {!project.questionnaire.meetingAvailability && 'Nincs megadva'}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Nem elérhető időszakok</div>
                        <div className="text-white">{project.questionnaire.unavailablePeriods || 'Nincs megadva'}</div>
                      </div>
                    </div>
                  </div>

                  {/* Kommunikáció */}
                  <div>
                    <h3 className="text-sm font-medium text-[#20A64B] mb-3">Kommunikáció</h3>
                    <div className="space-y-3">
                      <div>
                        <div className="text-sm text-gray-400">Elvárt válaszidő sürgős esetben</div>
                        <div className="text-white">
                          {project.questionnaire.urgentResponseTime === '1_hour' && '1 órán belül'}
                          {project.questionnaire.urgentResponseTime === '2_hours' && '2 órán belül'}
                          {project.questionnaire.urgentResponseTime === '4_hours' && '4 órán belül'}
                          {project.questionnaire.urgentResponseTime === '8_hours' && '8 órán belül'}
                          {!project.questionnaire.urgentResponseTime && 'Nincs megadva'}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Státusz frissítések gyakorisága</div>
                        <div className="text-white">
                          {project.questionnaire.updateFrequency === 'daily' && 'Naponta'}
                          {project.questionnaire.updateFrequency === '2_3_times_week' && 'Hetente 2-3 alkalommal'}
                          {project.questionnaire.updateFrequency === 'weekly' && 'Hetente'}
                          {project.questionnaire.updateFrequency === 'biweekly' && 'Kéthetente'}
                          {!project.questionnaire.updateFrequency && 'Nincs megadva'}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Preferált kommunikációs csatorna sürgős esetben</div>
                        <div className="text-white">
                          {project.questionnaire.communicationChannel?.urgent === 'email' && 'Email'}
                          {project.questionnaire.communicationChannel?.urgent === 'phone' && 'Telefon'}
                          {project.questionnaire.communicationChannel?.urgent === 'both' && 'Email és telefon'}
                          {!project.questionnaire.communicationChannel?.urgent && 'Nincs megadva'}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400">Preferált kommunikációs csatorna általános esetben</div>
                        <div className="text-white">
                          {project.questionnaire.communicationChannel?.normal === 'email' && 'Email'}
                          {project.questionnaire.communicationChannel?.normal === 'phone' && 'Telefon'}
                          {project.questionnaire.communicationChannel?.normal === 'both' && 'Email és telefon'}
                          {!project.questionnaire.communicationChannel?.normal && 'Nincs megadva'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 