import { useAuth } from '../context/AuthContext';
import { 
  FaPlus, 
  FaEdit, 
  FaTrash, 
  FaExchangeAlt,
  FaUserTie,
  FaUsers
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ProfileSetup from '../components/ProfileSetup';
import { useState } from 'react';
import { SERVICES } from '../constants/profileServices';

export default function Profile() {
  const { user, profiles, activeProfile, switchProfile, deleteProfile } = useAuth();
  const navigate = useNavigate();
  const [showProfileSetup, setShowProfileSetup] = useState(false);
  const [editingProfile, setEditingProfile] = useState<Profile | null>(null);

  if (!user) {
    navigate('/login');
    return null;
  }

  const handleDeleteProfile = async (profileId: string) => {
    if (window.confirm('Biztosan törölni szeretné ezt a profilt?')) {
      await deleteProfile(profileId);
    }
  };

  const getProfileTypeLabel = (type: 'contractor' | 'client') => {
    return type === 'contractor' ? 'Kivitelező' : 'Megrendelő';
  };

  const getBusinessTypeLabel = (type: 'individual' | 'company' | 'entrepreneur') => {
    switch (type) {
      case 'individual': return 'Magánszemély';
      case 'company': return 'Cég';
      case 'entrepreneur': return 'Egyéni vállalkozó';
    }
  };

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="bg-gray-800 rounded-lg p-6">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold text-white">Profilok kezelése</h1>
            <button
              onClick={() => setShowProfileSetup(true)}
              className="flex items-center gap-2 px-4 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors"
            >
              <FaPlus /> Új profil létrehozása
            </button>
          </div>

          {showProfileSetup || editingProfile ? (
            <div className="mb-8">
              <button
                onClick={() => {
                  setShowProfileSetup(false);
                  setEditingProfile(null);
                }}
                className="text-gray-400 hover:text-white mb-4"
              >
                ← Vissza a profilokhoz
              </button>
              <ProfileSetup 
                onComplete={() => {
                  setShowProfileSetup(false);
                  setEditingProfile(null);
                }}
                initialProfile={editingProfile}
                isEditing={!!editingProfile}
              />
            </div>
          ) : (
            <div className="grid gap-4">
              {profiles.map(profile => (
                <div
                  key={profile.id}
                  className={`p-6 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors`}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-800/50">
                        {profile.type === 'contractor' ? (
                          <FaUserTie className="text-[#20A64B] text-xl" />
                        ) : (
                          <FaUsers className="text-[#20A64B] text-xl" />
                        )}
                      </div>
                      <div>
                        <div className="flex items-center gap-3">
                          <h2 className="text-lg font-medium text-white">
                            {profile.type === 'contractor' ? 'Kivitelező' : 'Megrendelő'}
                          </h2>
                          {profile.id === activeProfile?.id && (
                            <span className="px-2 py-0.5 text-xs bg-[#20A64B] text-white rounded-full">
                              Aktív
                            </span>
                          )}
                        </div>
                        <p className="text-sm text-gray-300">
                          {profile.company?.name || 'Magánszemély'}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      {profile.id !== activeProfile?.id && (
                        <button
                          onClick={() => switchProfile(profile.id)}
                          className="flex items-center gap-2 px-3 py-1.5 bg-gray-600 hover:bg-[#20A64B] text-white rounded-lg transition-colors text-sm"
                        >
                          <FaExchangeAlt /> Aktiválás
                        </button>
                      )}
                      <button
                        onClick={() => setEditingProfile(profile)}
                        className="p-2 text-gray-400 hover:text-white transition-colors"
                        title="Szerkesztés"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteProfile(profile.id)}
                        className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                        title="Törlés"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                  {profile.type === 'contractor' && profile.services && profile.services.length > 0 && (
                    <div className="mt-4 pl-16">
                      <div className="flex flex-wrap gap-2">
                        {profile.services.map(serviceId => {
                          const service = SERVICES.find(s => s.id === serviceId);
                          return (
                            <span
                              key={serviceId}
                              className="px-2 py-1 text-xs bg-gray-800 text-gray-300 rounded-full"
                            >
                              {service?.name || serviceId}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 