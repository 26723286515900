import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import { ProjectData, Project, ProjectBudget, Proposal } from '../../../types/project';
import { formatDate, formatDateTime } from '../../../utils/dateUtils';
import { FaClock, FaArrowLeft, FaEdit, FaBan, FaCheck, FaFile, FaDownload, FaFileImage, FaFilePdf, FaFileWord, FaFileExcel, FaUser, FaEnvelope, FaPhone, FaExternalLinkAlt, FaCrown, FaTimes, FaEye } from 'react-icons/fa';
import { CATEGORIES } from '../../../constants/projectCategories';

// Típus kiegészítések
interface ExtendedProposal extends Proposal {
  contractor?: {
    id: string;
    name?: string;
    companyName?: string;
    email: string;
    displayName?: string;
    [key: string]: any;
  };
}

interface ExtendedProjectData extends ProjectData {
  category?: string;
  attachments?: Array<{
    id: string;
    name: string;
    size: number;
    type: string;
    url: string;
  }>;
}

export default function ProjectDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState<ExtendedProjectData | null>(null);
  const [proposals, setProposals] = useState<ExtendedProposal[]>([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<ExtendedProposal | null>(null);
  const [selectedProfile, setSelectedProfile] = useState<any>(null);

  useEffect(() => {
    if (id) {
      fetchProject(id);
    }
  }, [id]);

  const fetchProject = async (projectId: string) => {
    try {
      setLoading(true);
      
      // Projekt adatainak lekérése
      const projectRef = doc(db, 'projects', projectId);
      const projectSnap = await getDoc(projectRef);
      
      if (!projectSnap.exists()) {
        navigate('/admin/projects');
        return;
      }

      const projectData = {
        id: projectSnap.id,
        ...projectSnap.data()
      } as ExtendedProjectData;

      // Kliens adatainak lekérése
      const clientUserRef = doc(db, 'users', projectData.clientId);
      const clientUserSnap = await getDoc(clientUserRef);
      const clientProfileRef = doc(db, 'profiles', projectData.clientId);
      const clientProfileSnap = await getDoc(clientProfileRef);

      if (clientUserSnap.exists() || clientProfileSnap.exists()) {
        projectData.client = {
          id: projectData.clientId,
          ...clientUserSnap.data(),
          ...clientProfileSnap.data()
        };
      } else {
        console.error(`Client not found with ID: ${projectData.clientId}`);
      }

      // Összes ajánlat lekérése
      const proposalsRef = collection(db, 'proposals');
      const proposalsQuery = query(proposalsRef, where('projectId', '==', projectId));
      const proposalsSnap = await getDocs(proposalsQuery);
      const proposalsData: ExtendedProposal[] = [];

      for (const proposalDoc of proposalsSnap.docs) {
        const proposalData = {
          id: proposalDoc.id,
          ...proposalDoc.data()
        } as ExtendedProposal;

        // Kivitelező adatainak lekérése minden ajánlathoz
        const contractorUserRef = doc(db, 'users', proposalData.contractorId);
        const contractorUserSnap = await getDoc(contractorUserRef);
        const contractorProfileRef = doc(db, 'profiles', proposalData.contractorId);
        const contractorProfileSnap = await getDoc(contractorProfileRef);

        if (contractorUserSnap.exists() || contractorProfileSnap.exists()) {
          proposalData.contractor = {
            id: proposalData.contractorId,
            ...contractorUserSnap.data(),
            ...contractorProfileSnap.data()
          };
        }

        proposalsData.push(proposalData);
      }

      // Ajánlatok rendezése: elfogadott ajánlat legfelül, utána dátum szerint csökkenő
      proposalsData.sort((a, b) => {
        if (a.id === projectData.acceptedProposalId) return -1;
        if (b.id === projectData.acceptedProposalId) return 1;
        return (b.createdAt as Timestamp).toMillis() - (a.createdAt as Timestamp).toMillis();
      });

      setProject(projectData);
      setProposals(proposalsData);
    } catch (error) {
      console.error('Error fetching project:', error);
      navigate('/admin/projects');
    } finally {
      setLoading(false);
    }
  };

  const formatBudget = (budget: number | ProjectBudget | undefined): string => {
    if (!budget) return '-';
    
    if (typeof budget === 'number') {
      return `${budget.toLocaleString()} Ft`;
    }
    
    return `${budget.min.toLocaleString()} - ${budget.max.toLocaleString()} ${budget.currency}`;
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <FaClock className="w-6 h-6 text-gray-400 animate-spin mx-auto mb-2" />
        <p className="text-gray-400">Projekt betöltése...</p>
      </div>
    );
  }

  if (!project) {
    return null;
  }

  return (
    <div className="space-y-6">
      {/* Fejléc */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/admin/projects')}
            className="p-2 text-gray-400 hover:text-white transition-colors"
          >
            <FaArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">{project.title}</h1>
        </div>
        <div className="flex items-center gap-2">
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-[#20A64B]/20 hover:bg-[#20A64B]/30 rounded-lg transition-colors flex items-center gap-2"
          >
            <FaEdit className="w-4 h-4" />
            Szerkesztés
          </button>
        </div>
      </div>

      {/* Projekt adatok */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Bal oldal */}
        <div className="space-y-6">
          <div className="bg-gray-700/50 rounded-lg p-4">
            <h2 className="text-lg font-medium text-white mb-4">Alapadatok</h2>
            <div className="space-y-3">
              <div>
                <div className="text-sm text-gray-400">Kategória</div>
                <div className="text-white">
                  {project.category ? CATEGORIES.find(cat => cat.value === project.category)?.label || project.category : '-'}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Státusz</div>
                <div className={`inline-block px-2 py-1 text-sm rounded-full mt-1 ${
                  project.status === 'active' ? 'bg-[#20A64B]/20 text-[#20A64B]' :
                  project.status === 'completed' ? 'bg-blue-500/20 text-blue-400' :
                  project.status === 'cancelled' ? 'bg-red-500/20 text-red-400' :
                  'bg-gray-500/20 text-gray-400'
                }`}>
                  {project.status === 'active' ? 'Aktív' :
                   project.status === 'completed' ? 'Befejezett' :
                   project.status === 'cancelled' ? 'Visszavont' :
                   'Vázlat'}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Költségkeret</div>
                <div className="text-white">{formatBudget(project.budget)}</div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Határidő</div>
                <div className="text-white">{project.deadline ? formatDate(project.deadline) : '-'}</div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Létrehozva</div>
                <div className="text-white">{formatDateTime(project.createdAt)}</div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Utoljára módosítva</div>
                <div className="text-white">{formatDateTime(project.updatedAt)}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Jobb oldal */}
        <div className="space-y-6">
          <div className="bg-gray-700/50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-medium text-white">Megrendelő</h2>
              {project.client && (
                <button
                  onClick={() => setSelectedProfile(project.client)}
                  className="text-sm text-[#20A64B] hover:text-[#20A64B]/80 flex items-center gap-1"
                >
                  <span>Profil megtekintése</span>
                  <FaExternalLinkAlt className="w-3 h-3" />
                </button>
              )}
            </div>
            {project.client ? (
              <div className="space-y-3">
                <div className="flex items-start gap-3">
                  <FaUser className="w-4 h-4 text-gray-400 mt-0.5" />
                  <div>
                    <div className="text-white">{project.client.name}</div>
                    {project.client.companyName && (
                      <div className="text-sm text-gray-400">{project.client.companyName}</div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <FaEnvelope className="w-4 h-4 text-gray-400" />
                  <a 
                    href={`mailto:${project.client.email}`}
                    className="text-white hover:text-[#20A64B] transition-colors"
                  >
                    {project.client.email}
                  </a>
                </div>
                {project.client.phone && (
                  <div className="flex items-center gap-3">
                    <FaPhone className="w-4 h-4 text-gray-400" />
                    <a 
                      href={`tel:${project.client.phone}`}
                      className="text-white hover:text-[#20A64B] transition-colors"
                    >
                      {project.client.phone}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-gray-400">Nincs megrendelő hozzárendelve.</p>
            )}
          </div>

          {project.contractor && (
            <div className="bg-gray-700/50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-medium text-white">Kivitelező</h2>
                <button
                  onClick={() => setSelectedProfile(project.contractor)}
                  className="text-sm text-[#20A64B] hover:text-[#20A64B]/80 flex items-center gap-1"
                >
                  <span>Profil megtekintése</span>
                  <FaExternalLinkAlt className="w-3 h-3" />
                </button>
              </div>
              <div className="space-y-3">
                <div className="flex items-start gap-3">
                  <FaUser className="w-4 h-4 text-gray-400 mt-0.5" />
                  <div>
                    <div className="text-white">{project.contractor.name}</div>
                    {project.contractor.companyName && (
                      <div className="text-sm text-gray-400">{project.contractor.companyName}</div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <FaEnvelope className="w-4 h-4 text-gray-400" />
                  <a 
                    href={`mailto:${project.contractor.email}`}
                    className="text-white hover:text-[#20A64B] transition-colors"
                  >
                    {project.contractor.email}
                  </a>
                </div>
                {project.contractor.phone && (
                  <div className="flex items-center gap-3">
                    <FaPhone className="w-4 h-4 text-gray-400" />
                    <a 
                      href={`tel:${project.contractor.phone}`}
                      className="text-white hover:text-[#20A64B] transition-colors"
                    >
                      {project.contractor.phone}
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Ajánlatok */}
          <div className="bg-gray-700/50 rounded-lg p-4">
            <h2 className="text-lg font-medium text-white mb-4">Beérkezett ajánlatok</h2>
            {proposals.length > 0 ? (
              <div className="space-y-3">
                {proposals.map((proposal) => (
                  <div 
                    key={proposal.id}
                    className={`p-4 rounded-lg ${
                      proposal.id === project?.acceptedProposalId
                        ? 'bg-[#20A64B]/10 border border-[#20A64B]/20'
                        : 'bg-gray-800/50'
                    }`}
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-3">
                          <FaUser className="w-4 h-4 text-gray-400" />
                          <div>
                            <div className="text-white flex items-center gap-2">
                              {proposal.contractor?.displayName || proposal.contractor?.email}
                              {proposal.id === project?.acceptedProposalId && (
                                <FaCrown className="w-4 h-4 text-[#20A64B]" title="Elfogadott ajánlat" />
                              )}
                            </div>
                            {proposal.contractor?.name && (
                              <div className="text-sm text-gray-400">
                                {proposal.contractor.name}
                                {proposal.contractor.companyName && ` - ${proposal.contractor.companyName}`}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className="text-lg font-medium text-white">
                          {proposal.amount.toLocaleString()} {proposal.currency}
                        </div>
                        <button
                          onClick={() => setSelectedProposal(proposal)}
                          className="p-2 text-gray-400 hover:text-white transition-colors"
                          title="Részletek megtekintése"
                        >
                          <FaEye className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">Még nem érkeztek ajánlatok.</p>
            )}
          </div>

          {/* Fájlok */}
          <div className="bg-gray-700/50 rounded-lg p-4">
            <h2 className="text-lg font-medium text-white mb-4">Csatolt fájlok</h2>
            {project.attachments && project.attachments.length > 0 ? (
              <div className="space-y-2">
                {project.attachments.map((file, index) => {
                  // Fájl ikon kiválasztása a típus alapján
                  const getFileIcon = (type: string) => {
                    if (type.startsWith('image/')) return FaFileImage;
                    if (type === 'application/pdf') return FaFilePdf;
                    if (type.includes('word')) return FaFileWord;
                    if (type.includes('excel') || type.includes('spreadsheet')) return FaFileExcel;
                    return FaFile;
                  };
                  const FileIcon = getFileIcon(file.type);

                  return (
                    <div 
                      key={file.id} 
                      className="flex items-center justify-between p-3 bg-gray-800/50 rounded-lg hover:bg-gray-800/70 transition-colors"
                    >
                      <div className="flex items-center gap-3">
                        <FileIcon className="w-5 h-5 text-gray-400" />
                        <div>
                          <div className="text-sm text-white">{file.name}</div>
                          <div className="text-xs text-gray-400">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </div>
                        </div>
                      </div>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 text-gray-400 hover:text-white transition-colors"
                        title="Letöltés"
                      >
                        <FaDownload className="w-4 h-4" />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-gray-400">Nincsenek csatolt fájlok.</p>
            )}
          </div>
        </div>
      </div>

      {/* Profil részletek modal */}
      {selectedProfile && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="p-6 space-y-6">
              {/* Modal fejléc */}
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-xl font-medium text-white">Profil adatok</h3>
                  <p className="mt-1 text-sm text-gray-400">
                    {selectedProfile.type === 'client' ? 'Megrendelő' : 'Kivitelező'}
                  </p>
                </div>
                <button
                  onClick={() => setSelectedProfile(null)}
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <FaTimes className="w-5 h-5" />
                </button>
              </div>

              {/* Felhasználó adatai */}
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <FaUser className="w-4 h-4 text-gray-400 mt-1" />
                  <div>
                    <div className="text-white">{selectedProfile.displayName || selectedProfile.email}</div>
                    {selectedProfile.name && (
                      <div className="text-sm text-gray-400">
                        {selectedProfile.name}
                        {selectedProfile.companyName && ` - ${selectedProfile.companyName}`}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <FaEnvelope className="w-4 h-4 text-gray-400" />
                  <a 
                    href={`mailto:${selectedProfile.email}`}
                    className="text-white hover:text-[#20A64B] transition-colors"
                  >
                    {selectedProfile.email}
                  </a>
                </div>

                {selectedProfile.phone && (
                  <div className="flex items-center gap-3">
                    <FaPhone className="w-4 h-4 text-gray-400" />
                    <a 
                      href={`tel:${selectedProfile.phone}`}
                      className="text-white hover:text-[#20A64B] transition-colors"
                    >
                      {selectedProfile.phone}
                    </a>
                  </div>
                )}

                {selectedProfile.description && (
                  <div className="mt-4">
                    <div className="text-sm text-gray-400 mb-2">Bemutatkozás</div>
                    <div className="text-white whitespace-pre-wrap rounded-lg bg-gray-700/50 p-4">
                      {selectedProfile.description}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Ajánlat részletek modal */}
      {selectedProposal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="p-8 space-y-8">
              {/* Modal fejléc */}
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-2xl font-medium text-white">Ajánlat részletei</h3>
                  <p className="mt-2 text-sm text-gray-400">
                    Beküldve: {formatDateTime(selectedProposal.createdAt)}
                  </p>
                </div>
                <button
                  onClick={() => setSelectedProposal(null)}
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <FaTimes className="w-6 h-6" />
                </button>
              </div>

              {/* Kivitelező adatai */}
              <div className="space-y-4 bg-gray-700/30 rounded-lg p-4">
                <div className="flex items-start gap-3">
                  <FaUser className="w-4 h-4 text-gray-400 mt-1" />
                  <div>
                    <div className="text-white">{selectedProposal.contractor?.name}</div>
                    {selectedProposal.contractor?.companyName && (
                      <div className="text-sm text-gray-400">{selectedProposal.contractor.companyName}</div>
                    )}
                  </div>
                </div>
              </div>

              {/* Ajánlat adatai */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <div className="text-sm text-gray-400 mb-2">Ajánlott összeg</div>
                  <div className="text-2xl font-medium text-white">
                    {selectedProposal.amount.toLocaleString()} {selectedProposal.currency}
                  </div>
                </div>

                <div>
                  <div className="text-sm text-gray-400 mb-2">Becsült időtartam</div>
                  <div className="text-2xl font-medium text-white">{selectedProposal.estimatedDuration} nap</div>
                </div>
              </div>

              <div>
                <div className="text-sm text-gray-400 mb-2">Részletes leírás</div>
                <div className="text-white whitespace-pre-wrap rounded-lg bg-gray-700/30 p-6 leading-relaxed">
                  {selectedProposal.description}
                </div>
              </div>

              {/* Csatolt dokumentumok */}
              {selectedProposal.attachments && selectedProposal.attachments.length > 0 && (
                <div>
                  <div className="text-sm text-gray-400 mb-3">Csatolt dokumentumok</div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {selectedProposal.attachments.map((file) => (
                      <div 
                        key={file.id}
                        className="flex items-center justify-between p-4 bg-gray-700/30 rounded-lg hover:bg-gray-700/50 transition-colors"
                      >
                        <div className="flex items-center gap-3">
                          <FaFile className="w-4 h-4 text-gray-400" />
                          <div>
                            <div className="text-sm text-white">{file.name}</div>
                            <div className="text-xs text-gray-400">
                              {(file.size / 1024 / 1024).toFixed(2)} MB
                            </div>
                          </div>
                        </div>
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-2 text-gray-400 hover:text-white transition-colors"
                          title="Letöltés"
                        >
                          <FaDownload className="w-4 h-4" />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 