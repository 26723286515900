import { useAuth } from '../context/AuthContext';
import { FaExchangeAlt } from 'react-icons/fa';

export default function ProfileSwitcher() {
  const { user, profiles, activeProfile, switchProfile } = useAuth();

  if (!user || !profiles || profiles.length === 0) return null;

  if (profiles.length <= 1) return null;

  return (
    <div className="relative group">
      <button className="flex items-center gap-2 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg transition-colors">
        <span className="text-gray-200">
          {activeProfile?.type === 'contractor' ? 'Kivitelező' : 'Megrendelő'}
          {activeProfile?.company && ` - ${activeProfile.company.name}`}
        </span>
        <FaExchangeAlt className="text-[#20A64B]" />
      </button>
      
      <div className="absolute right-0 mt-2 w-64 bg-gray-800 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
        {profiles.map(profile => (
          <button
            key={profile.id}
            onClick={() => switchProfile(profile.id)}
            className={`w-full text-left px-4 py-3 hover:bg-gray-700 first:rounded-t-lg last:rounded-b-lg transition-colors flex items-center justify-between ${
              profile.id === activeProfile?.id ? 'bg-gray-700' : ''
            }`}
          >
            <div>
              <div className="text-white font-medium">
                {profile.type === 'contractor' ? 'Kivitelező' : 'Megrendelő'}
              </div>
              {profile.company && (
                <div className="text-sm text-gray-400">{profile.company.name}</div>
              )}
            </div>
            {profile.id === activeProfile?.id && (
              <div className="w-2 h-2 rounded-full bg-[#20A64B]" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
} 