import { ProjectQuestionnaire } from '../../../types/project';
import { useState } from 'react';

interface QuestionnaireStepProps {
  questionnaire: ProjectQuestionnaire;
  setQuestionnaire: (questionnaire: ProjectQuestionnaire) => void;
  validationErrors: Record<string, string>;
}

export function QuestionnaireStep({ questionnaire, setQuestionnaire, validationErrors }: QuestionnaireStepProps) {
  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-xl font-semibold text-white mb-4">Projekt Kérdőív</h2>
        <p className="text-gray-300">
          A következő kérdések segítenek felmérni az együttműködés kereteit és a projekt folyamatát.
          Kérjük, válaszoljon őszintén a kérdésekre.
        </p>
      </div>

      {/* Döntéshozatal és együttműködés */}
      <div className="space-y-6">
        <h3 className="text-lg font-medium text-white">Döntéshozatal és együttműködés</h3>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Mi a preferált döntéshozatali folyamat a projekttel kapcsolatos kérdésekben?
          </label>
          <textarea
            value={questionnaire.extraCostHandling}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              extraCostHandling: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
            rows={3}
            placeholder="Pl.: Kik vesznek részt a döntésekben? Milyen szintű önállóságot biztosít a kivitelezőnek?"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Mennyi időn belül tud visszajelzést adni a felmerülő kérdésekre?
          </label>
          <select
            value={questionnaire.decisionTime}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              decisionTime: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
          >
            <option value="">Válasszon...</option>
            <option value="1_day">24 órán belül</option>
            <option value="2_3_days">2-3 napon belül</option>
            <option value="1_week">1 héten belül</option>
            <option value="2_weeks">2 héten belül</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Milyen mértékben szeretne részt venni a projekt részleteinek kidolgozásában?
          </label>
          <select
            value={questionnaire.budgetFlexibility}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              budgetFlexibility: parseInt(e.target.value)
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
          >
            <option value="">Válasszon...</option>
            <option value="100">Aktív részvétel minden részletben</option>
            <option value="50">Főbb döntési pontoknál</option>
            <option value="20">Csak a kulcsfontosságú kérdésekben</option>
          </select>
        </div>
      </div>

      {/* Időbeli rugalmasság */}
      <div className="space-y-6">
        <h3 className="text-lg font-medium text-white">Időbeli rugalmasság</h3>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Mennyi a maximális döntési idő egy sürgős kérdésben?
          </label>
          <select
            value={questionnaire.urgentDecisionTime}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              urgentDecisionTime: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
          >
            <option value="">Válasszon...</option>
            <option value="2_hours">2 órán belül</option>
            <option value="4_hours">4 órán belül</option>
            <option value="8_hours">8 órán belül</option>
            <option value="24_hours">24 órán belül</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Milyen gyakran tud részt venni projekt egyeztetéseken?
          </label>
          <select
            value={questionnaire.meetingAvailability}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              meetingAvailability: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
          >
            <option value="">Válasszon...</option>
            <option value="daily">Naponta</option>
            <option value="2_3_times_week">Hetente 2-3 alkalommal</option>
            <option value="weekly">Hetente</option>
            <option value="biweekly">Kéthetente</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Van-e olyan időszak amikor biztosan nem elérhető?
          </label>
          <textarea
            value={questionnaire.unavailablePeriods}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              unavailablePeriods: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
            rows={2}
            placeholder="Pl.: Szabadság, egyéb projektek időszakai"
          />
        </div>
      </div>

      {/* Kommunikáció */}
      <div className="space-y-6">
        <h3 className="text-lg font-medium text-white">Kommunikáció</h3>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Mi az elvárt válaszidő sürgős kérdések esetén?
          </label>
          <select
            value={questionnaire.urgentResponseTime}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              urgentResponseTime: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
          >
            <option value="">Válasszon...</option>
            <option value="1_hour">1 órán belül</option>
            <option value="2_hours">2 órán belül</option>
            <option value="4_hours">4 órán belül</option>
            <option value="8_hours">8 órán belül</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Milyen gyakran szeretne projekt státusz updateket?
          </label>
          <select
            value={questionnaire.updateFrequency}
            onChange={(e) => setQuestionnaire({
              ...questionnaire,
              updateFrequency: e.target.value
            })}
            className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
          >
            <option value="">Válasszon...</option>
            <option value="daily">Naponta</option>
            <option value="2_3_times_week">Hetente 2-3 alkalommal</option>
            <option value="weekly">Hetente</option>
            <option value="biweekly">Kéthetente</option>
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Preferált kommunikációs csatorna sürgős esetben
            </label>
            <select
              value={questionnaire.communicationChannel.urgent}
              onChange={(e) => setQuestionnaire({
                ...questionnaire,
                communicationChannel: {
                  ...questionnaire.communicationChannel,
                  urgent: e.target.value as 'email' | 'phone' | 'both'
                }
              })}
              className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
            >
              <option value="email">Email</option>
              <option value="phone">Telefon</option>
              <option value="both">Mindkettő</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Preferált kommunikációs csatorna általános esetben
            </label>
            <select
              value={questionnaire.communicationChannel.normal}
              onChange={(e) => setQuestionnaire({
                ...questionnaire,
                communicationChannel: {
                  ...questionnaire.communicationChannel,
                  normal: e.target.value as 'email' | 'phone' | 'both'
                }
              })}
              className="w-full bg-gray-800 border border-gray-600 rounded-lg p-3 text-white"
            >
              <option value="email">Email</option>
              <option value="phone">Telefon</option>
              <option value="both">Mindkettő</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
} 