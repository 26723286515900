import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, where, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { ProjectData, Project, ProjectBudget } from '../../../types/project';
import { FaClock, FaExclamationCircle, FaBan, FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/dateUtils';

export default function ProjectsPage() {
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState<string | null>(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      
      // Projektek lekérése
      const projectsRef = collection(db, 'projects');
      const projectsQuery = query(
        projectsRef,
        orderBy('createdAt', 'desc')
      );
      const projectsSnap = await getDocs(projectsQuery);
      
      // Projektek adatainak összeállítása
      const projectsData: ProjectData[] = [];
      
      for (const doc of projectsSnap.docs) {
        const project = {
          id: doc.id,
          ...doc.data()
        } as ProjectData;

        // Kliens adatainak lekérése
        const clientRef = collection(db, 'profiles');
        const clientQuery = query(clientRef, where('id', '==', project.clientId));
        const clientSnap = await getDocs(clientQuery);
        const clientData = clientSnap.docs[0]?.data();

        // Elfogadott ajánlat lekérése
        if (project.acceptedProposalId) {
          const proposalRef = collection(db, 'proposals');
          const proposalQuery = query(proposalRef, where('id', '==', project.acceptedProposalId));
          const proposalSnap = await getDocs(proposalQuery);
          const proposalData = proposalSnap.docs[0]?.data();

          // Kivitelező adatainak lekérése
          if (proposalData?.contractorId) {
            const contractorQuery = query(clientRef, where('id', '==', proposalData.contractorId));
            const contractorSnap = await getDocs(contractorQuery);
            const contractorData = contractorSnap.docs[0]?.data();
            project.contractor = contractorData;
          }
        }

        project.client = clientData;
        projectsData.push(project);
      }
      
      setProjects(projectsData);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateProjectStatus = async (projectId: string, newStatus: 'active' | 'cancelled') => {
    try {
      setUpdating(projectId);
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, {
        status: newStatus,
        updatedAt: new Date()
      });
      
      // Frissítjük a helyi állapotot
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project.id === projectId 
            ? { ...project, status: newStatus, updatedAt: new Date() as any }
            : project
        )
      );
    } catch (error) {
      console.error('Error updating project status:', error);
    } finally {
      setUpdating(null);
    }
  };

  const formatBudget = (budget: number | ProjectBudget | undefined): string => {
    if (!budget) return '';
    
    if (typeof budget === 'number') {
      return `${budget.toLocaleString()} Ft`;
    }
    
    return `${budget.min.toLocaleString()} - ${budget.max.toLocaleString()} ${budget.currency}`;
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <FaClock className="w-6 h-6 text-gray-400 animate-spin mx-auto mb-2" />
        <p className="text-gray-400">Projektek betöltése...</p>
      </div>
    );
  }

  if (projects.length === 0) {
    return (
      <div className="text-center py-12">
        <FaExclamationCircle className="w-6 h-6 text-gray-400 mx-auto mb-2" />
        <p className="text-gray-400">Nincsenek még projektek.</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {projects.map((project) => (
        <div
          key={project.id}
          className="bg-gray-700/50 rounded-lg p-4"
        >
          <div className="flex items-start justify-between">
            <div>
              <Link 
                to={`/admin/projects/${project.id}`}
                className="text-lg font-medium text-white hover:text-[#20A64B] transition-colors"
              >
                {project.title}
              </Link>
              <div className="mt-1 text-sm text-gray-400">
                <span>Létrehozva: {formatDate(project.createdAt)}</span>
                {project.budget && (
                  <>
                    <span className="mx-2">•</span>
                    <span>Költségkeret: {formatBudget(project.budget)}</span>
                  </>
                )}
                {project.deadline && (
                  <>
                    <span className="mx-2">•</span>
                    <span>Határidő: {formatDate(project.deadline)}</span>
                  </>
                )}
              </div>
              {project.client && (
                <div className="mt-2 text-sm text-gray-400">
                  Megrendelő: {project.client.contactName}
                </div>
              )}
              {project.contractor && (
                <div className="mt-1 text-sm text-gray-400">
                  Kivitelező: {project.contractor.contactName}
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              <div className={`px-2 py-1 text-xs rounded-full ${
                project.status === 'active' ? 'bg-[#20A64B]/20 text-[#20A64B]' :
                project.status === 'completed' ? 'bg-blue-500/20 text-blue-400' :
                project.status === 'cancelled' ? 'bg-red-500/20 text-red-400' :
                'bg-gray-500/20 text-gray-400'
              }`}>
                {project.status === 'active' ? 'Aktív' :
                 project.status === 'completed' ? 'Befejezett' :
                 project.status === 'cancelled' ? 'Visszavont' :
                 'Vázlat'}
              </div>
              {project.status === 'active' && (
                <button
                  onClick={() => updateProjectStatus(project.id, 'cancelled')}
                  disabled={updating === project.id}
                  className="p-1 text-red-400 hover:text-red-300 disabled:opacity-50"
                  title="Projekt inaktiválása"
                >
                  <FaBan className="w-4 h-4" />
                </button>
              )}
              {project.status === 'cancelled' && (
                <button
                  onClick={() => updateProjectStatus(project.id, 'active')}
                  disabled={updating === project.id}
                  className="p-1 text-green-400 hover:text-green-300 disabled:opacity-50"
                  title="Projekt aktiválása"
                >
                  <FaCheck className="w-4 h-4" />
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
} 