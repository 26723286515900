import { CATEGORIES } from '../constants/projectCategories';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';

const PROJECT_TITLES = [
  'Webáruház fejlesztés',
  'Mobilalkalmazás készítés',
  'WordPress weboldal',
  'CRM rendszer fejlesztés',
  'Landing page készítés',
  'E-learning platform',
  'Ügyfélszolgálati rendszer',
  'Foglalási rendszer',
  'Ingatlan kereső alkalmazás',
  'Étterem rendelési rendszer'
];

const PROJECT_DESCRIPTIONS = [
  'Modern, reszponzív webáruház fejlesztése egyedi design alapján. A rendszer tartalmaz raktárkészlet kezelést, automatikus számlázást és több fizetési módot.',
  'Natív iOS és Android alkalmazás fejlesztése React Native technológiával. Az app támogatja az offline működést és a push értesítéseket.',
  'Professzionális WordPress weboldal fejlesztése egyedi sablonnal és bővítményekkel. SEO optimalizálás és gyors betöltési idő.',
  'Testreszabott CRM rendszer fejlesztése a vállalat egyedi igényei alapján. Integrálható a meglévő rendszerekkel.',
  'Konverziófókuszú landing page fejlesztése modern design elemekkel és A/B tesztelési lehetőséggel.',
  'Online oktatási platform fejlesztése videó streaming támogatással és interaktív kvízekkel.',
  'Komplex ügyfélszolgálati rendszer ticketing funkcióval és automatikus válaszokkal.',
  'Online foglalási rendszer fejlesztése naptár nézettel és automatikus értesítésekkel.',
  'Ingatlan kereső és hirdető platform fejlesztése térképes megjelenítéssel.',
  'Éttermi rendelési rendszer fejlesztése online fizetési lehetőséggel és futár követéssel.'
];

const BUDGETS = [
  { min: 500000, max: 1000000 },
  { min: 1000000, max: 2000000 },
  { min: 2000000, max: 5000000 },
  { min: 5000000, max: 10000000 }
];

const getRandomElement = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

const getRandomDate = (start: Date, end: Date): Date => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

const getRandomBudget = () => {
  const budget = getRandomElement(BUDGETS);
  return {
    min: budget.min,
    max: budget.max,
    currency: 'HUF'
  };
};

export const generateFakeProject = async (clientId: string) => {
  const now = new Date();
  const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  const threeMonthsFromNow = new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000);

  const projectData = {
    title: getRandomElement(PROJECT_TITLES),
    description: getRandomElement(PROJECT_DESCRIPTIONS),
    category: getRandomElement(CATEGORIES).value,
    status: getRandomElement(['draft', 'active', 'completed', 'cancelled']),
    budget: getRandomBudget(),
    clientId,
    deadline: getRandomDate(now, threeMonthsFromNow),
    createdAt: getRandomDate(oneMonthAgo, now),
    updatedAt: new Date(),
    attachments: [],
    location: 'Budapest',
    isPublic: true
  };

  try {
    const projectsRef = collection(db, 'projects');
    const docRef = await addDoc(projectsRef, projectData);
    console.log('✅ Fake project created:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error creating fake project:', error);
    throw error;
  }
};

export const generateMultipleFakeProjects = async (clientId: string, count: number = 5) => {
  try {
    console.log(`🚀 Generating ${count} fake projects...`);
    const promises = Array(count).fill(null).map(() => generateFakeProject(clientId));
    const results = await Promise.all(promises);
    console.log('✅ All fake projects created successfully!');
    return results;
  } catch (error) {
    console.error('Error generating fake projects:', error);
    throw error;
  }
}; 