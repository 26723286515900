import React, { useState, useEffect } from 'react';
import { FaRegMoneyBillAlt, FaCheck, FaFileAlt, FaClipboardList, FaQuestionCircle, FaPlusCircle, FaInfoCircle, FaRegClock } from 'react-icons/fa';
import { calculateAdvancePaymentScore, getInsuranceScore, getMinimumAdvancePayment, getProjectBadge, calculateProjectScore } from '../../../utils/projectScoring';
import { ProjectBudget, ProjectRequirement, ProjectTimeline, ProjectQuestionnaire, ProjectAttachment, ProjectCategory } from '../../../types/project';

interface ProjectScoreIndicatorProps {
  description: string;
  category?: ProjectCategory;
  subcategory?: string;
  budget: ProjectBudget;
  timeline: ProjectTimeline;
  requirements: ProjectRequirement[];
  questionnaire: ProjectQuestionnaire;
  attachments: ProjectAttachment[];
  references: any[];
}

const STORAGE_KEY = 'webtender_project_draft';

const scoreInfo = {
  basicInfo: {
    title: "Alapinformációk (max 40 pont)",
    description: `
      • Projekt kategória (10 pont)
      • Alkategória (5 pont)
      • Projekt méret (5 pont)
      • Részletes projektleírás (20 pont)
      
      Minél részletesebb a projekt leírása és minél pontosabbak az alapadatok,
      annál magasabb pontszámot kap a projekt ebben a kategóriában.
    `
  },
  documentation: {
    title: "Dokumentáció (max 40 pont)",
    description: `
      • Műszaki dokumentáció (20 pont)
      • Referencia anyagok (20 pont)
      
      A dokumentáció segít a kivitelezőknek pontosan megérteni az elvárásokat 
      és a projekt követelményeit.
    `
  },
  requirements: {
    title: "Követelmények és időzítés (max 20 pont)",
    description: `
      • Funkcionális követelmények (10 pont)
      • Határidők és időzítés (10 pont)
      
      A pontos követelmények és időzítés segít a kivitelezőknek 
      megfelelő ajánlatot adni.
    `
  },
  budget: {
    title: "Költségvetés (max 60 pont)",
    description: `
      • Költségvetési keretek (10 pont)
      • Előlegfizetés (20 pont)
      • Projekt biztosítás:
        - Alap: 15 pont
        - Prémium: 30 pont
      
      A pénzügyi feltételek pontos meghatározása segít 
      a projekt kockázatainak csökkentésében.
    `
  },
  questionnaire: {
    title: "Kérdőív (max 40 pont)",
    description: `
      • Döntéshozatal (20 pont)
        - Költségkezelés (10 pont)
        - Döntési idő (5 pont)
        - Sürgős döntések (5 pont)
      
      • Együttműködés és kommunikáció (20 pont)
        - Egyeztetések gyakorisága (5 pont)
        - Státusz frissítések (5 pont)
        - Kommunikációs csatornák (5 pont)
        - Elérhetőség (5 pont)
      
      A kérdőív segít felmérni a projekt kockázatait és az együttműködés kereteit.
    `
  },
  timeline: {
    title: "Időzítés (max 10 pont)",
    description: `
      • Határidők (10 pont)
      
      A pontos időzítés segít a kivitelezőknek megfelelő ajánlatot adni.
    `
  }
};

type ScoreInfoKey = 'basicInfo' | 'documentation' | 'requirements' | 'budget' | 'questionnaire' | 'timeline';

interface ScoreSection {
  name: string;
  score: number;
  max: number;
  key: ScoreInfoKey;
  icon: React.ReactNode;
  subItems: { name: string; score: number; max: number }[];
}

export const ProjectScoreIndicator: React.FC<ProjectScoreIndicatorProps> = ({
  description,
  category,
  subcategory,
  budget,
  timeline,
  requirements,
  questionnaire,
  attachments,
  references
}) => {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  // Mentsük el a projekt adatait a localStorage-ba
  useEffect(() => {
    const projectData = {
      description,
      category,
      subcategory,
      budget,
      timeline,
      requirements,
      questionnaire,
      attachments,
      references
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(projectData));
  }, [description, category, subcategory, budget, timeline, requirements, questionnaire, attachments, references]);

  // Számoljuk ki a pontszámokat
  const projectScore = calculateProjectScore({
    description,
    category,
    subcategory,
    budget,
    timeline,
    requirements,
    attachments,
    references,
    details: {
      platforms: [],
      responsiveDesign: false,
      seoRequirements: false,
      accessibility: false,
      analytics: false,
      maintenance: false,
      training: false
    },
    questionnaire,
    createdAt: new Date(),
    updatedAt: new Date(),
    id: '',
    clientId: '',
    clientName: '',
    title: '',
    scope: 'medium',
    status: 'draft',
    proposalIds: [],
    isGreenfield: false,
    contact: {
      preferredContact: 'email',
      preferredLanguages: ['hu'],
      availableHours: {
        from: '09:00',
        to: '17:00'
      }
    }
  });

  // Számoljuk ki a fizetési pontokat és adjuk hozzá az összpontszámhoz
  const paymentScore = (budget?.min > 0 && budget?.max > 0 ? 10 : 0) + calculateAdvancePaymentScore(budget) + getInsuranceScore(budget.insurance);

  // Számoljuk ki az összes megszerezhető pontot
  const maxPoints = {
    basicInfo: 40,
    documentation: 40,
    requirements: 10,
    timeline: 10,
    budget: 60,
    questionnaire: 40
  };

  // A teljes pontszám számítása (ne adjuk hozzá külön a paymentScore-t, mert az már benne van a projectScore.breakdown.budget-ben)
  const totalScore = projectScore.total;
  const totalMaxPoints = 200;

  const badge = getProjectBadge(totalScore);

  const getScoreColor = (value: number): string => {
    if (value >= 80) return 'text-[#20A64B]';
    if (value >= 60) return 'text-yellow-400';
    return 'text-red-400';
  };

  const renderBreakdown = () => {
    const sections: ScoreSection[] = [
      { 
        name: 'Alapinformációk', 
        score: projectScore.breakdown.basicInfo, 
        max: 40, 
        key: 'basicInfo',
        icon: <FaFileAlt className="text-gray-400" />,
        subItems: [
          { name: 'Projekt kategória', score: projectScore.breakdown.basicInfo >= 10 ? 10 : 0, max: 10 },
          { name: 'Alkategória', score: projectScore.breakdown.basicInfo >= 15 ? 5 : 0, max: 5 },
          { name: 'Projekt méret', score: projectScore.breakdown.basicInfo >= 20 ? 5 : 0, max: 5 },
          { name: 'Részletes projektleírás', score: projectScore.breakdown.basicInfo >= 40 ? 20 : projectScore.breakdown.basicInfo > 20 ? projectScore.breakdown.basicInfo - 20 : 0, max: 20 }
        ]
      },
      { 
        name: 'Dokumentáció', 
        score: projectScore.breakdown.documentation, 
        max: 40, 
        key: 'documentation',
        icon: <FaClipboardList className="text-gray-400" />,
        subItems: [
          { name: 'Műszaki dokumentáció', score: projectScore.breakdown.documentation >= 20 ? 20 : 0, max: 20 },
          { name: 'Referencia anyagok', score: projectScore.breakdown.documentation >= 40 ? 20 : projectScore.breakdown.documentation > 20 ? projectScore.breakdown.documentation - 20 : 0, max: 20 }
        ]
      },
      { 
        name: 'Időzítés és követelmények',
        score: projectScore.breakdown.timeline + projectScore.breakdown.requirements,
        max: 20,
        key: 'timeline',
        icon: <FaRegClock className="text-gray-400" />,
        subItems: [
          { name: 'Határidők', score: projectScore.breakdown.timeline, max: 10 },
          { name: 'Követelmények', score: projectScore.breakdown.requirements, max: 10 }
        ]
      },
      { 
        name: 'Költségvetés', 
        score: paymentScore, 
        max: 60, 
        key: 'budget',
        icon: <FaRegMoneyBillAlt className="text-gray-400" />,
        subItems: [
          { name: 'Költségvetési keretek', score: budget?.min > 0 && budget?.max > 0 ? 10 : 0, max: 10 },
          { name: 'Előlegfizetés', score: calculateAdvancePaymentScore(budget), max: 20 },
          { name: 'Biztosítás', score: getInsuranceScore(budget.insurance), max: 30 }
        ]
      },
      { 
        name: 'Kérdőív', 
        score: projectScore.breakdown.questionnaire, 
        max: 40, 
        key: 'questionnaire',
        icon: <FaQuestionCircle className="text-gray-400" />,
        subItems: [
          { name: 'Döntéshozatal', score: Math.min(20, projectScore.breakdown.questionnaire), max: 20 },
          { name: 'Együttműködés és kommunikáció', score: Math.max(0, Math.min(20, projectScore.breakdown.questionnaire - 20)), max: 20 }
        ]
      }
    ];

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Bal oldali oszlop */}
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-300">Alapinformációk és dokumentáció</div>
            {sections.slice(0, 2).map((section: ScoreSection) => (
              <div key={section.name}>
                <div className="flex items-center justify-between text-sm bg-gray-700/50 p-2 rounded group relative">
                  <div className="flex items-center gap-2">
                    {section.icon}
                    <span className="text-gray-300">{section.name}</span>
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onMouseEnter={() => setActiveTooltip(section.key)}
                      onMouseLeave={() => setActiveTooltip(null)}
                    >
                      <FaInfoCircle className="w-4 h-4" />
                    </button>
                    {activeTooltip === section.key && (
                      <div className="absolute left-0 bottom-full mb-2 w-64 p-3 bg-gray-800 rounded-lg shadow-lg z-10">
                        <h4 className="font-medium text-white mb-1">{scoreInfo[section.key].title}</h4>
                        <p className="text-gray-300 whitespace-pre-line text-xs">
                          {scoreInfo[section.key].description}
                        </p>
                      </div>
                    )}
                  </div>
                  <span className={getScoreColor(section.score / section.max * 100)}>
                    {section.score}/{section.max} pont
                  </span>
                </div>
                <div className="ml-6 mt-1 space-y-1">
                  {section.subItems.map((subItem: { name: string; score: number; max: number }, index: number) => (
                    <div key={index} className="flex items-center justify-between text-sm bg-gray-700/30 p-2 rounded">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-400">└ {subItem.name}</span>
                      </div>
                      <span className="text-gray-400">{subItem.score}/{subItem.max} pont</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Jobb oldali oszlop */}
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-300">Követelmények, költségvetés és kérdőív</div>
            {sections.slice(2).map((section: ScoreSection) => (
              <div key={section.name}>
                <div className="flex items-center justify-between text-sm bg-gray-700/50 p-2 rounded group relative">
                  <div className="flex items-center gap-2">
                    {section.icon}
                    <span className="text-gray-300">{section.name}</span>
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onMouseEnter={() => setActiveTooltip(section.key)}
                      onMouseLeave={() => setActiveTooltip(null)}
                    >
                      <FaInfoCircle className="w-4 h-4" />
                    </button>
                    {activeTooltip === section.key && (
                      <div className="absolute left-0 bottom-full mb-2 w-64 p-3 bg-gray-800 rounded-lg shadow-lg z-10">
                        <h4 className="font-medium text-white mb-1">{scoreInfo[section.key].title}</h4>
                        <p className="text-gray-300 whitespace-pre-line text-xs">
                          {scoreInfo[section.key].description}
                        </p>
                      </div>
                    )}
                  </div>
                  <span className={getScoreColor(section.score / section.max * 100)}>
                    {section.score}/{section.max} pont
                  </span>
                </div>
                <div className="ml-6 mt-1 space-y-1">
                  {section.subItems.map((subItem: { name: string; score: number; max: number }, index: number) => (
                    <div key={index} className="flex items-center justify-between text-sm bg-gray-700/30 p-2 rounded">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-400">└ {subItem.name}</span>
                      </div>
                      <span className="text-gray-400">{subItem.score}/{subItem.max} pont</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 bg-[#20A64B]/10 border border-[#20A64B]/50 rounded-lg">
      {/* Fejléc és progress bar */}
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <FaRegMoneyBillAlt className="text-[#20A64B]" />
              <span className="text-sm font-medium text-[#20A64B]">Projekt pontszám</span>
              <div className="group relative inline-block">
                <FaInfoCircle className="text-gray-400 hover:text-[#20A64B] cursor-help w-4 h-4" />
                <div className="absolute left-1/2 -translate-x-1/2 top-full mt-2 w-80 p-3 bg-gray-700 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                  <p className="text-sm text-gray-300 mb-2">
                    A projekt minőségi mutató egy 0-200 pontos skálán jelzi, hogy mennyire részletes és alapos a projekt kiírása.
                  </p>
                  <ul className="text-sm text-gray-300 space-y-1">
                    <li>• 150+ pont: Kiemelt projekt</li>
                    <li>• 100-149 pont: Részletes projekt</li>
                    <li>• 50-99 pont: Alapszintű projekt</li>
                    <li>• 0-49 pont: Hiányos projekt</li>
                  </ul>
                  <p className="text-xs text-gray-400 mt-2 border-t border-gray-600 pt-2">
                    További részletek a Fontos tudnivalók / Minőségi mutató oldalon
                  </p>
                </div>
              </div>
            </div>
            <div className={`px-3 py-1 rounded-full text-sm font-medium ${
              badge.label === 'Kiemelt Projekt' 
                ? 'bg-emerald-600 text-white' 
                : `bg-${badge.color}-500/20 text-${badge.color}-400`
            } flex items-center gap-2`}>
              {badge.label}
              {badge.label === 'Kiemelt Projekt' && (
                <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center animate-pulse">
                  <FaCheck className="w-3 h-3 text-emerald-600" />
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-right">
              <span className="text-2xl font-bold text-[#20A64B]">{totalScore}</span>
              <span className="text-sm text-gray-400">/{totalMaxPoints} pont</span>
            </div>
          </div>
        </div>

        <div className="w-full bg-gray-900/50 rounded-full h-3">
          <div 
            className="bg-[#20A64B] h-3 rounded-full transition-all duration-500"
            style={{ width: `${Math.min(100, (totalScore / totalMaxPoints) * 100)}%` }}
          ></div>
        </div>
      </div>

      {renderBreakdown()}
    </div>
  );
};