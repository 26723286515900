import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaInfoCircle, FaShieldAlt, FaChartLine, FaFileAlt } from 'react-icons/fa';

const sections = [
  {
    id: 'minosegi-mutato',
    title: 'Minőségi mutató',
    icon: <FaChartLine className="w-5 h-5" />,
    content: (
      <>
        <p className="text-gray-300 mb-4">
          A projekt minőségi mutató egy 0-200 pontos értékelési rendszer, amely segít meghatározni a projekt részletességét és alaposságát.
          A magasabb pontszám jobb láthatóságot és több lehetőséget biztosít a projekt számára.
        </p>

        <h3 className="text-lg font-medium text-white mb-3">Pontszámítás részletei</h3>
        <div className="space-y-4">
          <div>
            <h4 className="text-white mb-2">Alapinformációk (max. 30 pont)</h4>
            <ul className="list-disc list-inside text-gray-300 space-y-1">
              <li>Projekt cím és kategória: 5 pont</li>
              <li>Részletes leírás: 15 pont</li>
              <li>Projekt méret meghatározása: 5 pont</li>
              <li>Technológiai háttér: 5 pont</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white mb-2">Dokumentáció (max. 30 pont)</h4>
            <ul className="list-disc list-inside text-gray-300 space-y-1">
              <li>Csatolt dokumentumok: 10 pont</li>
              <li>Referencia anyagok: 10 pont</li>
              <li>Műszaki specifikáció: 10 pont</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white mb-2">Követelmények (max. 25 pont)</h4>
            <ul className="list-disc list-inside text-gray-300 space-y-1">
              <li>Funkcionális követelmények: 15 pont</li>
              <li>Prioritások meghatározása: 10 pont</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white mb-2">Költségvetés (max. 50 pont)</h4>
            <ul className="list-disc list-inside text-gray-300 space-y-1">
              <li>Költségkeret meghatározása: 10 pont</li>
              <li>Rugalmasság: 10 pont</li>
              <li>Előlegfizetés: 10-20 pont</li>
              <li>Biztosítás: 15-30 pont</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white mb-2">Időzítés (max. 25 pont)</h4>
            <ul className="list-disc list-inside text-gray-300 space-y-1">
              <li>Kezdési időpont: 5 pont</li>
              <li>Határidő: 10 pont</li>
              <li>Rugalmasság: 10 pont</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white mb-2">Együttműködés (max. 20 pont)</h4>
            <ul className="list-disc list-inside text-gray-300 space-y-1">
              <li>Kommunikációs preferenciák: 5 pont</li>
              <li>Elérhetőség: 5 pont</li>
              <li>Egyeztetések gyakorisága: 5 pont</li>
              <li>Döntéshozatali folyamat: 5 pont</li>
            </ul>
          </div>
        </div>

        <h3 className="text-lg font-medium text-white mt-6 mb-4">Projekt kategóriák</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gradient-to-br from-emerald-600/20 to-emerald-600/5 border border-emerald-600/20 rounded-xl p-6 relative overflow-hidden group hover:shadow-lg hover:shadow-emerald-600/10 transition-all duration-300">
            <div className="absolute top-0 right-0 w-32 h-32 bg-emerald-500/10 rounded-full -mr-16 -mt-16 transition-transform group-hover:scale-110"></div>
            <h4 className="text-emerald-400 font-medium text-lg mb-3 flex items-center gap-2">
              Kiemelt projekt (150+ pont)
            </h4>
            <p className="text-gray-300 text-sm leading-relaxed">
              Részletesen kidolgozott, professzionális projektek. Kiemelt megjelenés és magasabb láthatóság a platformon.
            </p>
            <ul className="mt-4 space-y-2 text-sm text-gray-400">
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                Kiemelt pozíció a keresési találatokban
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                Prémium projekt badge
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                Maximális láthatóság és elérés
              </li>
            </ul>
          </div>

          <div className="bg-gradient-to-br from-blue-600/20 to-blue-600/5 border border-blue-600/20 rounded-xl p-6 relative overflow-hidden group hover:shadow-lg hover:shadow-blue-600/10 transition-all duration-300">
            <div className="absolute top-0 right-0 w-32 h-32 bg-blue-500/10 rounded-full -mr-16 -mt-16 transition-transform group-hover:scale-110"></div>
            <h4 className="text-blue-400 font-medium text-lg mb-3 flex items-center gap-2">
              Részletes projekt (100-149 pont)
            </h4>
            <p className="text-gray-300 text-sm leading-relaxed">
              Jól előkészített projektek, megfelelő részletességgel és világos elvárásokkal.
            </p>
            <ul className="mt-4 space-y-2 text-sm text-gray-400">
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-blue-500"></div>
                Kiemelt megjelenés a listázásban
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-blue-500"></div>
                Részletes projekt badge
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-blue-500"></div>
                Jó láthatóság a platformon
              </li>
            </ul>
          </div>

          <div className="bg-gradient-to-br from-yellow-600/20 to-yellow-600/5 border border-yellow-600/20 rounded-xl p-6 relative overflow-hidden group hover:shadow-lg hover:shadow-yellow-600/10 transition-all duration-300">
            <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-500/10 rounded-full -mr-16 -mt-16 transition-transform group-hover:scale-110"></div>
            <h4 className="text-yellow-400 font-medium text-lg mb-3 flex items-center gap-2">
              Alapszintű projekt (50-99 pont)
            </h4>
            <p className="text-gray-300 text-sm leading-relaxed">
              Alapvető információkkal rendelkező projektek, amelyek még fejleszthetők a részletek terén.
            </p>
            <ul className="mt-4 space-y-2 text-sm text-gray-400">
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-yellow-500"></div>
                Alap megjelenés a listázásban
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-yellow-500"></div>
                Alapszintű projekt badge
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-yellow-500"></div>
                Fejlesztési javaslatok
              </li>
            </ul>
          </div>

          <div className="bg-gradient-to-br from-red-600/20 to-red-600/5 border border-red-600/20 rounded-xl p-6 relative overflow-hidden group hover:shadow-lg hover:shadow-red-600/10 transition-all duration-300">
            <div className="absolute top-0 right-0 w-32 h-32 bg-red-500/10 rounded-full -mr-16 -mt-16 transition-transform group-hover:scale-110"></div>
            <h4 className="text-red-400 font-medium text-lg mb-3 flex items-center gap-2">
              Hiányos projekt (0-49 pont)
            </h4>
            <p className="text-gray-300 text-sm leading-relaxed">
              Jelentős kiegészítésre szoruló projektek. Javasolt a részletek pontosítása a jobb eredmény érdekében.
            </p>
            <ul className="mt-4 space-y-2 text-sm text-gray-400">
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-red-500"></div>
                Korlátozott megjelenés
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-red-500"></div>
                Részletes fejlesztési útmutató
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-red-500"></div>
                Segítség a pontszám növeléséhez
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  },
  {
    id: 'biztositas',
    title: 'Biztosítás',
    icon: <FaShieldAlt className="w-5 h-5" />,
    content: (
      <>
        <p className="text-gray-300 mb-4">
          A projekt biztosítás védelmet nyújt a projekt során felmerülő problémák esetén. Két különböző szintű biztosítási csomagot kínálunk.
        </p>

        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium text-white mb-3">Alap biztosítási csomag (projekt érték 10%-a)</h3>
            <div className="bg-gray-800 rounded-lg p-4">
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Projekt mentorálás</li>
                <li>Alapszintű minőségbiztosítás</li>
                <li>Konfliktuskezelés</li>
              </ul>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium text-white mb-3">Prémium biztosítási csomag (projekt érték 20%-a)</h3>
            <div className="bg-gray-800 rounded-lg p-4">
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Minden ami az alap csomagban</li>
                <li>Új kivitelező keresése probléma esetén
                  <ul className="list-none ml-6 mt-1 text-gray-400">
                    <li>- Azonnali segítség kivitelezői problémák esetén</li>
                    <li>- Előminősített kivitelezők bevonása</li>
                    <li>- Zökkenőmentes átadás-átvétel biztosítása</li>
                  </ul>
                </li>
                <li>Szakértői támogatás
                  <ul className="list-none ml-6 mt-1 text-gray-400">
                    <li>- Senior fejlesztők és szakértők bevonása</li>
                    <li>- Architekturális tanácsadás</li>
                    <li>- Technológiai stack optimalizálás</li>
                  </ul>
                </li>
                <li>Emelt szintű minőségbiztosítás
                  <ul className="list-none ml-6 mt-1 text-gray-400">
                    <li>- Folyamatos kódminőség ellenőrzés</li>
                    <li>- Teljesítmény optimalizálási javaslatok</li>
                    <li>- Biztonsági audit és javítások</li>
                  </ul>
                </li>
                <li>Code review és technikai tanácsadás
                  <ul className="list-none ml-6 mt-1 text-gray-400">
                    <li>- Rendszeres kód átvizsgálás</li>
                    <li>- Best practice ajánlások</li>
                    <li>- Skálázhatósági szempontok vizsgálata</li>
                  </ul>
                </li>
                <li>Proaktív kockázatkezelés
                  <ul className="list-none ml-6 mt-1 text-gray-400">
                    <li>- Rendszeres kockázatelemzés</li>
                    <li>- Megelőző intézkedések kidolgozása</li>
                    <li>- Válságkezelési terv készítése</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-medium text-white mb-3">Fontos tudnivalók</h3>
            <div className="bg-gray-800 rounded-lg p-4">
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>A biztosítás díja ÁFA köteles</li>
                <li>A biztosítás díját a projekt indulása előtt kell befizetni</li>
                <li>A biztosítás a projekt teljes időtartamára érvényes</li>
                <li>A biztosítás nem visszatéríthető</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
];

export default function Info() {
  const { section } = useParams();
  const activeSection = sections.find(s => s.id === section) || sections[0];

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto py-6">
        <div className="bg-gray-800 rounded-lg">
          {/* Header */}
          <div className="p-6 border-b border-gray-700">
            <h1 className="text-2xl font-bold text-white flex items-center gap-2">
              <FaInfoCircle className="w-6 h-6 text-[#20A64B]" />
              Fontos tudnivalók
            </h1>
            <p className="text-gray-400 mt-1">
              Részletes információk a platform működéséről és szolgáltatásairól
            </p>
          </div>

          {/* Content */}
          <div className="flex flex-col md:flex-row">
            {/* Sidebar */}
            <div className="w-full md:w-64 p-6 border-b md:border-b-0 md:border-r border-gray-700">
              <nav className="space-y-1">
                {sections.map(section => (
                  <Link
                    key={section.id}
                    to={`/info/${section.id}`}
                    className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
                      activeSection.id === section.id
                        ? 'bg-[#20A64B]/20 text-[#20A64B]'
                        : 'text-gray-400 hover:bg-gray-700/50 hover:text-gray-300'
                    }`}
                  >
                    {section.icon}
                    {section.title}
                  </Link>
                ))}
              </nav>
            </div>

            {/* Main content */}
            <div className="flex-1 p-6">
              <h2 className="text-xl font-bold text-white mb-6 flex items-center gap-2">
                {activeSection.icon}
                {activeSection.title}
              </h2>
              {activeSection.content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 