import React from 'react';
import { FaUserShield } from 'react-icons/fa';

export default function GDPR() {
  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto py-6">
        <div className="bg-gray-800 rounded-lg">
          {/* Header */}
          <div className="p-6 border-b border-gray-700">
            <h1 className="text-2xl font-bold text-white flex items-center gap-2">
              <FaUserShield className="w-6 h-6 text-[#20A64B]" />
              GDPR Szabályzat
            </h1>
            <p className="text-gray-400 mt-1">
              Az EU Általános Adatvédelmi Rendeletének (GDPR) megfelelő adatkezelési szabályok
            </p>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="space-y-6 text-gray-300">
              <section>
                <h2 className="text-xl font-medium text-white mb-4">1. Az adatkezelés jogalapja</h2>
                <div className="space-y-3">
                  <p>
                    A WebTender platform adatkezelése az alábbi jogalapok szerint történik:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Az Ön kifejezett hozzájárulása (GDPR 6. cikk (1) a)</li>
                      <li>Szerződés teljesítése vagy előkészítése (GDPR 6. cikk (1) b)</li>
                      <li>Jogi kötelezettség teljesítése (GDPR 6. cikk (1) c)</li>
                      <li>Jogos érdek érvényesítése (GDPR 6. cikk (1) f)</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">2. Az érintettek jogai</h2>
                <div className="space-y-3">
                  <p>
                    A GDPR alapján Önt az alábbi jogok illetik meg személyes adatainak kezelésével kapcsolatban:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4 space-y-4">
                    <div>
                      <h3 className="text-white font-medium mb-2">Hozzáférési jog</h3>
                      <p className="text-gray-300">
                        Jogosult visszajelzést kapni arról, hogy személyes adatainak kezelése folyamatban van-e, 
                        és ha igen, akkor jogosult ezen adatokhoz hozzáférni.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-white font-medium mb-2">Helyesbítéshez való jog</h3>
                      <p className="text-gray-300">
                        Kérheti pontatlan személyes adatainak helyesbítését, illetve a hiányos adatok kiegészítését.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-white font-medium mb-2">Törléshez való jog</h3>
                      <p className="text-gray-300">
                        Bizonyos esetekben kérheti személyes adatainak törlését, például ha az adatkezelés célja megszűnt.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-white font-medium mb-2">Adatkezelés korlátozása</h3>
                      <p className="text-gray-300">
                        Kérheti az adatkezelés korlátozását, például ha vitatja az adatok pontosságát.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-white font-medium mb-2">Adathordozhatósághoz való jog</h3>
                      <p className="text-gray-300">
                        Jogosult az Önre vonatkozó adatokat tagolt, széles körben használt formátumban megkapni.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">3. Adatkezelési alapelvek</h2>
                <div className="space-y-3">
                  <p>
                    A személyes adatok kezelése során az alábbi alapelveket követjük:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Jogszerűség, tisztességes eljárás és átláthatóság</li>
                      <li>Célhoz kötöttség</li>
                      <li>Adattakarékosság</li>
                      <li>Pontosság</li>
                      <li>Korlátozott tárolhatóság</li>
                      <li>Integritás és bizalmas jelleg</li>
                      <li>Elszámoltathatóság</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">4. Adatvédelmi incidensek kezelése</h2>
                <div className="space-y-3">
                  <p>
                    Az adatvédelmi incidenst indokolatlan késedelem nélkül, legkésőbb 72 órán belül bejelentjük 
                    az illetékes felügyeleti hatóságnak, kivéve, ha az incidens valószínűsíthetően nem jár 
                    kockázattal az érintettek jogaira nézve.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Azonnali intézkedések az incidens megszüntetésére</li>
                      <li>Az érintettek értesítése szükség esetén</li>
                      <li>Az incidens dokumentálása és nyilvántartása</li>
                      <li>Megelőző intézkedések bevezetése</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">5. Adatfeldolgozók</h2>
                <div className="space-y-3">
                  <p>
                    A Platform működtetése során az alábbi kategóriákba tartozó adatfeldolgozókat vesszük igénybe:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Tárhelyszolgáltató</li>
                      <li>E-mail szolgáltató</li>
                      <li>Fizetési szolgáltató</li>
                      <li>Analitikai szolgáltatók</li>
                    </ul>
                  </div>
                  <p className="text-sm text-gray-400">
                    Az adatfeldolgozók pontos listája és elérhetőségei az adatvédelmi tisztviselőnktől kérhetők.
                  </p>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">6. Nemzetközi adattovábbítás</h2>
                <div className="space-y-3">
                  <p>
                    Az EU-n kívüli adattovábbítás esetén megfelelő garanciákat biztosítunk:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Megfelelőségi határozatok</li>
                      <li>Általános adatvédelmi kikötések</li>
                      <li>Kötelező erejű vállalati szabályok</li>
                      <li>Egyedi szerződéses feltételek</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">7. Jogorvoslati lehetőségek</h2>
                <div className="space-y-3">
                  <p>
                    Ha úgy érzi, hogy személyes adatainak kezelése nem megfelelő, az alábbi lehetőségei vannak:
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <ul className="list-disc list-inside text-gray-300 space-y-2">
                      <li>Kapcsolatfelvétel adatvédelmi tisztviselőnkkel</li>
                      <li>Panasz benyújtása a felügyeleti hatósághoz (NAIH)</li>
                      <li>Bírósági jogorvoslat kezdeményezése</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-medium text-white mb-4">8. A szabályzat módosítása</h2>
                <div className="space-y-3">
                  <p>
                    Fenntartjuk a jogot a GDPR szabályzat módosítására. A változásokról a felhasználókat 
                    előzetesen értesítjük.
                  </p>
                  <div className="bg-gray-700/50 rounded-lg p-4 text-sm">
                    <p className="text-gray-300">
                      Utolsó módosítás: 2025. február 7.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 