import React from 'react';
import { FaRegClock, FaTrash, FaInfoCircle } from 'react-icons/fa';
import { ProjectRequirement } from '../../../types/project';
import { Tooltip } from '../../../components/Tooltip';

interface RequirementsStepProps {
  requirements: ProjectRequirement[];
  setRequirements: React.Dispatch<React.SetStateAction<ProjectRequirement[]>>;
  timeline: {
    expectedStartDate?: Date;
    deadline?: Date;
    isFlexible: boolean;
  };
  setTimeline: React.Dispatch<React.SetStateAction<{
    expectedStartDate?: Date;
    deadline?: Date;
    isFlexible: boolean;
  }>>;
  validationErrors: {
    timeline?: string;
    requirements?: string;
  };
}

export const RequirementsStep: React.FC<RequirementsStepProps> = ({
  requirements,
  setRequirements,
  timeline,
  setTimeline,
  validationErrors
}) => {
  return (
    <div className="space-y-6">
      {/* Határidők */}
      <div>
        <h3 className="text-lg font-medium text-white mb-4">Határidők</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Tervezett kezdés
              {validationErrors.timeline && (
                <span className="text-red-500 text-xs ml-2">
                  {validationErrors.timeline}
                </span>
              )}
            </label>
            <div className="relative">
              <input
                type="date"
                value={timeline.expectedStartDate?.toISOString().split('T')[0] || ''}
                onChange={(e) => setTimeline(prev => ({ 
                  ...prev, 
                  expectedStartDate: e.target.value ? new Date(e.target.value) : undefined 
                }))}
                className={`w-full px-4 py-2 pl-12 rounded-lg border ${
                  validationErrors.timeline 
                    ? 'border-red-500 focus:ring-red-500' 
                    : 'border-gray-600 focus:ring-[#20A64B]'
                } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
                required
                data-error={validationErrors.timeline ? 'true' : undefined}
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaRegClock className="text-gray-400" />
              </div>
            </div>
            <p className="mt-1 text-sm text-gray-400">
              Mikortól tudná kezdeni a projektet?
            </p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Határidő
            </label>
            <div className="relative">
              <input
                type="date"
                value={timeline.deadline?.toISOString().split('T')[0] || ''}
                onChange={(e) => setTimeline(prev => ({ 
                  ...prev, 
                  deadline: e.target.value ? new Date(e.target.value) : undefined 
                }))}
                className={`w-full px-4 py-2 pl-12 rounded-lg border ${
                  validationErrors.timeline 
                    ? 'border-red-500 focus:ring-red-500' 
                    : 'border-gray-600 focus:ring-[#20A64B]'
                } bg-gray-700 text-white focus:ring-2 focus:border-transparent`}
                required
                data-error={validationErrors.timeline ? 'true' : undefined}
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaRegClock className="text-gray-400" />
              </div>
            </div>
            <p className="mt-1 text-sm text-gray-400">
              Meddig szeretné befejezni a projektet?
            </p>
          </div>
        </div>

        <div className="mt-4">
          <label className="relative inline-flex items-start gap-3 cursor-pointer group">
            <div className="flex items-center h-6">
              <input
                type="checkbox"
                checked={timeline.isFlexible}
                onChange={(e) => setTimeline(prev => ({ ...prev, isFlexible: e.target.checked }))}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[#20A64B] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray-400 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#20A64B] group-hover:after:bg-gray-300"></div>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-300">A határidő rugalmas</span>
              <p className="mt-1 text-sm text-gray-400">
                Ha a határidő rugalmas, a fejlesztők az időkeretet a projekt komplexitásához igazíthatják
              </p>
            </div>
          </label>
        </div>
      </div>

      {/* Funkcionális követelmények */}
      <div className="mt-8">
        <div className="flex items-center gap-2 mb-4">
          <h3 className="text-lg font-medium text-white flex items-center gap-2">
            Funkcionális követelmények
            <Tooltip content="A funkcionális követelmények azok a konkrét elvárások, amiket a projektnek teljesítenie kell. Például: 'Felhasználók tudjanak regisztrálni', 'Admin felület a tartalom kezeléséhez', stb. A prioritás segít meghatározni, hogy mely funkciók a legfontosabbak.">
              <FaInfoCircle className="text-gray-400 hover:text-gray-300 cursor-help w-5 h-5" />
            </Tooltip>
            {validationErrors.requirements && (
              <span className="text-red-500 text-sm ml-2">
                {validationErrors.requirements}
              </span>
            )}
          </h3>
        </div>
        <div className="space-y-4">
          {requirements.length === 0 && (
            <div className="text-red-400 text-sm mb-4">
              Kérjük, adjon meg legalább egy követelményt!
            </div>
          )}
          {requirements.map((req, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-[1fr,auto,auto] gap-4">
              <div>
                <input
                  type="text"
                  value={req.description}
                  onChange={(e) => setRequirements(prev => prev.map((r, i) => i === index ? { ...r, description: e.target.value } : r))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
                  placeholder="Pl.: Felhasználói regisztráció és bejelentkezés"
                  required
                />
              </div>
              <select
                value={req.priority}
                onChange={(e) => setRequirements(prev => prev.map((r, i) => i === index ? { ...r, priority: e.target.value as 'must' | 'should' | 'nice' } : r))}
                className="px-4 py-2 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
              >
                <option value="must">Must have</option>
                <option value="should">Should have</option>
                <option value="nice">Nice to have</option>
              </select>
              <button
                type="button"
                onClick={() => setRequirements(prev => prev.filter((_, i) => i !== index))}
                className="px-3 py-2 text-gray-400 hover:text-red-500 transition-colors"
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => setRequirements(prev => [...prev, { description: '', priority: 'must', category: 'functional' }])}
            className="mt-4 px-4 py-2 bg-[#20A64B]/20 hover:bg-[#20A64B]/30 text-[#20A64B] transition-colors flex items-center gap-2 rounded-lg"
          >
            + Követelmény hozzáadása
          </button>
        </div>
      </div>
    </div>
  );
}; 