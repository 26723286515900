import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, query, where, getDocs, collectionGroup } from 'firebase/firestore';
import { Project, Proposal, ProjectAttachment, ProjectBudget, ProjectTimeline, ProjectRequirement, ProjectQuestionnaire, ProjectDetails as ProjectDetailsType } from '../../types/project';
import { motion } from 'framer-motion';
import { 
  FaPlus, 
  FaClock, 
  FaCheckCircle, 
  FaExclamationCircle,
  FaSearch,
  FaFilter,
  FaStar,
  FaLeaf,
  FaInfoCircle
} from 'react-icons/fa';
import { Timestamp } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { calculateProjectScore, getProjectBadge } from '../../utils/projectScoring';

interface ProjectData extends Omit<Project, 'createdAt'> {
  createdAt: Timestamp;
}

export default function MyProjects() {
  const { user, activeProfile } = useAuth();
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      if (!user?.uid || !activeProfile?.type) return;
      
      try {
        const projectsRef = collection(db, 'projects');
        let projectsData: ProjectData[] = [];

        if (activeProfile.type === 'client') {
          const q = query(
            projectsRef,
            where('clientId', '==', user.uid)
          );
          const querySnapshot = await getDocs(q);
          projectsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as ProjectData[];
        } else {
          // Kivitelezői nézet: lekérjük az elfogadott ajánlatokat
          const proposalsRef = collection(db, 'proposals');
          const proposalsQuery = query(
            proposalsRef,
            where('contractorId', '==', user.uid),
            where('status', '==', 'accepted')
          );
          const proposalsSnapshot = await getDocs(proposalsQuery);
          
          // Összegyűjtjük a projekteket az elfogadott ajánlatok alapján
          const projectIds = proposalsSnapshot.docs.map(doc => doc.data().projectId);
          
          if (projectIds.length > 0) {
            // Több lekérdezést kell végeznünk, mert a Firestore nem támogatja a "where in" operátort 10-nél több elemmel
            const chunks = [];
            for (let i = 0; i < projectIds.length; i += 10) {
              chunks.push(projectIds.slice(i, i + 10));
            }

            const projectPromises = chunks.map(chunk => {
              const q = query(
                projectsRef,
                where('id', 'in', chunk)
              );
              return getDocs(q);
            });

            const projectSnapshots = await Promise.all(projectPromises);
            projectsData = projectSnapshots.flatMap(snapshot => 
              snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }))
            ) as ProjectData[];
          }
        }
        
        setProjects(projectsData);
      } catch (error) {
        console.error('Error fetching projects:', error);
        toast.error('Hiba történt a projektek betöltése során');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [user?.uid, activeProfile?.type]);

  const filteredProjects = projects
    .filter(project => {
      if (filter === 'all') return true;
      return project.status === filter;
    })
    .filter(project =>
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'draft': return 'text-gray-400';
      case 'active': return 'text-blue-400';
      case 'completed': return 'text-green-400';
      case 'cancelled': return 'text-red-400';
      default: return 'text-gray-400';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'draft': return <FaClock />;
      case 'active': return <FaCheckCircle />;
      case 'completed': return <FaCheckCircle />;
      case 'cancelled': return <FaExclamationCircle />;
      default: return <FaClock />;
    }
  };

  const formatDate = (timestamp: Timestamp) => {
    return new Date(timestamp.seconds * 1000).toLocaleDateString('hu-HU');
  };

  const getProjectUrl = (projectId: string) => {
    return activeProfile?.type === 'client' 
      ? `/client/projects/${projectId}`
      : `/contractor/projects/${projectId}`;
  };

  const getProjectScore = (project: ProjectData) => {
    const projectForScoring: Partial<Project> = {
      ...project,
      createdAt: project.createdAt instanceof Timestamp ? project.createdAt.toDate() : project.createdAt,
      updatedAt: project.updatedAt instanceof Timestamp ? project.updatedAt.toDate() : project.updatedAt,
      budget: project.budget as ProjectBudget,
      timeline: project.timeline as ProjectTimeline,
      requirements: project.requirements as ProjectRequirement[],
      questionnaire: project.questionnaire as ProjectQuestionnaire,
      details: project.details as ProjectDetailsType,
      attachments: project.attachments as ProjectAttachment[]
    };
    
    const score = calculateProjectScore(projectForScoring);
    const badge = getProjectBadge(score.total);
    
    return (
      <div className="flex items-center gap-2">
        <span className="text-[#20A64B] font-medium">{score.total}/200</span>
        {badge.label === 'Kiemelt Projekt' ? (
          <div className="flex items-center gap-2 px-3 py-1 bg-gradient-to-r from-emerald-500/20 to-[#20A64B]/20 border border-emerald-500/20 rounded-full">
            <span className="w-1.5 h-1.5 rounded-full bg-emerald-500 animate-pulse"></span>
            <span className="text-xs font-medium bg-gradient-to-r from-emerald-400 to-[#20A64B] bg-clip-text text-transparent">
              {badge.label}
            </span>
          </div>
        ) : (
          <span className={`px-3 py-1 rounded-full text-xs font-medium 
            ${badge.color === 'blue' ? 'bg-blue-500/20 text-blue-400' : 
              badge.color === 'yellow' ? 'bg-yellow-500/20 text-yellow-400' : 
              'bg-red-500/20 text-red-400'}`}>
            {badge.label}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="bg-gray-800 rounded-lg p-6">
          {/* Header */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
            <div>
              <h1 className="text-2xl font-bold text-white">
                {activeProfile?.type === 'client' ? 'Kiírt projektjeim' : 'Vállalt projektjeim'}
              </h1>
              <p className="text-gray-400 mt-1">
                {activeProfile?.type === 'client' 
                  ? 'Kezelje és kövesse nyomon kiírt projektjeit'
                  : 'Kezelje és kövesse nyomon vállalt projektjeit'
                }
              </p>
            </div>
            {activeProfile?.type === 'client' && (
              <Link
                to="/client/projects/create"
                className="flex items-center gap-2 px-4 py-2 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors"
              >
                <FaPlus className="w-4 h-4" />
                Új projekt
              </Link>
            )}
          </div>

          {/* Szűrők és keresés */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="relative">
              <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Keresés a projektekben..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
              />
            </div>
            <div className="flex items-center gap-4">
              <FaFilter className="text-gray-400" />
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="flex-1 px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-[#20A64B] focus:border-transparent"
              >
                <option value="all">Minden projekt</option>
                <option value="draft">Vázlatok</option>
                <option value="active">Aktív projektek</option>
                <option value="completed">Befejezett projektek</option>
                <option value="cancelled">Törölt projektek</option>
              </select>
            </div>
          </div>

          {/* Projektek lista */}
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#20A64B] mx-auto"></div>
            </div>
          ) : filteredProjects.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.map(project => {
                return (
                  <motion.div
                    key={project.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gray-700 rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300"
                  >
                    <Link to={getProjectUrl(project.id)}>
                      <div className="p-6">
                        <div className="flex items-start justify-between mb-4">
                          <div className="flex items-start gap-2">
                            <h3 className="text-lg font-semibold text-white">{project.title}</h3>
                            {project.isGreenfield && (
                              <div className="group relative inline-block">
                                <FaLeaf className="text-[#20A64B] w-4 h-4 cursor-help" />
                                <div className="absolute left-0 top-full mt-2 px-3 py-2 bg-gray-800 text-sm text-white rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all w-48 text-center shadow-lg z-[60]">
                                  <div className="flex items-center gap-2 justify-center mb-1">
                                    <FaLeaf className="text-[#20A64B] w-4 h-4" />
                                    <span className="font-medium">Zöldmezős projekt</span>
                                  </div>
                                  <p className="text-gray-400 text-xs">Ez egy új projekt, amelyen még nem dolgozott más kivitelező.</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <span className={`flex items-center gap-1 ${getStatusColor(project.status)}`}>
                            {getStatusIcon(project.status)}
                            <span className="text-sm">
                              {project.status === 'draft' && 'Vázlat'}
                              {project.status === 'active' && 'Aktív'}
                              {project.status === 'completed' && 'Befejezett'}
                              {project.status === 'cancelled' && 'Törölt'}
                            </span>
                          </span>
                        </div>
                        <p className="text-gray-400 text-sm mb-4 line-clamp-2">
                          {project.description}
                        </p>
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center justify-between text-sm">
                            <span className="text-gray-400">
                              {formatDate(project.createdAt)}
                            </span>
                            <span className="text-[#20A64B]">
                              {project.budget?.min.toLocaleString()} - {project.budget?.max.toLocaleString()} Ft
                            </span>
                          </div>
                          <div className="flex items-center justify-between text-sm pt-2 border-t border-gray-600">
                            <div className="flex items-center gap-1">
                              <FaStar className="text-[#20A64B]" />
                              {getProjectScore(project)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-400">
                {activeProfile?.type === 'client' 
                  ? 'Még nem írt ki projektet'
                  : 'Még nem vállalt projektet'
                }
                {searchTerm && ' a keresési feltételekkel'}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 