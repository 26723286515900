import { ProjectCategory, CategoryInfo } from '../types/project';
import { FaRegClock, FaRegLightbulb, FaRegBuilding } from 'react-icons/fa';

export const PROJECT_SIZES = [
  {
    value: 'small',
    label: 'Kis projekt',
    icon: FaRegLightbulb,
    duration: '1-3 hónap',
    description: 'Egyszerűbb, kisebb volumenű projektek',
    examples: [
      'Egyszerű weboldal',
      'Landing page',
      'Kisebb módosítások',
      'Egyszerű funkciók implementálása'
    ]
  },
  {
    value: 'medium',
    label: 'Közepes projekt',
    icon: FaRegClock,
    duration: '3-6 hónap',
    description: 'Összetettebb, több funkciót tartalmazó projektek',
    examples: [
      'Webáruház',
      'Vállalati weboldal',
      'Mobilalkalmazás',
      'Komplex rendszerintegráció'
    ]
  },
  {
    value: 'large',
    label: 'Nagy projekt',
    icon: FaRegBuilding,
    duration: '6+ hónap',
    description: 'Nagyméretű, komplex rendszerek',
    examples: [
      'Vállalatirányítási rendszer',
      'Többplatformos alkalmazás',
      'Egyedi fejlesztésű keretrendszer',
      'Nagy adatforgalmú rendszerek'
    ]
  }
];

export const CATEGORIES: CategoryInfo[] = [
    { 
      value: 'web_development', 
      label: 'Webfejlesztés',
      subcategories: [
        'Webáruház',
        'Céges weboldal',
        'Landing page',
        'Web alkalmazás',
        'Egyedi CMS rendszer'
      ],
      additionalInfo: {
        requirements: [
          'Domain név és tárhely',
          'Arculati elemek és design',
          'Tartalmak és szövegek',
          'SSL tanúsítvány',
          'Adminisztrációs igények',
          'SEO elvárások'
        ],
        features: [
          'Reszponzív design',
          'Keresőoptimalizálás',
          'Teljesítmény optimalizálás',
          'Biztonságos működés',
          'Adminisztrációs felület',
          'Analytics integráció'
        ],
        considerations: [
          'Célközönség meghatározása',
          'Böngésző kompatibilitás',
          'Mobilbarát megjelenés',
          'Tartalom frissítési igények',
          'Látogatói elvárások',
          'Konverziós célok'
        ]
      }
    },
    { 
      value: 'software_development', 
      label: 'Egyedi szoftverfejlesztés',
      subcategories: [
        'Asztali alkalmazás',
        'Vállalatirányítási rendszer (ERP)',
        'Ügyfélkezelő rendszer (CRM)',
        'Automatizációs szoftver',
        'Adatelemző alkalmazás',
        'Integrációs megoldások'
      ],
      additionalInfo: {
        requirements: [
          'Részletes folyamatok leírása',
          'Adatstruktúra meghatározása',
          'Jogosultsági szintek',
          'Integrációs pontok',
          'Teljesítmény elvárások',
          'Biztonsági követelmények'
        ],
        phases: [
          'Igényfelmérés és tervezés',
          'Prototípus készítés',
          'Fejlesztés és tesztelés',
          'Bevezetés és oktatás',
          'Támogatás és karbantartás'
        ],
        considerations: [
          'Skálázhatóság',
          'Adatbiztonság',
          'Felhasználói képzés',
          'Dokumentáció',
          'Verziókövetés',
          'Támogatási időszak'
        ]
      }
    },
    { 
      value: 'mobile_app', 
      label: 'Mobilalkalmazás fejlesztés',
      subcategories: [
        'iOS natív fejlesztés',
        'Android natív fejlesztés',
        'Cross-platform fejlesztés (Flutter)',
        'Cross-platform fejlesztés (React Native)',
        'PWA fejlesztés',
        'App Store publikálás és menedzsment',
        'Play Store publikálás és menedzsment',
        'App optimalizálás (ASO)',
        'In-app vásárlások beállítása',
        'Push értesítések implementálása',
        'App analitika beállítása',
        'Meglévő app továbbfejlesztése',
        'App Store / Play Store megfelelőség audit',
        'App biztonság és GDPR megfelelőség',
        'App teljesítmény optimalizálás',
        'App UX/UI tervezés',
        'App tesztelés és QA',
        'App karbantartás és support'
      ],
      additionalInfo: {
        publishingRequirements: [
          'Apple Developer Program tagság',
          'Google Play Developer fiók',
          'Adatvédelmi nyilatkozat',
          'Alkalmazás ikonok és képernyőképek',
          'App Store / Play Store leírások',
          'Korhatár besorolás',
          'GDPR megfelelőség',
          'Használati feltételek'
        ],
        commonServices: [
          'Áruházi fiók létrehozása és beállítása',
          'Alkalmazás metaadatok optimalizálása',
          'Képernyőképek és promóciós anyagok készítése',
          'Verziófrissítések kezelése',
          'Felhasználói visszajelzések kezelése',
          'Hibajelentések monitorozása',
          'Statisztikák és analitika beállítása'
        ]
      }
    },
    { 
      value: 'maintenance', 
      label: 'Üzemeltetés és karbantartás',
      subcategories: [
        'Rendszerüzemeltetés',
        'Szerverkarbantartás',
        'Biztonsági audit',
        'Teljesítmény optimalizálás',
        'Monitoring rendszer',
        'Hibaelhárítás'
      ],
      additionalInfo: {
        services: [
          'Rendszeres biztonsági mentés',
          'Teljesítmény monitorozás',
          'Biztonsági frissítések',
          'Hibaelhárítás',
          'Terheléselosztás',
          '24/7 felügyelet'
        ],
        sla: [
          'Garantált rendelkezésre állás',
          'Reakcióidő vállalás',
          'Hibajavítási határidők',
          'Rendszeres jelentések',
          'Tervezett karbantartások'
        ],
        tools: [
          'Monitoring rendszerek',
          'Hibajegy kezelés',
          'Teljesítmény analitika',
          'Biztonsági eszközök',
          'Automatizált ellenőrzések'
        ]
      }
    },
    { 
      value: 'wordpress', 
      label: 'WordPress fejlesztés',
      subcategories: [
        'Weboldal készítés',
        'Plugin fejlesztés',
        'Téma fejlesztés',
        'WooCommerce webshop',
        'Testreszabás és optimalizálás'
      ],
      additionalInfo: {
        requirements: [
          'WordPress verzió és kompatibilitás',
          'Téma preferenciák',
          'Szükséges bővítmények',
          'Tartalom migráció',
          'SEO beállítások',
          'Biztonsági elvárások'
        ],
        features: [
          'Reszponzív design',
          'Egyedi sablonok',
          'Bővítmény testreszabás',
          'Többnyelvűség',
          'Teljesítmény optimalizálás',
          'Biztonsági beállítások'
        ],
        maintenance: [
          'Rendszeres frissítések',
          'Biztonsági mentések',
          'Teljesítmény monitorozás',
          'Tartalom frissítés',
          'Hibajavítás',
          'SEO karbantartás'
        ]
      }
    },
    { 
      value: 'ui_design', 
      label: 'UI/UX tervezés',
      subcategories: [
        'Felhasználói felület tervezés',
        'Prototípus készítés',
        'Használhatósági audit',
        'Design rendszer készítés'
      ],
      additionalInfo: {
        deliverables: [
          'Wireframe-ek',
          'Interaktív prototípus',
          'Design rendszer',
          'Asset-ek és ikonok',
          'Reszponzív verziók',
          'Dokumentáció'
        ],
        process: [
          'Felhasználói kutatás',
          'Információs architektúra',
          'Wireframing',
          'Visual design',
          'Prototípus készítés',
          'Használhatósági tesztelés'
        ],
        tools: [
          'Figma / Adobe XD',
          'InVision / Prototype',
          'Zeplin / Avocode',
          'UserTesting',
          'Analytics tools',
          'Dokumentációs eszközök'
        ]
      }
    },
    { 
      value: 'graphic_design', 
      label: 'Grafikai tervezés',
      subcategories: [
        'Logó tervezés',
        'Arculattervezés',
        'Marketing anyagok',
        'Illusztrációk'
      ]
    },
    { 
      value: 'marketing', 
      label: 'Marketing',
      subcategories: [
        'SEO optimalizálás',
        'Google Ads kampány',
        'Social media marketing',
        'Email marketing',
        'Tartalommarketing',
        'Konverzióoptimalizálás',
        'Analitika és riportok'
      ],
      additionalInfo: {
        services: [
          'Keresőoptimalizálás (SEO)',
          'Google Ads kampányok',
          'Social media marketing',
          'Email marketing',
          'Tartalommarketing',
          'Konverzióoptimalizálás',
          'Analitika és riportok'
        ],
        deliverables: [
          'Marketing stratégia',
          'Kampány tervek',
          'Teljesítmény riportok',
          'ROI elemzések',
          'Versenytárs analízis',
          'Célcsoport elemzés'
        ],
        metrics: [
          'Konverziós ráta',
          'Látogatói forgalom',
          'Költség per konverzió',
          'Visszafordulási arány',
          'Elérés és engagement',
          'Értékesítési mutatók'
        ]
      }
    },
    { 
      value: 'testing_qa', 
      label: 'Tesztelés és minőségbiztosítás',
      subcategories: [
        'Funkcionális tesztelés',
        'Automatizált tesztelés',
        'Teljesítmény tesztelés',
        'Biztonsági audit',
        'Használhatósági tesztelés',
        'Kompatibilitás tesztelés',
        'Regressziós tesztelés',
        'API tesztelés'
      ],
      additionalInfo: {
        testTypes: [
          'Funkcionális tesztelés',
          'Regressziós tesztelés',
          'Teljesítmény tesztelés',
          'Biztonsági tesztelés',
          'Használhatósági tesztelés',
          'Automatizált tesztelés'
        ],
        tools: [
          'Selenium / Cypress',
          'JMeter / LoadRunner',
          'JIRA / TestRail',
          'Postman / SoapUI',
          'BrowserStack',
          'Jenkins / GitLab CI'
        ],
        documentation: [
          'Tesztesetek',
          'Tesztjelentések',
          'Hibajelentések',
          'Teljesítmény riportok',
          'Követhetőségi mátrix',
          'Ajánlások és javítások'
        ]
      }
    },
    { 
      value: 'data_analytics', 
      label: 'Adatelemzés és BI',
      subcategories: [
        'Adatvizualizáció',
        'Dashboard fejlesztés',
        'Riportok készítése',
        'Adatbázis optimalizálás',
        'Big Data megoldások',
        'Prediktív analitika',
        'Machine Learning'
      ],
      additionalInfo: {
        solutions: [
          'Adatvizualizáció',
          'Prediktív analitika',
          'Valós idejű dashboardok',
          'Automatizált riportok',
          'Adatbányászat',
          'Machine learning modellek'
        ],
        dataTypes: [
          'Tranzakciós adatok',
          'Ügyfél adatok',
          'Webanalitika',
          'Szenzor adatok',
          'Pénzügyi adatok',
          'Marketing adatok'
        ],
        tools: [
          'Power BI / Tableau',
          'Python / R',
          'SQL / NoSQL',
          'TensorFlow / PyTorch',
          'Apache Spark',
          'ETL eszközök'
        ]
      }
    },
    { 
      value: 'security', 
      label: 'IT Biztonság',
      subcategories: [
        'Penetrációs tesztelés',
        'Biztonsági audit',
        'Sérülékenység vizsgálat',
        'Biztonsági tanácsadás',
        'Incidens kezelés',
        'Megfelelőségi vizsgálat',
        'Biztonsági monitoring'
      ],
      additionalInfo: {
        services: [
          'Biztonsági audit',
          'Penetrációs tesztelés',
          'Sérülékenység vizsgálat',
          'Biztonsági monitoring',
          'Incidens kezelés',
          'Kockázatelemzés',
          'Megfelelőségi vizsgálat'
        ],
        requirements: [
          'Rendszerek és hálózatok dokumentációja',
          'Hozzáférési jogosultságok listája',
          'Jelenlegi biztonsági megoldások',
          'Korábbi incidensek története',
          'Compliance követelmények',
          'Üzleti kritikus rendszerek'
        ],
        deliverables: [
          'Biztonsági jelentés',
          'Kockázati elemzés',
          'Javítási javaslatok',
          'Incidens kezelési terv',
          'Biztonsági szabályzatok',
          'Oktatási anyagok'
        ]
      }
    },
    { 
      value: 'integration', 
      label: 'Rendszerintegráció',
      subcategories: [
        'API fejlesztés',
        'Rendszerek összekapcsolása',
        'Adatmigráció',
        'Legacy rendszer modernizáció',
        'Mikroszolgáltatások fejlesztése',
        'Cloud migráció',
        'Workflow automatizálás'
      ],
      additionalInfo: {
        phases: [
          'Rendszerek felmérése',
          'Architektúra tervezés',
          'API fejlesztés',
          'Adatmigráció',
          'Tesztelés és validáció',
          'Éles üzembe helyezés'
        ],
        considerations: [
          'Rendszerek kompatibilitása',
          'Adatformátumok és protokollok',
          'Teljesítmény követelmények',
          'Skálázhatóság',
          'Hibakezelés és monitoring',
          'Biztonsági szempontok'
        ],
        technologies: [
          'REST/SOAP API-k',
          'Message Queue rendszerek',
          'ETL eszközök',
          'Mikroszolgáltatások',
          'Konténerizáció',
          'CI/CD pipeline'
        ]
      }
    },
    { 
      value: 'consulting', 
      label: 'IT Tanácsadás',
      subcategories: [
        'Technológiai stratégia',
        'Architektúra tervezés',
        'Projektmenedzsment',
        'Agilis transzformáció',
        'DevOps tanácsadás',
        'Digitális transzformáció',
        'IT audit'
      ],
      additionalInfo: {
        services: [
          'Digitális stratégia kialakítása',
          'Technológiai audit',
          'Folyamatoptimalizálás',
          'Agilis transzformáció',
          'DevOps tanácsadás',
          'IT biztonsági tanácsadás',
          'Architektúra tervezés'
        ],
        deliverables: [
          'Stratégiai terv',
          'Audit jelentés',
          'Folyamatleírások',
          'Implementációs ütemterv',
          'Költség-haszon elemzés',
          'Kockázatelemzés',
          'Ajánlások és roadmap'
        ],
        benefits: [
          'Hatékonyabb működés',
          'Költségoptimalizálás',
          'Versenyképesség növelése',
          'Kockázatok csökkentése',
          'Innovációs képesség fejlesztése',
          'Digitális érettség növelése',
          'Jobb döntéshozatal'
        ]
      }
    },
    { 
      value: 'ai_integration', 
      label: 'AI és ML Integráció',
      subcategories: [
        'ChatGPT integráció',
        'Egyedi AI chatbot fejlesztés',
        'Képfelismerés és feldolgozás',
        'Szövegfeldolgozás (NLP)',
        'Prediktív analitika',
        'Ajánlórendszer fejlesztés',
        'Automatizált dokumentum feldolgozás',
        'Beszédfelismerés és szintézis',
        'Gépi tanulás integráció',
        'Computer Vision megoldások',
        'AI alapú döntéstámogatás',
        'Sentiment analízis',
        'AI alapú keresés és szűrés',
        'Automatizált tartalomgenerálás'
      ],
      additionalInfo: {
        requirements: [
          'API kulcsok és hozzáférések',
          'Adatforrások meghatározása',
          'Adatvédelmi megfelelőség',
          'Modell típus kiválasztása',
          'Teljesítményelvárások',
          'Használati korlátok',
          'Költségkeretek'
        ],
        commonUses: [
          'Ügyfélszolgálat automatizálás',
          'Tartalom moderálás',
          'Dokumentum feldolgozás',
          'Személyre szabott ajánlások',
          'Folyamatoptimalizálás',
          'Minőségellenőrzés',
          'Előrejelzések készítése'
        ],
        considerations: [
          'API költségek és limitek',
          'Adatminőség és mennyiség',
          'Válaszidő elvárások',
          'Skálázhatóság',
          'Etikai megfontolások',
          'Adatvédelem és GDPR',
          'Modell frissítések kezelése'
        ]
      }
    },
    {
      value: 'video',
      label: 'Videókészítés',
      subcategories: [
        'Promóciós videók',
        'Termékbemutatók',
        'Animációk',
        'Oktatóvideók',
        'Eseményfelvételek',
        'Drónfelvételek'
      ],
      additionalInfo: {
        services: [
          'Forgatás és utómunka',
          'Motion graphics',
          '2D/3D animáció',
          'Hangkeverés',
          'Feliratozás',
          'Színkorrekció'
        ],
        equipment: [
          'Professzionális kamerák',
          'Világítástechnika',
          'Hangrögzítő eszközök',
          'Drónok',
          'Stabilizátorok',
          'Utómunka szoftverek'
        ]
      }
    },
    {
      value: 'backend',
      label: 'Backend fejlesztés',
      subcategories: [
        'API fejlesztés',
        'Adatbázis tervezés',
        'Szerveroldali alkalmazások',
        'Mikroszolgáltatások',
        'Cloud architektúra',
        'Teljesítmény optimalizálás'
      ],
      additionalInfo: {
        technologies: [
          'Node.js / Express',
          'Python / Django / Flask',
          'Java / Spring',
          'PHP / Laravel',
          '.NET Core',
          'GraphQL'
        ],
        databases: [
          'PostgreSQL',
          'MySQL',
          'MongoDB',
          'Redis',
          'Elasticsearch',
          'DynamoDB'
        ]
      }
    },
    {
      value: 'ecommerce',
      label: 'E-commerce',
      subcategories: [
        'Webáruház fejlesztés',
        'Fizetési rendszer integráció',
        'Raktárkezelés',
        'Szállítási integráció',
        'Többnyelvű webshop',
        'Marketplace integráció'
      ],
      additionalInfo: {
        features: [
          'Termékkezelés',
          'Kosár és checkout folyamat',
          'Fizetési gateway-ek',
          'Készletkezelés',
          'Rendeléskezelés',
          'Ügyfélkezelés'
        ]
      }
    },
    {
      value: 'game_dev',
      label: 'Játékfejlesztés',
      subcategories: [
        'Mobil játékok',
        'PC játékok',
        'Web alapú játékok',
        'VR/AR játékok',
        'Oktatójátékok',
        'Játék portolás'
      ],
      additionalInfo: {
        engines: [
          'Unity',
          'Unreal Engine',
          'Godot',
          'HTML5 Canvas',
          'WebGL',
          'Native fejlesztés'
        ],
        services: [
          'Játéktervezés',
          'Grafika és animáció',
          'Játék fizika',
          'Multiplayer fejlesztés',
          'Játék optimalizálás',
          'Monetizáció tervezés'
        ]
      }
    },
    {
      value: 'desktop_app',
      label: 'Asztali alkalmazás',
      subcategories: [
        'Windows alkalmazások',
        'macOS alkalmazások',
        'Linux alkalmazások',
        'Cross-platform alkalmazások',
        'Natív alkalmazások',
        'Electron alkalmazások'
      ],
      additionalInfo: {
        technologies: [
          '.NET / WPF',
          'Electron',
          'Qt',
          'Java Swing/JavaFX',
          'Python (PyQt/Tkinter)',
          'C++/wxWidgets'
        ],
        features: [
          'Natív UI elemek',
          'Offline működés',
          'Automatikus frissítés',
          'Rendszerintegráció',
          'Hardver hozzáférés',
          'Teljesítmény optimalizálás'
        ]
      }
    },
    {
      value: 'seo',
      label: 'SEO',
      subcategories: [
        'Keresőoptimalizálás',
        'Kulcsszókutatás',
        'Technikai SEO audit',
        'Linképítés',
        'Tartalomoptimalizálás',
        'Helyi SEO',
        'E-commerce SEO'
      ],
      additionalInfo: {
        services: [
          'Weboldal audit',
          'Versenytárselemzés',
          'On-page optimalizálás',
          'Off-page optimalizálás',
          'SEO stratégia készítés',
          'Teljesítménymérés'
        ],
        deliverables: [
          'SEO audit jelentés',
          'Kulcsszókutatási elemzés',
          'Havi teljesítményjelentés',
          'Technikai ajánlások',
          'Tartalom optimalizációs terv',
          'Rangsorolási jelentések'
        ]
      }
    },
    {
      value: 'devops',
      label: 'DevOps',
      subcategories: [
        'CI/CD pipeline kialakítás',
        'Konténerizáció',
        'Infrastruktúra automatizálás',
        'Monitoring rendszerek',
        'Cloud architektúra',
        'Teljesítmény optimalizálás'
      ],
      additionalInfo: {
        technologies: [
          'Docker / Kubernetes',
          'Jenkins / GitLab CI',
          'Terraform / Ansible',
          'AWS / Azure / GCP',
          'Prometheus / Grafana',
          'ELK Stack'
        ],
        services: [
          'Infrastruktúra tervezés',
          'Automatizálás',
          'Monitorozás beállítása',
          'Biztonsági konfiguráció',
          'Teljesítmény optimalizálás',
          'Incident management'
        ]
      }
    }
  ] as const;
  