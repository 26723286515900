import { useAuth } from '../context/AuthContext';
import { Navigate, Link } from 'react-router-dom';
import ProfileSetup from '../components/ProfileSetup';
import { 
  FaTools,      // Kivitelező ikon
  FaClipboardList, // Projekt lista ikon
  FaPlus,       // Új projekt ikon
  FaSearch,     // Keresés ikon
  FaTasks,      // Feladatok ikon
  FaUserCog,    // Profil beállítások
  FaChartLine,  // Statisztikák
  FaComments,   // Üzenetek
  FaBriefcase,  // Referenciák
  FaCog,        // Beállítások
  FaList,       // Lista ikon
  FaUserTie,    // Profil ikon
  FaFileAlt,    // Ajánlatok ikon
  FaInbox      // Beérkezett ajánlatok ikon
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Dashboard() {
  const { user, activeProfile } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (!activeProfile) {
    return (
      <div className="w-full px-4">
        <div className="max-w-[1920px] mx-auto">
          <div className="bg-gray-800 rounded-lg p-8 text-center">
            <div className="max-w-2xl mx-auto">
              <h1 className="text-3xl font-bold text-white mb-6">
                Üdvözöljük a WebTender-en!
              </h1>
              <p className="text-gray-300 text-lg mb-8">
                A folytatáshoz kérjük, hozza létre első profilját. Válassza ki, hogy megrendelőként vagy kivitelezőként szeretne regisztrálni.
              </p>
              <button
                onClick={() => navigate('/profile')}
                className="inline-flex items-center px-6 py-3 bg-[#20A64B] hover:bg-[#178f3f] text-white rounded-lg transition-colors text-lg"
              >
                <FaUserTie className="mr-2" />
                Profil létrehozása
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const DashboardTile = ({ 
    icon: Icon, 
    title, 
    description, 
    to,
    primary = false 
  }: { 
    icon: React.ComponentType<{ className?: string }>,
    title: string, 
    description: string, 
    to?: string,
    primary?: boolean 
  }) => {
    const content = (
      <>
        <div className="relative z-10 flex flex-col items-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gray-800/20">
            <Icon className={`text-4xl ${primary ? 'text-white' : 'text-[#20A64B]'}`} />
          </div>
          <h3 className="text-xl font-semibold mb-3">{title}</h3>
          <p className="text-sm opacity-90 max-w-[200px] text-center">{description}</p>
        </div>
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity rounded-xl" />
      </>
    );

    const className = `
      relative overflow-hidden group rounded-xl transition-all duration-300
      ${primary 
        ? 'bg-gradient-to-br from-[#20A64B] to-[#178f3f] text-white shadow-lg hover:shadow-xl' 
        : 'bg-gray-700 hover:bg-gray-600 text-gray-100'
      }
      p-6 flex flex-col items-center justify-center text-center h-full transform hover:scale-[1.02]
    `;

    if (to) {
      return (
        <Link to={to} className={className}>
          {content}
        </Link>
      );
    }

    return (
      <div className={className}>
        {content}
      </div>
    );
  };

  return (
    <div className="w-full px-4">
      <div className="max-w-[1920px] mx-auto">
        <div className="bg-gray-800 rounded-lg p-6">
          <h1 className="text-2xl font-bold text-white mb-8">Vezérlőpult</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {activeProfile?.type === 'contractor' ? (
              <>
                <DashboardTile
                  icon={FaSearch}
                  title="Új ajánlatok böngészése"
                  description="Tekintse meg a legújabb projekteket és adjon ajánlatot"
                  to="/contractor/projects/browse"
                  primary
                />
                <DashboardTile
                  icon={FaFileAlt}
                  title="Ajánlataim"
                  description="Beküldött ajánlatok kezelése és nyomon követése"
                  to="/contractor/proposals"
                />
                <DashboardTile
                  icon={FaTasks}
                  title="Aktív projektjeim"
                  description="Folyamatban lévő munkák és feladatok kezelése"
                  to="/contractor/projects/active"
                />
                <DashboardTile
                  icon={FaChartLine}
                  title="Statisztikák"
                  description="Teljesítmény mutatók és bevételek követése"
                />
                <DashboardTile
                  icon={FaComments}
                  title="Üzenetek"
                  description="Kommunikáció az ügyfelekkel"
                />
                <DashboardTile
                  icon={FaBriefcase}
                  title="Referenciák"
                  description="Korábbi munkák és értékelések"
                  to="/profile"
                />
                <DashboardTile
                  icon={FaCog}
                  title="Beállítások"
                  description="Profil és szolgáltatások kezelése"
                  to="/profile"
                />
              </>
            ) : (
              <>
                <DashboardTile
                  icon={FaPlus}
                  title="Új projekt létrehozása"
                  description="Indítson új projektet és kérjen ajánlatokat"
                  to="/client/projects/create"
                  primary
                />
                <DashboardTile
                  icon={FaInbox}
                  title="Beérkezett ajánlatok"
                  description="Tekintse meg és értékelje a kapott ajánlatokat"
                  to="/client/proposals"
                />
                <DashboardTile
                  icon={FaList}
                  title="Projektjeim"
                  description="Aktív és lezárt projektek kezelése"
                  to="/client/projects"
                />
                <DashboardTile
                  icon={FaUserTie}
                  title="Kivitelezők keresése"
                  description="Böngésszen a minősített kivitelezők között"
                  to="/contractors"
                />
                <DashboardTile
                  icon={FaComments}
                  title="Üzenetek"
                  description="Kommunikáció a kivitelezőkkel"
                />
                <DashboardTile
                  icon={FaChartLine}
                  title="Költségek"
                  description="Projektek költségeinek követése"
                />
                <DashboardTile
                  icon={FaCog}
                  title="Beállítások"
                  description="Profil és értesítések kezelése"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 